import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { AutoSizer, List } from 'react-virtualized'
import { radius, space } from 'theme'
import { CANVA, DOCUMENT, ERROR_MESSAGE, IMAGE, VIDEO } from 'consts'
import { originCleanUp, truncate, popupCenter } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Checkbox from 'components/atoms/Checkbox'
import Initials from 'components/atoms/Initials'
import Divider from 'components/atoms/Divider'
import NoData from 'components/molecules/NoData'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import DropdownMenu from 'components/molecules/DropdownMenu'
import {
  MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
} from 'routes/Calendar/consts'
import MediaFilters from './MediaFilters'
import ExportConfigurationModal from './ExportConfigurationModal'
import ConnectProfileSelectEntityModal from './ConnectProfileSelectEntityModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${({ theme }) => transparentize(0.5, theme.colors.background_loading_progress)};
`

const MediaSectionWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.border_color_light)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
`

const MediaItemWrapper = styled(Flex)`
  width: 200px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  margin: 0 ${space.s} ${space.s};
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity:0.6;`}
  ${({ selected }) =>
    selected && `box-shadow: 0 0 5px 0 ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};`}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
    ${SelectWrapper} {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.border_color)};
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  text-align: center;
`

const StyledDialogEnvironmentSelectedMediasWrapper = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledSelectedMediasWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    div {
      display: flex;
    }
  }
`

const SelectedMediasImage = styled(ImageWithFallback)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.m};
  vertical-align: inherit;
`

const StyledDismissWrapper = styled(Flex)`
  display: none;
  cursor: pointer;
  background: #000;
  z-index: 999;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
`

const StyledPublicationsCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: ${space.xs};
  right: ${space.xs};
  z-index: 2;
  border-radius: ${radius.l};
  padding: ${space.xs} ${space.s};
  cursor: pointer;
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const StyledSelectedMediaWrapper = styled(Flex)`
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  padding: ${space.m};
`

const StyledSelectedMedia = styled(Flex)`
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  width: 64px;
  height: 64px;
`

const StyledPageWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
`

const StyledSelectedProfileWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.background_item_light};
  &:hover {
    background: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledFolderBackNavigationText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const DEFAULT_EXPORT_CONFIGURATION_MODAL_OPTIONS = { isOpen: false, type: null }
const DEFAULT_CONNECT_PROFILE_SELECT_ENTITY_MODAL = { isOpen: false }
const DEFAULT_IMAGE_FALLBACK = '/assets/landscape.svg'

const MAX_ALLOWED_DESIGNS = 20
const MAX_CANVA_PROFILES = 10

const { FOLDER, DESIGN } = { FOLDER: 'folder', DESIGN: 'design' }

const SearchInputComponent = forwardRef(({ handleFilterMediaBySearch }, inputSearchText) => {
  const [searchString, setSearchString] = useState('')

  useImperativeHandle(inputSearchText, () => ({
    getSearchString() {
      return searchString
    },
  }))

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMediaBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      height="34px"
      width="300px"
    />
  )
})

SearchInputComponent.propTypes = {
  handleFilterMediaBySearch: PropTypes.func.isRequired,
}

const CanvaMediaLibraryModal = ({
  isOpen,
  handleDismiss,
  handleClickAddMedias,
  handleAddNewProfile,
  type,
  maxImageAttachmentsAllowed,
  maxVideoAttachmentsAllowed,
  maxDocumentAttachmentsAllowed,
  entities,
  selectedEntities,
  ...props
}) => {
  const scrollbarsRef = useRef(null)
  const inputSearchText = useRef(null)
  const mediaFiltersRef = useRef(null)

  const [step, setStep] = useState(1)
  const [isGettingData, setIsGettingData] = useState(true)
  const [medias, setMedias] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [page, setPage] = useState(0)
  const [selectedMedias, setSelectedMedias] = useState([])
  const [isInitial, setIsInitial] = useState(false)
  const [exportConfigurationModalOptions, setExportConfigurationModalOptions] = useState({
    ...DEFAULT_EXPORT_CONFIGURATION_MODAL_OPTIONS,
  })
  const [connectProfileSelectEntityModalOptions, setConnectProfileSelectEntityModalOptions] = useState({
    ...DEFAULT_CONNECT_PROFILE_SELECT_ENTITY_MODAL,
  })
  const [isProcessingMedias, setIsProcessingMedias] = useState(false)
  const [quality, setQuality] = useState(null)
  const [canvaProfiles, setCanvaProfiles] = useState({ ...JSON.parse(JSON.stringify(props.canvaProfiles)) })

  const [isSearchFieldEmpty, setIsSearchFieldEmpty] = useState(true)
  const [folderId, setFolderId] = useState('root')
  const [mediaPath, setMediaPath] = useState([{ name: 'Home', id: 'root' }])
  const [newProfileConnected, setNewProfileConnected] = useState(null)
  const [isConnectingProfile, setIsConnectingProfile] = useState(false)
  const [reconnectProfileBlockVisible, setReconnectProfileBlockVisible] = useState(false)

  const { selectedProfile, profiles } = canvaProfiles

  const { id: profileId, name, nickname, username } = selectedProfile || {}

  const totalCanvaProfilesLength = profiles.length

  const isFinalPage = !nextPageToken

  const collectFiltersToQueryParameters = ({ selectedFilters }) => {
    const query_items = []

    const searchTextValue = inputSearchText && inputSearchText.current ? inputSearchText.current.getSearchString() : ''

    query_items.push(`q=${encodeURIComponent(searchTextValue)}`)

    let updatedSelectedFilters =
      selectedFilters || mediaFiltersRef?.current ? mediaFiltersRef.current.getFiltersData() : {}

    const { filters } = updatedSelectedFilters || {}

    if (!filters || JSON.stringify(filters) === JSON.stringify({})) {
      updatedSelectedFilters = {
        filters: {},
      }
    }

    const { filters: { selectedOwnership, selectedMediaTypes, selectedSort } = {} } = updatedSelectedFilters || {}

    if (folderId) {
      query_items.push(`folder_gid=${folderId}`)
    }

    if (searchTextValue && selectedOwnership) {
      query_items.push(`owner=${selectedOwnership.value}`)
    }

    if (!searchTextValue && selectedMediaTypes) {
      query_items.push(`item_types=${selectedMediaTypes.map(({ value }) => value).join(',')}`)
    }

    if (selectedSort) {
      query_items.push(`sort=${selectedSort.value}`)
    }

    if (nextPageToken) {
      query_items.push(`next_token=${nextPageToken}`)
    }

    return { isSearchFieldEmpty: !searchTextValue, query_string: query_items.join('&') }
  }

  const getDesigns = async ({ selectedFilters, page, profile_gid }) => {
    const profile_gid_updated = profile_gid || profileId

    if (profile_gid_updated) {
      try {
        setIsGettingData(true)

        let path = `canva/medias?profile_gid=${profile_gid_updated}`

        const { isSearchFieldEmpty, query_string } = collectFiltersToQueryParameters({ selectedFilters })

        setIsSearchFieldEmpty(isSearchFieldEmpty)

        path += `&${query_string}`

        const response = await request({
          path,
        })

        const { error, data, continuation, reconnect } = response || {}
        if (!response || error) {
          if (!reconnect) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          }

          if (reconnect) {
            setReconnectProfileBlockVisible(true)
          }
        } else {
          setReconnectProfileBlockVisible(false)

          setNextPageToken(continuation)

          if (page === 0) {
            setMedias([...data])
          } else {
            setMedias([...medias, ...data])
          }

          if (isInitial) {
            setIsInitial(false)
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: CanvaMediaLibraryModal.displayName,
          functionName: 'getDesigns',
        })
      } finally {
        setIsGettingData(false)
      }
    } else {
      setIsGettingData(false)
    }
  }

  const updatedEntitiesForConnectedProfile = async ({ profile }) => {
    const { id, isReconnecting } = profile

    if (selectedEntities.length > 1 && !isReconnecting) {
      try {
        await request({
          method: 'POST',
          path: `profiles`,
          body: {
            profile_gid: id,
            selected_entity_gids: selectedEntities.map(({ id }) => id),
            unselected_entity_gids: [],
            quiet: true,
          },
        })
      } catch (error) {
        errorHelper({
          error,
          componentName: CanvaMediaLibraryModal.displayName,
          functionName: 'updatedEntitiesForConnectedProfile',
          showAlert: false,
        })
      }
    }
  }

  const processCanvaConnectProfile = (event) => {
    if (event.origin === process.env.NEXT_PUBLIC_SITE_URL && event.data.message === 'connectPopupCallback') {
      const { profile } = event.data

      if (profile) {
        setNewProfileConnected(profile)
      }

      event.source.close()

      setIsConnectingProfile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('message', processCanvaConnectProfile)

    return () => {
      window.removeEventListener('message', processCanvaConnectProfile)
    }
  }, [])

  useEffect(() => {
    if (!isGettingData && !isFinalPage && !isInitial) {
      if (process.browser && page === 0 && scrollbarsRef && scrollbarsRef.current) {
        let top = 0

        const el = document.getElementById('media-section-wrapper')

        if (el) {
          const bounding = el.getBoundingClientRect()

          // eslint-disable-next-line prefer-destructuring
          top = bounding.top
        }

        scrollbarsRef.current.scrollTop(top)
      }

      getDesigns({ page })
    }
  }, [page])

  useEffect(() => {
    getDesigns({ page: 0 })

    if (page !== 0) {
      setPage(0)
    }

    if (nextPageToken) {
      setNextPageToken(null)
    }
  }, [folderId])

  useEffect(() => {
    if (newProfileConnected) {
      const foundProfileIndex = canvaProfiles.profiles.find(({ id }) => id === newProfileConnected.id)

      if (foundProfileIndex > -1) {
        canvaProfiles.profiles.splice(foundProfileIndex, 1)
      }

      canvaProfiles.profiles.unshift(newProfileConnected)

      setCanvaProfiles({ ...canvaProfiles })

      // eslint-disable-next-line no-use-before-define
      handleClickChangeProfile({ profile: newProfileConnected })

      handleAddNewProfile({ profile: newProfileConnected })

      updatedEntitiesForConnectedProfile({ profile: newProfileConnected })

      setNewProfileConnected(null)
    }
  }, [newProfileConnected])

  const handleClickChangeProfile = ({ profile }) => {
    if (isGettingData) {
      Alert.error('Please wait while we finish getting your medias.', { timeout: 5000 })
    } else {
      canvaProfiles.selectedProfile = profile
      setCanvaProfiles({ ...canvaProfiles })

      setMedias([])
      setSelectedMedias([])

      if (page === 0) {
        getDesigns({ page: 0, profile_gid: profile.id })
      } else {
        setNextPageToken(null)
        setPage(0)
      }
    }
  }

  const handleFilterMediaBySearch = debounce(() => {
    if (page === 0) {
      getDesigns({ page })
    } else {
      setNextPageToken(null)
      setPage(0)
    }
  }, 300)

  const handleClickChangeFolder = ({ id, name }) => {
    const foundFolderIndex = mediaPath.findIndex((folder) => folder.id === id)

    if (foundFolderIndex > -1) {
      setMediaPath(mediaPath.slice(0, foundFolderIndex + 1))
    } else {
      mediaPath.push({ id, name })
      setMediaPath([...mediaPath])
    }

    setFolderId(id)
  }

  const handleClickSelectMedia = (item) => {
    let maxAttachments
    let maxAttachmentsErrorText

    if (type === IMAGE) {
      maxAttachments = maxImageAttachmentsAllowed || MAX_IMAGES_ATTACHMENTS_ALLOWED

      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one image can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
      }
    } else if (type === VIDEO) {
      maxAttachments = maxVideoAttachmentsAllowed || MAX_VIDEOS_ATTACHMENTS_ALLOWED
      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one video can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} ${
          maxAttachments === 1 ? 'attachment' : 'attachments'
        }!`
      }
    } else if (type === DOCUMENT) {
      maxAttachments = maxDocumentAttachmentsAllowed || MAX_DOCUMENTS_ATTACHMENTS_ALLOWED
      maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
    }

    if (!maxAttachments || maxAttachments > MAX_ALLOWED_DESIGNS) {
      maxAttachments = MAX_ALLOWED_DESIGNS
    }

    const foundMediaIndex = selectedMedias.findIndex((media) => item.id === media.id)
    if (foundMediaIndex > -1) {
      selectedMedias.splice(foundMediaIndex, 1)
      setSelectedMedias([...selectedMedias])
    } else if (selectedMedias.length < maxAttachments) {
      selectedMedias.push(item)
      setSelectedMedias([...selectedMedias])
    } else {
      Alert.warning(maxAttachmentsErrorText)
    }
  }

  const handleClickProcessSelectedMedias = () => {
    if (selectedMedias.length === 0) {
      Alert.error('Please select at least one media.', { timeout: 5000 })
    } else if (isGettingData) {
      Alert.error('Please wait while we finish getting your medias.', { timeout: 5000 })
    } else if (type === IMAGE || type === VIDEO) {
      setExportConfigurationModalOptions({ ...{ isOpen: true, type } })
    } else if (type === DOCUMENT) {
      // eslint-disable-next-line no-use-before-define
      handleClickNextStep()
    }
  }

  const handleClickCloseExportConfigurationModal = () => {
    setExportConfigurationModalOptions({ ...DEFAULT_EXPORT_CONFIGURATION_MODAL_OPTIONS })
  }

  const preprocessMedia = async ({ media, quality }) => {
    const foundMediaIndex = selectedMedias.findIndex(({ id }) => id === media.id)

    selectedMedias[foundMediaIndex].isProcessing = true

    setSelectedMedias([...selectedMedias])

    try {
      const response = await request({
        path: `canva/design/${media.id}/process`,
        method: 'POST',
        body: {
          profile_gid: profileId,
          type: IMAGE,
          quality: type === IMAGE ? quality : 80,
        },
      })

      const { error, data = [] } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })

        selectedMedias[foundMediaIndex].error = error
      } else {
        const page_urls = data.map((url, index) => ({
          url,
          selected: true,
          page_id: index + 1,
        }))

        selectedMedias[foundMediaIndex].pages_urls = page_urls

        if (type === IMAGE) {
          selectedMedias[foundMediaIndex].processed_urls = page_urls
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: CanvaMediaLibraryModal.displayName,
        functionName: 'preprocessMedia',
      })

      selectedMedias[foundMediaIndex].error = ERROR_MESSAGE
    } finally {
      selectedMedias[foundMediaIndex].isProcessing = false
      selectedMedias[foundMediaIndex].isProcessed = true

      setSelectedMedias([...selectedMedias])
    }
  }

  const handleClickNextStep = async (props) => {
    setStep(2)
    const { quality } = props || {}

    setQuality(quality)

    setIsProcessingMedias(true)

    for (const media of selectedMedias) {
      const { isProcessed } = media

      if (!isProcessed) {
        await preprocessMedia({ media, quality })
      }
    }

    setIsProcessingMedias(false)
  }

  const handleClickBackStep = () => {
    const hasProcessingMedia = selectedMedias.find(({ isProcessing }) => isProcessing)
    if (isProcessingMedias || hasProcessingMedia) {
      Alert.error('Please wait a bit longer while we finish processing your medias.', { timeout: 5000 })
    } else {
      setStep(step - 1)
    }
  }

  const finishProcessingMedia = async ({ media }) => {
    const { design_gid, pages } = media

    let processed_urls = []

    const foundMediaIndex = selectedMedias.findIndex(({ id }) => id === media.design_gid)

    try {
      selectedMedias[foundMediaIndex].isProcessing = true

      setSelectedMedias([...selectedMedias])

      const response = await request({
        path: `canva/design/${design_gid}/process`,
        method: 'POST',
        body: {
          profile_gid: profileId,
          pages,
          type,
          quality,
        },
      })

      const { error, data = [] } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })

        selectedMedias[foundMediaIndex].error = error
      } else {
        processed_urls = data.map((url, index) => ({
          url,
          page_id: index + 1,
        }))

        selectedMedias[foundMediaIndex].processed_urls = processed_urls
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: CanvaMediaLibraryModal.displayName,
        functionName: 'finishProcessingMedia',
      })

      selectedMedias[foundMediaIndex].error = ERROR_MESSAGE
    } finally {
      selectedMedias[foundMediaIndex].isProcessing = false

      setSelectedMedias([...selectedMedias])
    }

    return { processed_urls }
  }

  const handleClickSubmitMedias = async () => {
    let hasProcessingMedia = false
    let hasSelectedMedia = false

    selectedMedias.forEach((media) => {
      const { error, isProcessing, isProcessed, pages_urls = [] } = media

      if (isProcessing) {
        hasProcessingMedia = true
      } else if (isProcessed && !error) {
        const has_selected_media = pages_urls.find(({ selected }) => selected)

        if (has_selected_media) {
          hasSelectedMedia = true
        }
      }
    })

    if (hasProcessingMedia) {
      Alert.error('Please wait a bit longer while we finish processing your medias.', { timeout: 5000 })
      return
    }

    if (!hasSelectedMedia) {
      Alert.error('Please select at least one media.', { timeout: 5000 })
      return
    }

    const urls = []

    if (type === IMAGE) {
      selectedMedias.forEach(({ error, isProcessed, pages_urls }) => {
        if (isProcessed && !error && pages_urls && pages_urls.length !== 0) {
          pages_urls.forEach(({ url, selected }) => {
            if (selected) {
              urls.push({ url, type })
            }
          })
        }
      })
    } else {
      const selectedDesignsWithPages = []

      selectedMedias.forEach((media) => {
        const { id, error, isProcessed, pages_urls = [] } = media

        if (isProcessed && !error) {
          const data = { design_gid: id, pages: [] }

          pages_urls.forEach(({ selected, page_id }) => {
            if (selected) {
              data.pages.push(page_id)
            }
          })

          if (data.pages.length !== 0) {
            selectedDesignsWithPages.push(data)
          }
        }
      })

      setIsProcessingMedias(true)

      for (const media of selectedDesignsWithPages) {
        const { processed_urls } = await finishProcessingMedia({ media })

        media.processed_urls = processed_urls
      }

      setIsProcessingMedias(false)

      selectedDesignsWithPages.forEach(({ processed_urls }) => {
        if (processed_urls && processed_urls.length !== 0) {
          processed_urls.forEach(({ url }) => {
            urls.push({ url, type })
          })
        }
      })
    }

    handleClickAddMedias({ urls })
    handleDismiss()
  }

  const handleProcessCanvaProfileConnection = async ({ entityId, reconnecting_profile_gid }) => {
    const updatedCode = CANVA

    const updatedOrigin = originCleanUp({ origin: '/connect/popup-callback' })

    let url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/connect/${updatedCode}?origin=${updatedOrigin}&entityId=${entityId}&redirect_param=true`

    if (reconnecting_profile_gid) {
      url += `&reconnecting_profile_gid=${reconnecting_profile_gid}`
    }

    const handle = window.open(url, 'Connect Canva profile', popupCenter({ w: 500, h: 700 }))

    if (!handle) {
      Alert.error(`Please turn off your Ad blocker or allow popup's to be opened.`, { timeout: 5000 })
    } else {
      setIsConnectingProfile(true)

      let leftDomain = false
      const interval = setInterval(() => {
        try {
          if (handle.document.domain === document.domain) {
            if (leftDomain && handle.document.readyState === 'complete') {
              clearInterval(interval)

              handle.postMessage({ message: 'connectPopupCallPing' }, process.env.NEXT_PUBLIC_SITE_URL)
            }
          } else {
            leftDomain = true
          }
        } catch (e) {
          if (handle.closed) {
            clearInterval(interval)

            setIsConnectingProfile(false)
            return
          }
          leftDomain = true
        }
      }, 500)
    }
  }

  const handleClickReconnectCanvaProfile = () => {
    if (isConnectingProfile) {
      Alert.error('Please finish existing Canva profile connection workflow.', { timeout: 5000 })
    } else {
      const { entityId, id } = canvaProfiles.selectedProfile

      handleProcessCanvaProfileConnection({ entityId, reconnecting_profile_gid: id })
    }
  }

  const handleClickConnectCanvaProfile = () => {
    if (isConnectingProfile) {
      Alert.error('Please finish existing Canva profile connection workflow.', { timeout: 5000 })
    } else if (selectedEntities.length === 0) {
      setConnectProfileSelectEntityModalOptions({ ...{ isOpen: true } })
    } else {
      const { id } = selectedEntities[0]

      handleProcessCanvaProfileConnection({ entityId: id })
    }
  }

  const handleClickReloadMedias = () => {
    if (!isGettingData) {
      getDesigns({ page: 0 })

      if (page !== 0) {
        setPage(0)
      }

      if (nextPageToken) {
        setNextPageToken(null)
      }
    } else {
      Alert.error('Please wait while we finish getting your medias.', {
        timeout: 5000,
      })
    }
  }

  const handleClickCloseConnectProfileSelectEntityModal = () => {
    setConnectProfileSelectEntityModalOptions({ ...DEFAULT_CONNECT_PROFILE_SELECT_ENTITY_MODAL })
  }

  const handleClickCloseModal = () => {
    if (step === 2) {
      if (isProcessingMedias) {
        Alert.error('Please wait while we finish processing your medias.', {
          timeout: 5000,
        })
      } else {
        handleClickBackStep()
      }
    } else if (step === 1) {
      if (isGettingData) {
        Alert.error('Please wait while we finish getting your medias.', {
          timeout: 5000,
        })
      } else {
        handleDismiss()
      }
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        {step === 1 && (
          <StyledDialogContent>
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
              <H4 my="m">Select Canva designs {profileId && `from ${nickname || name || username}`}</H4>
            </StyledDialogEnvironmentWrapper>

            <StyledDialogBodyWrapper flex="1" flexDirection="column">
              {profileId && (
                <Flex my="s" px="m" justifyContent="space-between">
                  {!reconnectProfileBlockVisible ? (
                    <SearchInputComponent handleFilterMediaBySearch={handleFilterMediaBySearch} ref={inputSearchText} />
                  ) : (
                    <Flex />
                  )}

                  <Flex alignItems="center">
                    {!reconnectProfileBlockVisible && (
                      <Flex
                        justifyContent="center"
                        flexDirection="column"
                        width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
                        mx="s"
                      >
                        <MediaFilters
                          ref={mediaFiltersRef}
                          handleFetchMedia={({ filters }) => {
                            if (page === 0) {
                              getDesigns({
                                selectedFilters: { filters },
                                page,
                              })
                            } else {
                              setNextPageToken(null)
                              setPage(0)
                            }
                          }}
                          left="unset"
                          right="0px"
                          isSearchFieldEmpty={isSearchFieldEmpty}
                        />
                      </Flex>
                    )}

                    <Flex>
                      <DropdownMenu
                        WrapperComp={
                          <StyledSelectedProfileWrapper>
                            <Initials
                              name={name}
                              height="32px"
                              width="32px"
                              isSmall
                              isCircle
                              backgroundColor="#7D2AE8"
                            />
                          </StyledSelectedProfileWrapper>
                        }
                        right={0}
                        isTriangleVisible={false}
                        isDismissedOnClickInside
                        top={30}
                        minWidth="250px"
                      >
                        <DropdownMenu.Item
                          label={isConnectingProfile ? 'Connecting account' : 'Connect Canva account'}
                          PictureComp={
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              className="cliploader-wrapper"
                              width="20px"
                              height="20px"
                              mr="s"
                            >
                              {isConnectingProfile ? (
                                <ClipLoader size="20" />
                              ) : (
                                <Icon.VistaSocialPlus fill="primaryText" />
                              )}
                            </Flex>
                          }
                          onClick={handleClickConnectCanvaProfile}
                        />

                        {!reconnectProfileBlockVisible && (
                          <DropdownMenu.Item
                            label="Reload medias"
                            PictureComp={
                              <Flex
                                alignItems="center"
                                justifyContent="center"
                                className="cliploader-wrapper"
                                width="20px"
                                height="20px"
                                mr="s"
                              >
                                <Icon.Sync fill="primaryText" width="16px" height="16px" />
                              </Flex>
                            }
                            onClick={handleClickReloadMedias}
                          />
                        )}
                        <Divider height="1px" />

                        <AutoSizer disableHeight>
                          {({ width }) => (
                            <List
                              height={
                                totalCanvaProfilesLength > MAX_CANVA_PROFILES ? 300 : totalCanvaProfilesLength * 30
                              }
                              overscanRowCount={10}
                              noRowsRenderer={() => {}}
                              rowCount={totalCanvaProfilesLength}
                              rowHeight={30}
                              width={width}
                              rowRenderer={({ index, key, style }) => {
                                const profile = profiles[index]
                                const { id, nickname, name, username } = profile
                                const isActive = profileId === id

                                return (
                                  <Flex flexDirection="column" style={style} key={key}>
                                    <DropdownMenu.Item
                                      label={nickname || name || username}
                                      PictureComp={
                                        <Flex position="relative" mr="s">
                                          <Initials
                                            name={name}
                                            height="20px"
                                            width="20px"
                                            isSmall
                                            isCircle
                                            backgroundColor="#7D2AE8"
                                            textFontSize="xxs"
                                          />
                                        </Flex>
                                      }
                                      onClick={() => {
                                        if (profile.id !== profileId) {
                                          handleClickChangeProfile({ profile })
                                        }
                                      }}
                                      iconNameEnding="Checkmark"
                                      iconWidthEnding={isActive ? '10px' : '0px'}
                                      iconColorEnding="primaryText"
                                    />
                                  </Flex>
                                )
                              }}
                            />
                          )}
                        </AutoSizer>
                      </DropdownMenu>
                    </Flex>
                  </Flex>
                </Flex>
              )}

              <Flex width="100%" height="100%" position="relative" flexDirection="column" id="media-section-wrapper">
                <StyledProgressWrapper
                  display={isGettingData ? 'flex' : 'none'}
                  alignItems="center"
                  justifyContent="center"
                  className="cliploader-wrapper"
                >
                  <ClipLoader size="100" />
                </StyledProgressWrapper>

                {profileId && !reconnectProfileBlockVisible && (
                  <Fragment>
                    <Scrollbars
                      ref={scrollbarsRef}
                      universal
                      style={{ display: 'flex', flexWrap: 'wrap' }}
                      onScrollFrame={({ top }) => {
                        if (top >= 0.99) {
                          if (!isGettingData && !isFinalPage) {
                            setPage((page) => {
                              return page + 1
                            })
                          }
                        }
                      }}
                    >
                      <Flex flexDirection="column" mb="m" width="100%" px="s">
                        <MediaSectionWrapper flexDirection="column" pb="m" width="100%">
                          {isSearchFieldEmpty && (
                            <Flex alignItems="center" mt="s" px="s">
                              {mediaPath.map(({ id, name }, index) => {
                                const hasMoreItems = !!mediaPath[index + 1]

                                const isLastItem = mediaPath.length - 1 === index

                                return (
                                  <Flex key={id} alignItems="center">
                                    <StyledFolderBackNavigationText
                                      fontSize="s"
                                      color="secondaryText"
                                      onClick={() => {
                                        if (!isLastItem) {
                                          handleClickChangeFolder({ id, name })
                                        }
                                      }}
                                    >
                                      {name}
                                    </StyledFolderBackNavigationText>

                                    {hasMoreItems && (
                                      <Text color="text_slash_divider_color" fontSize="s">
                                        &nbsp;/&nbsp;
                                      </Text>
                                    )}
                                  </Flex>
                                )
                              })}
                            </Flex>
                          )}

                          {medias.length === 0 ? (
                            <Flex
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                              width="100%"
                              px="m"
                              bg="white"
                              pt="xxl"
                            >
                              <NoData
                                showHeaderText={false}
                                message={isGettingData ? '' : 'No medias found'}
                                image="/assets/noResultsIllustration.svg"
                              />
                            </Flex>
                          ) : (
                            <Grid pt="m" gridTemplateColumns="repeat(4, 1fr)" width="100%" height="100%">
                              {medias.map((item) => {
                                const { type } = item

                                const { id, name, title, thumbnail: { url } = {}, page_count } = item[type]

                                const selected = selectedMedias.find((media) => media.id === id)

                                return (
                                  <MediaItemWrapper
                                    key={id}
                                    onClick={() => {
                                      if (type === DESIGN) {
                                        handleClickSelectMedia(item[type])
                                      } else if (type === FOLDER) {
                                        handleClickChangeFolder({ name, id })
                                      }
                                    }}
                                    flexDirection="column"
                                    selected={selected}
                                  >
                                    <Flex alignItems="center" justifyContent="center" width="100%">
                                      <Flex alignItems="center" justifyContent="center" width="100%">
                                        <Flex
                                          width="100%"
                                          height="150px"
                                          alignItems="center"
                                          justifyContent="center"
                                          bg="background_item_light"
                                          borderRadius={radius.l}
                                          position="relative"
                                        >
                                          <MediaWrapper selected={selected}>
                                            {type === DESIGN && (
                                              <StyledImage
                                                source={url || DEFAULT_IMAGE_FALLBACK}
                                                fallbackSource={DEFAULT_IMAGE_FALLBACK}
                                                borderRadius={`${radius.l} ${radius.l} 0 0`}
                                              />
                                            )}

                                            {type === FOLDER && (
                                              <Flex
                                                width="100%"
                                                height="100%"
                                                alignItems="center"
                                                justifyContent="center"
                                              >
                                                <Icon.Folder width="50%" height="50%" stroke="icon_color_gray" />
                                              </Flex>
                                            )}
                                          </MediaWrapper>

                                          {type === DESIGN && page_count > 1 && (
                                            <StyledPublicationsCounterWrapper>
                                              <Text fontSize="xxs" color="white">
                                                {page_count > 99 ? '99+' : page_count}
                                              </Text>
                                            </StyledPublicationsCounterWrapper>
                                          )}
                                        </Flex>
                                      </Flex>
                                    </Flex>

                                    {type === DESIGN && (
                                      <Fragment>
                                        <Excerpt color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                          {title && truncate(title, 35)}
                                        </Excerpt>

                                        <Box
                                          position="absolute"
                                          top={space.xs}
                                          left={space.xs}
                                          zIndex="2"
                                          width="100%"
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            handleClickSelectMedia(item[type])
                                          }}
                                        >
                                          <SelectWrapper
                                            alignItems="center"
                                            justifyContent="center"
                                            selected={selected}
                                          >
                                            <StyledIconCheckmark selected={selected} width="10px" height="10px" />
                                          </SelectWrapper>
                                        </Box>
                                      </Fragment>
                                    )}

                                    {type === FOLDER && (
                                      <Excerpt color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                        {name && truncate(name, 35)}
                                      </Excerpt>
                                    )}
                                  </MediaItemWrapper>
                                )
                              })}
                            </Grid>
                          )}
                        </MediaSectionWrapper>
                      </Flex>
                    </Scrollbars>
                  </Fragment>
                )}

                {!profileId && (
                  <Flex flexDirection="column" my="xxl" textAlign="center">
                    <NoData
                      message="You have not connected any Canva accounts yet"
                      image="/assets/noRecentPostsIllustration.svg"
                      showHeaderText={false}
                    />
                    <Flex mt="m" mx="auto">
                      <ButtonWithLoading
                        isSmall
                        onClick={handleClickConnectCanvaProfile}
                        isLoading={isConnectingProfile}
                      >
                        {isConnectingProfile ? 'Connecting' : 'Connect Canva account'}
                      </ButtonWithLoading>
                    </Flex>
                  </Flex>
                )}

                {reconnectProfileBlockVisible && (
                  <Flex flexDirection="column" my="xxl" textAlign="center">
                    <NoData
                      message="Whoops! We can no longer access this Canva account."
                      image="/assets/noRecentPostsIllustration.svg"
                      showHeaderText={false}
                    />
                    <Flex mt="m" mx="auto">
                      <ButtonWithLoading
                        isSmall
                        onClick={handleClickReconnectCanvaProfile}
                        isLoading={isConnectingProfile}
                      >
                        {isConnectingProfile ? 'Reconnecting' : 'Reconnect Canva account'}
                      </ButtonWithLoading>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </StyledDialogBodyWrapper>

            <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
              {selectedMedias.length > 0 && <Text fontSize="m">Selected designs</Text>}
              <Flex alignItems="center" mx="m" flex="1" maxWidth="500px">
                <StyledDialogEnvironmentSelectedMediasWrapper>
                  {selectedMedias.map((item) => {
                    const { id, thumbnail: { url } = {} } = item
                    return (
                      <Flex alignItems="center" key={id} mr="m">
                        <StyledSelectedMediasWrapper
                          width="24px"
                          height="24px"
                          justifyContent="center"
                          alignItems="center"
                          position="relative"
                        >
                          <SelectedMediasImage
                            source={url || DEFAULT_IMAGE_FALLBACK}
                            fallbackSource={DEFAULT_IMAGE_FALLBACK}
                          />

                          <StyledDismissWrapper
                            position="absolute"
                            onClick={() => {
                              handleClickSelectMedia(item)
                            }}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Image width="12px" height="12px" src="/assets/clear.svg" />
                          </StyledDismissWrapper>
                        </StyledSelectedMediasWrapper>
                      </Flex>
                    )
                  })}
                </StyledDialogEnvironmentSelectedMediasWrapper>
              </Flex>
              {profileId && (
                <Flex alignItems="center" justifyContent="flex-end">
                  <Button.Gray isSmall onClick={handleDismiss}>
                    Cancel
                  </Button.Gray>
                  {!reconnectProfileBlockVisible && (
                    <Button.Gradient ml="m" onClick={handleClickProcessSelectedMedias} isSmall>
                      <Text fontWeight="medium">Select</Text>
                    </Button.Gradient>
                  )}
                </Flex>
              )}
            </StyledDialogEnvironmentWrapper>
            <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
              <Image width="10px" height="10px" src="/assets/clear.svg" />
            </CloseIconWrapper>
          </StyledDialogContent>
        )}

        {step === 2 && (
          <StyledDialogContent>
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
              <H4 my="m">Exporting Canva designs</H4>
            </StyledDialogEnvironmentWrapper>

            <StyledDialogBodyWrapper flex="1" flexDirection="column">
              <Flex width="100%" height="100%" position="relative" flexDirection="column">
                <Scrollbars
                  ref={scrollbarsRef}
                  universal
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                  onScrollFrame={({ top }) => {
                    if (top >= 0.99) {
                      if (!isGettingData && !isFinalPage) {
                        setPage((page) => {
                          return page + 1
                        })
                      }
                    }
                  }}
                >
                  <Flex flexDirection="column" mb="m" width="100%">
                    <MediaSectionWrapper flexDirection="column" width="100%">
                      <Grid gridGap="m" gridTemplateColumns="1fr" width="100%" height="100%" p="m">
                        {selectedMedias.map((item, media_index) => {
                          const {
                            id,
                            title,
                            thumbnail: { url } = {},
                            isProcessing,
                            isProcessed,
                            error,
                            pages_urls = [],
                            page_count,
                          } = item

                          const pagesLength = pages_urls ? pages_urls.length : 0

                          const allSelected =
                            !error && pages_urls && pagesLength === pages_urls.filter(({ selected }) => selected).length

                          return (
                            <StyledSelectedMediaWrapper key={id} flexDirection="column">
                              <Flex alignItems="center">
                                <Flex width="32px">
                                  {isProcessing ? (
                                    <Flex alignItems="center" justifyContent="center" className="cliploader-wrapper">
                                      <ClipLoader size="15" />
                                    </Flex>
                                  ) : (
                                    <Fragment>
                                      {!error && (
                                        <CheckBoxWrapper>
                                          <Checkbox
                                            isChecked={allSelected}
                                            onClick={() => {
                                              if (isProcessingMedias) {
                                                Alert.error('Please wait while we finish processing your medias.', {
                                                  timeout: 5000,
                                                })
                                              } else {
                                                if (allSelected) {
                                                  selectedMedias[media_index].pages_urls.forEach((page_url) => {
                                                    page_url.selected = false
                                                  })
                                                } else {
                                                  selectedMedias[media_index].pages_urls.forEach((page_url) => {
                                                    page_url.selected = true
                                                  })
                                                }

                                                setSelectedMedias([...selectedMedias])
                                              }
                                            }}
                                          />
                                        </CheckBoxWrapper>
                                      )}
                                    </Fragment>
                                  )}
                                </Flex>

                                <Flex alignItems="center">
                                  <StyledSelectedMedia>
                                    <StyledImage
                                      source={url || DEFAULT_IMAGE_FALLBACK}
                                      fallbackSource={DEFAULT_IMAGE_FALLBACK}
                                      borderRadius={radius.l}
                                    />
                                  </StyledSelectedMedia>

                                  <Flex flexDirection="column" ml="m">
                                    <Excerpt color="primaryText" fontSize="s">
                                      {title && truncate(title, 35)}
                                    </Excerpt>

                                    <Text fontSize="xs" color="secondaryText">
                                      {page_count} {page_count === 1 ? 'page' : 'pages'}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>

                              {isProcessed && (
                                <Flex mt="s" flexDirection="column" ml="l">
                                  {error ? (
                                    <Text mt="s" color="error">
                                      {error}
                                    </Text>
                                  ) : (
                                    <Fragment>
                                      {pages_urls.map(({ url, selected, page_id }, index) => {
                                        return (
                                          <StyledPageWrapper
                                            pt="s"
                                            pb={index === pagesLength - 1 ? 0 : 's'}
                                            key={page_id}
                                            alignItems="center"
                                          >
                                            <Flex width="32px">
                                              <CheckBoxWrapper>
                                                <Checkbox
                                                  isChecked={selected}
                                                  onClick={() => {
                                                    if (isProcessingMedias) {
                                                      Alert.error(
                                                        'Please wait while we finish processing your medias.',
                                                        {
                                                          timeout: 5000,
                                                        }
                                                      )
                                                    } else {
                                                      selectedMedias[media_index].pages_urls[index].selected = !selected

                                                      setSelectedMedias([...selectedMedias])
                                                    }
                                                  }}
                                                />
                                              </CheckBoxWrapper>
                                            </Flex>

                                            <Flex alignItems="center">
                                              <StyledSelectedMedia>
                                                <StyledImage
                                                  source={url || DEFAULT_IMAGE_FALLBACK}
                                                  fallbackSource={DEFAULT_IMAGE_FALLBACK}
                                                  borderRadius={radius.l}
                                                />
                                              </StyledSelectedMedia>

                                              <Flex flexDirection="column" ml="m">
                                                <Excerpt color="primaryText" fontSize="s">
                                                  {title && truncate(title, 35)}
                                                </Excerpt>

                                                <Text fontSize="xs" color="secondaryText">
                                                  {page_id} of {pagesLength}
                                                </Text>
                                              </Flex>
                                            </Flex>
                                          </StyledPageWrapper>
                                        )
                                      })}
                                    </Fragment>
                                  )}
                                </Flex>
                              )}
                            </StyledSelectedMediaWrapper>
                          )
                        })}
                      </Grid>
                    </MediaSectionWrapper>
                  </Flex>
                </Scrollbars>
              </Flex>
            </StyledDialogBodyWrapper>

            <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
              <Button.Gray mr="m" isSmall onClick={handleClickBackStep}>
                Back
              </Button.Gray>
              <ButtonWithLoading onClick={handleClickSubmitMedias} isSmall isLoading={isProcessingMedias}>
                <Text fontWeight="medium">{isProcessingMedias ? 'Processing' : 'Select'}</Text>
              </ButtonWithLoading>
            </StyledDialogEnvironmentWrapper>
            <CloseIconWrapper className="modal-close-icon" onClick={handleClickBackStep}>
              <Image width="10px" height="10px" src="/assets/clear.svg" />
            </CloseIconWrapper>
          </StyledDialogContent>
        )}

        {exportConfigurationModalOptions.isOpen && (
          <ExportConfigurationModal
            isOpen={exportConfigurationModalOptions.isOpen}
            handleDismiss={handleClickCloseExportConfigurationModal}
            type={exportConfigurationModalOptions.type}
            handleClickNextStep={handleClickNextStep}
          />
        )}

        {connectProfileSelectEntityModalOptions.isOpen && (
          <ConnectProfileSelectEntityModal
            isOpen={connectProfileSelectEntityModalOptions.isOpen}
            handleDismiss={handleClickCloseConnectProfileSelectEntityModal}
            entities={entities}
            selectedEntities={selectedEntities}
            handleProcessCanvaProfileConnection={handleProcessCanvaProfileConnection}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

CanvaMediaLibraryModal.defaultProps = {
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  maxVideoAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  maxDocumentAttachmentsAllowed: MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  selectedEntities: null,
}

CanvaMediaLibraryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClickAddMedias: PropTypes.func.isRequired,
  handleAddNewProfile: PropTypes.func.isRequired,
  maxImageAttachmentsAllowed: PropTypes.number,
  maxVideoAttachmentsAllowed: PropTypes.number,
  maxDocumentAttachmentsAllowed: PropTypes.number,
  canvaProfiles: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array,
}

CanvaMediaLibraryModal.displayName = 'CanvaMediaLibraryModal'

export default CanvaMediaLibraryModal
