import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import {
  TUMBLR,
  DEFAULT_VIDEO_THUMBNAIL,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
  IMAGE,
  IMAGE_DYNAMIC,
  DIRECTION_RIGHT_TO_LEFT,
  DIRECTION_LEFT_TO_RIGHT,
} from 'consts'
import { radius, COLOR_CONSTANTS, fontWeights, space, breakpoints } from 'theme'
import { linkTransformer } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Input from 'components/atoms/Input'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Counter from 'components/molecules/Counter'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  TUMBLR_COLORS,
  PREVIEW_MEDIA_CLASS_NAME,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
// eslint-disable-next-line import/no-cycle
import { transformTextVariantsToUnicodeVariant, variableTransformer } from '../../helpers'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import ImagesGridTumblr from './components/ImagesGridTumblr'
import PublicationOptionComponent from './components/PublicationOptionComponent'

const DEFAULT_FONT_SIZE = 16
const EDITABLE_FONT_SIZE = 10.666666

const StyledFlex = styled(Flex)`
  border-radius: 0.5em;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
  height: fit-content; 
  font-size: ${({ isEditable }) => (isEditable ? `${EDITABLE_FONT_SIZE}px` : `${DEFAULT_FONT_SIZE}px`)};  
    height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};   
  --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  --font-family-default: Helvetica, Arial, sans-serif;
  --font-family-segoe: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
}
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const SourceWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 0.375em;
`

const BodyWrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledButtonWrapper = styled(Box)`
  height: 2.25em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: ${radius.xl};
  padding: 0.375em 1em;
`

const StyledFirstImage = styled(ImageWithFallback)`
  object-fit: cover;
  width: auto;
  max-width: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const StyledUrlHostWrapper = styled(Flex)`
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0.375em 0.375em;
`

const StyledUrlHostText = styled(Text)`
  text-transform: uppercase;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === TUMBLR)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  titleMaxLimit: MAX_TITLE_LENGTH,
  slugMaxLimit: MAX_SLUG_LENGTH,
  maxImages: MAX_IMAGES,
  hasMobileNotificationOption,
} = networkLimits

const TumblrPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  reminderNotificationsList,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  theme,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const { isRTL } = theme || {}
  const TEXT_DIRECTION = isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_WIDTH = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
    insights = {},
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let title = ''
  let slug = ''
  let tags = []
  let publishing_option = null
  let device_gid = null
  let email_user_gid = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      title = '',
      slug = '',
      tags = [],
      publishing_option = null,
      device_gid = null,
      email_user_gid = null,
    } = profileCustomizations)
  }

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  const { transformed_text_to_html_for_textarea, variableTransformedText } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({
      str: updatedPostText,
      savedVariables,
      post,
      profileId,
    })

    const transformed_text_to_html_for_textarea =
      linkTransformer({
        body: variableTransformedText,
        color: TUMBLR_COLORS.LAST_LIGHT_BLUE,
        fontWeight: fontWeights.semiLight,
        transformHashstag: true,
        hashtagColor: TUMBLR_COLORS.LAST_LIGHT_BLUE,
        hashtagFontWeight: fontWeights.semiLight,
      }) || ''

    return { transformed_text_to_html_for_textarea, variableTransformedText }
  }, [updatedPostText, savedVariables])

  const { url, picture_url } = link || {}
  const { published_link } = result || {}

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationReminderLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const tagsRef = useRef(null)

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value

    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (e) {
      urlHost = url
    }
  }

  let c_notes = 0
  let c_likes = 0
  let c_shares = 0
  let c_comments = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, shares: c_shares = 0, comments: c_comments = 0 } = profileInsights)

    c_notes = c_likes + c_shares + c_comments
  }

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  const postMixedMediasUpdated =
    customizedPostComponent === POST_MIXED_MEDIA ? customizedPostMixedMedias.filter(({ tempId }) => !tempId) : []

  let showTitle = false
  if (
    (title && postComponent === POST_IMAGE && postImagesUpdated.length) ||
    (postComponent === POST_VIDEO &&
      customizedPostVideos.length &&
      (customizedPostVideos[0].type === VIDEO || customizedPostVideos[0].type === VIDEO_DYNAMIC)) ||
    (postComponent === POST_MIXED_MEDIA && postMixedMediasUpdated.length)
  ) {
    showTitle = true
  }

  const hashtagsComponent = <Flex flexDirection="column" mt="0.5em" />

  const feedPostFooterComponent = (
    <Flex flexDirection="column" mt="0.75em">
      <Flex my="0.25em" px="1em" py="0.5em" justifyContent="space-between">
        <Flex alignItems="center">
          <Image src="/assets/tumblr_blaze.svg" alt="Share" width="1.5em" height="1.5em" />
          <Text fontSize="1em" color={TUMBLR_COLORS.TARNISHED_SILVER} fontWeight="medium" ml="0.125em">
            Blaze
          </Text>
        </Flex>
      </Flex>

      <Flex flexDirection="column" px="1em" py="0.5em" borderTop="1px solid rgba(0, 0, 0, 0.13)">
        <Flex py="0.25em" justifyContent="space-between" alignItems="center">
          <StyledButtonWrapper>
            <Text as="span" fontWeight="extraBold" color={COLOR_CONSTANTS.BLACK} fontSize="1em">
              {c_notes}
            </Text>{' '}
            <Text as="span" fontSize="1em" color={TUMBLR_COLORS.TARNISHED_SILVER}>
              {c_notes === 1 ? 'note' : 'notes'}
            </Text>
          </StyledButtonWrapper>

          <Flex>
            <Image src="/assets/tumblr_share.svg" alt="Share" mr="1.25em" width="1.5em" height="1.5em" />
            <Image src="/assets/tumblr_reply.svg" alt="Reeply" mr="1.25em" width="1.5em" height="1.5em" />
            <Image src="/assets/tumblr_reblog.svg" alt="Reblog" mr="1.25em" width="1.5em" height="1.5em" />
            <Image src="/assets/tumblr_like.svg" alt="Like" width="1.5em" height="1.5em" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { type, isNew, id: videoId, url: videoUrl, thumbnail_url } = data[0]

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <SourceWrapper>
        {noVideoAttachment ? (
          <Flex justifyContent="center" alignItems="center" position="relative">
            {type === VIDEO && (
              <Fragment>
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
                <StyledVideoThumbnailImage
                  height="100%"
                  width="100%"
                  source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                  fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                  name={videoId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                />
              </Fragment>
            )}
            {type === VIDEO_DYNAMIC && (
              <Flex justifyContent="center" alignItems="center" mx="auto">
                <DynamicMedia media={data} post={post} profiles={[profile]} isSmall={smallPreview} />
              </Flex>
            )}
          </Flex>
        ) : (
          <StyledBox position="relative" justifyContent="center" hasError={postVideoError}>
            {isNew ? (
              <Box className="cliploader-wrapper">
                <ClipLoader size="50" />
              </Box>
            ) : (
              <Fragment>
                {type === VIDEO && (
                  <VideoWithFallback
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                    url={videoUrl}
                    controls
                    width="100%"
                    style={{
                      background: '#ffffff',
                    }}
                    config={{
                      file: {
                        attributes: {
                          poster: videoThumbnailUrl,
                        },
                      },
                    }}
                  />
                )}
                {type === VIDEO_DYNAMIC && (
                  <Flex justifyContent="center" alignItems="center" mx="auto">
                    <DynamicMedia media={data} post={post} profiles={[profile]} isSmall={smallPreview} />
                  </Flex>
                )}
              </Fragment>
            )}
          </StyledBox>
        )}
      </SourceWrapper>
    )
  }

  let sourceTypeTemplate = ''

  switch (customizedPostComponent) {
    case POST_IMAGE:
      if (postImagesUpdated.length !== 0) {
        sourceTypeTemplate = (
          <SourceWrapper>
            <ImagesGridTumblr
              images={postImagesUpdated.slice(0, MAX_IMAGES)}
              network={network}
              socialPreviewProfiles={[profile]}
              post={post}
              maxWidth={MAX_WIDTH}
            />
          </SourceWrapper>
        )
      }
      break
    case POST_VIDEO:
      if (
        customizedPostVideos.length !== 0 &&
        (customizedPostVideos[0].url ||
          customizedPostVideos[0].thumbnail_url ||
          customizedPostVideos[0].type === VIDEO_DYNAMIC)
      ) {
        sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
      }

      break
    case POST_LINK:
      if (urlHost) {
        sourceTypeTemplate = (
          <SourceWrapper minHeight="auto" px="1em">
            <Flex flexDirection="column">
              {picture_url && (
                <StyledFirstImage
                  height="15.625em"
                  source={picture_url}
                  fallbackSourceHeight="0px"
                  fallbackSourceWidth="0px"
                  borderRadius="0.375em 0.375em 0 0"
                />
              )}
              <StyledUrlHostWrapper px="1em" py="0.75em" alignItems="center">
                <StyledUrlHostText fontSize="0.78125em" color={TUMBLR_COLORS.TARNISHED_SILVER}>
                  {urlHost}
                </StyledUrlHostText>
              </StyledUrlHostWrapper>
            </Flex>
          </SourceWrapper>
        )
      }
      break
    case POST_MIXED_MEDIA:
      if (postMixedMediasUpdated.length !== 0) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          sourceTypeTemplate = (
            <SourceWrapper>
              <ImagesGridTumblr
                images={postMixedMediasUpdated
                  .filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
                  .slice(0, MAX_IMAGES)}
                network={network}
                socialPreviewProfiles={[profile]}
                post={post}
                maxWidth={MAX_WIDTH}
              />
            </SourceWrapper>
          )
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: postMixedMediasUpdated })
        }
      }
      break
    default:
      break
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_post_preview' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
                width={`${MAX_WIDTH}px`}
                mx="auto"
              >
                <Flex
                  flexDirection="column"
                  pt="0.5em"
                  pb="0.125em"
                  width="100%"
                  borderRadius="0 0.5em 0.5em 0"
                  bg={isPostFormDirty ? 'white' : 'transparent'}
                >
                  {isPostFormDirty ? (
                    <Fragment>
                      <Box p="1em">
                        <Flex>
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width="2em"
                              height="2em"
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width="2em"
                              height="2em"
                              imageBorderRadius={radius.pill}
                            />
                          )}
                          <Flex ml="0.625em" alignItems="center">
                            <Flex flexDirection="column" justifyContent="center">
                              <Flex alignItems="center">
                                <Flex
                                  alignItems="center"
                                  onMouseEnter={(e) => {
                                    if (!isMultipleProfilesPreview) {
                                      tooltipRef.current.handleShowTooltip({
                                        contentComp: (
                                          <ProfileItemTooltip
                                            profile={{
                                              code: network,
                                              picture_url: profilePictureUrl,
                                              profile_url: profileUrl,
                                              name: profileName,
                                              username: profileUsername,
                                              display,
                                              profile_id,
                                              type: profile_type,
                                            }}
                                            message=""
                                          />
                                        ),
                                        wrapperComp: e.currentTarget,
                                      })
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    tooltipRef.current.handleHideTooltip()
                                  }}
                                >
                                  <StyledLink
                                    as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                    href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                    target="_blank"
                                    color={COLOR_CONSTANTS.BLACK}
                                    fontWeight="bold"
                                    fontSize="0.875em"
                                  >
                                    {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : `${profileName}`}
                                  </StyledLink>
                                </Flex>

                                <StyledLink
                                  ml="0.25em"
                                  fontSize="0.875em"
                                  fontWeight="bold"
                                  color={TUMBLR_COLORS.LAST_LIGHT_BLUE}
                                >
                                  Follow
                                </StyledLink>
                              </Flex>
                              <StyledLink
                                fontSize="0.78125em"
                                color={TUMBLR_COLORS.TARNISHED_SILVER}
                                as={published_link ? 'a' : 'p'}
                                href={published_link || ''}
                                target="_blank"
                              >
                                {publish_at ? `${moment(publish_at).fromNow()} ${zoneShort || ''}` : ''}
                              </StyledLink>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>

                      <Flex
                        flexDirection="column"
                        width="100%"
                        onClick={() => {
                          if (published_link) {
                            window.open(published_link)
                          }
                        }}
                      >
                        {customizedPostComponent ? <Box>{sourceTypeTemplate}</Box> : <Box px="0.5em" mb="0.5em" />}
                        <Box px="1em">
                          {showTitle ? (
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: title,
                              }}
                              my="1em"
                              fontSize="1em"
                              whiteSpace="pre-line"
                              color={COLOR_CONSTANTS.BLACK}
                              dir={TEXT_DIRECTION}
                            />
                          ) : (
                            <Fragment>
                              {transformed_text_to_html_for_textarea && (
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: transformed_text_to_html_for_textarea,
                                  }}
                                  my="1em"
                                  fontSize="1em"
                                  whiteSpace="pre-line"
                                  color={COLOR_CONSTANTS.BLACK}
                                  dir={TEXT_DIRECTION}
                                />
                              )}
                            </Fragment>
                          )}
                        </Box>
                        {tags && tags.length > 0 && hashtagsComponent}
                        {feedPostFooterComponent}
                      </Flex>
                    </Fragment>
                  ) : (
                    <Box mt="xs" px="s" mb="s" height="100%">
                      <NoPost />
                    </Box>
                  )}
                </Flex>
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder={isRTL ? '... Write your content' : 'Write your content ...'}
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiless={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={variableTransformedText.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={
                                  transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'
                                }
                                dir={TEXT_DIRECTION}
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={TUMBLR} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            showMixedMediaComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <PublicationOptionComponent
                    handleUpdateCustomization={handleUpdateCustomization}
                    publishing_option={publishing_option}
                    device_gid={device_gid}
                    email_user_gid={email_user_gid}
                    reminderNotificationsList={reminderNotificationsList}
                    notificationReminderLengthLimit={notificationReminderLengthLimit}
                    hasMobileNotificationOption={hasMobileNotificationOption}
                    network={network}
                  />

                  <Flex mt="m" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Input
                            placeholder="Title"
                            label=""
                            defaultValue={title}
                            name="title"
                            id="title"
                            onChange={(e) => {
                              const { value } = e.target
                              if (value.length <= MAX_TITLE_LENGTH) {
                                handleUpdateCustomizationDebounce({ type: 'title', value })
                              }
                            }}
                            maxLength={MAX_TITLE_LENGTH}
                            width="100%"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="title" network={TUMBLR} />
                      </Flex>
                    </Flex>

                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Input
                            placeholder="Slug"
                            label=""
                            defaultValue={slug}
                            name="slug"
                            id="slug"
                            onChange={(e) => {
                              const { value } = e.target
                              if (value.length <= MAX_SLUG_LENGTH) {
                                handleUpdateCustomizationDebounce({ type: 'slug', value })
                              }
                            }}
                            maxLength={MAX_SLUG_LENGTH}
                            width="100%"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="slug" network={TUMBLR} />
                      </Flex>
                    </Flex>

                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        <DropDown
                          innerRef={tagsRef}
                          isMulti
                          label=""
                          placeholder="Tag your post"
                          value={tags ? tags.map((item) => ({ value: item, label: item })) : []}
                          onChange={(options) => {
                            if (options) {
                              const optionsTemp = {}
                              options.forEach(({ value }) => {
                                const values = value.split(',')
                                values.forEach((item) => {
                                  const updatedItem = item.toLowerCase().trim()
                                  if (updatedItem) {
                                    optionsTemp[updatedItem] = updatedItem
                                  }
                                })
                              })

                              handleUpdateCustomization({
                                type: 'tags',
                                value: Object.keys(optionsTemp),
                              })
                            } else {
                              handleUpdateCustomization({
                                type: 'tags',
                                value: [],
                              })
                            }
                          }}
                          openMenuOnFocus
                          dropDownType="CreatableSelect"
                          noOptionsMessage={() => {
                            return 'Tag your post by adding new tags'
                          }}
                          onMenuClose={() => {
                            if (tagsRef && tagsRef.current && tagsRef.current.state.inputValue) {
                              handleUpdateCustomization({
                                type: 'tags',
                                value: [
                                  ...new Set([
                                    ...tagsRef.current.props.value.map(({ value }) => value),
                                    tagsRef.current.state.inputValue,
                                  ]),
                                ],
                              })
                            }
                          }}
                        />
                      </Flex>
                      <TooltipPromptComponent prompt_key="tags" network={TUMBLR} />
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

TumblrPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  selectedProfiles: [],
  reminderNotificationsList: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

TumblrPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isPostFormDirty: PropTypes.bool,
  postErrors: PropTypes.object,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  reminderNotificationsList: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(TumblrPost)
