import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropDown from 'shared/DropDown'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483002;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const formatSelectEntityOption = ({ value, label, type = '', picture_url }) => (
  <Flex alignItems="center" key={value}>
    <ImageWithFallback
      source={picture_url}
      fallbackSource="/assets/company.svg"
      width="20px"
      height="20px"
      borderRadius={radius.l}
    />
    <Flex ml="s" flexDirection="column">
      <Text>
        {label} {type ? `(${type.toLowerCase()})` : ''}
      </Text>
    </Flex>
  </Flex>
)

const ConnectProfileSelectEntityModal = ({
  isOpen,
  handleDismiss,
  entities,
  selectedEntities,
  handleProcessCanvaProfileConnection,
}) => {
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [profileGroups, setProfileGroups] = useState([])

  useEffect(() => {
    if (isOpen) {
      setProfileGroups(
        entities.map(({ id, name, ...props }) => ({
          value: id,
          label: name,
          ...props,
        }))
      )

      if (selectedEntities.length === 1) {
        setSelectedEntity({
          ...{ value: selectedEntities[0].id, label: selectedEntities[0].name, ...selectedEntities[0] },
        })
      }
    }
  }, [isOpen])

  const handleClickContinue = () => {
    if (!selectedEntity) {
      Alert.error('Please select profile group with which Canva account would be associated.', { timeout: 5000 })
    } else {
      const { value } = selectedEntity

      handleProcessCanvaProfileConnection({ entityId: value })

      handleDismiss()
    }
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Where are you adding this Canva account to?</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" p="m" tabIndex={0}>
            <Flex flexDirection="column">
              <DropDown
                placeholder="Select profile group"
                label=""
                value={selectedEntity}
                onChange={(option) => {
                  setSelectedEntity({ ...option })
                }}
                options={profileGroups}
                openMenuOnFocus
                formatOptionLabel={({ ...props }) => formatSelectEntityOption({ ...props })}
              />
            </Flex>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>

            <Button.Gradient onClick={handleClickContinue} isSmall>
              <Text fontWeight="medium">Connect Canva account</Text>
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ConnectProfileSelectEntityModal.defaultProps = {
  selectedEntities: [],
}

ConnectProfileSelectEntityModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array,
  handleProcessCanvaProfileConnection: PropTypes.func.isRequired,
}

export default ConnectProfileSelectEntityModal
