import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import Alert from 'react-s-alert'
import { radius } from 'theme'
import { CUSTOM } from 'consts'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropDown from 'shared/DropDown'
import {
  PUBLISHING_OPTION_DIRECT,
  PUBLISHING_OPTION_NOTIFICATION,
  PUBLISHING_OPTIONS,
  PUBLISHING_OPTIONS_CUSTOM_PROFILE,
} from 'routes/Calendar/consts'

import TooltipPromptComponent from './TooltipPromptComponent'

const formatOptionLabel = ({ value, label, picture_url, description, enabled }, selectValue) => {
  const isSelected = selectValue && selectValue.value === value

  return (
    <Flex alignItems="center" key={value}>
      <ImageWithFallback source={picture_url} width="20px" height="20px" borderRadius={radius.l} />
      <Flex ml="s" flexDirection="column">
        <Text fontSize="xs" color={enabled ? `${isSelected} ? 'white' : 'primaryText'` : 'error'}>
          {label}
        </Text>
        {description && (
          <Text color={enabled ? `${isSelected} ? 'white' : 'primaryText'` : 'error'} fontSize="xxs">
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

const PublicationOptionComponent = ({
  publishing_option,
  handleUpdateCustomization,
  device_gid,
  email_user_gid,
  reminderNotificationsList,
  checkChangeOptionFunction,
  notificationReminderLengthLimit,
  hasMobileNotificationOption,
  theme,
  network,
}) => {
  const notification_value = device_gid || email_user_gid

  let DEFAULT_PUBLISHING_OPTIONS = PUBLISHING_OPTIONS
  let menuPlacement = 'auto'

  if (network === CUSTOM) {
    DEFAULT_PUBLISHING_OPTIONS = PUBLISHING_OPTIONS_CUSTOM_PROFILE
    menuPlacement = 'top'
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Flex mt="m" alignItems="center" width="100%">
        <Flex flexDirection="column" width="100%">
          <DropDown
            placeholder="Publishing options"
            value={
              publishing_option
                ? DEFAULT_PUBLISHING_OPTIONS.find(({ value }) => value === publishing_option)
                : DEFAULT_PUBLISHING_OPTIONS[0]
            }
            onChange={(option) => {
              if (option.value === PUBLISHING_OPTION_NOTIFICATION && reminderNotificationsList.length === 0) {
                Alert.error(
                  `You don't have any registered mobile devices or users with publishing access. <a style="color: ${theme.colors.primary};" href="https://support.vistasocial.com/hc/en-us/articles/7612460663579-Instagram-Story-Publishing-with-Vista-Social" target="_blank">Learn more</a>.`,
                  { timeout: 5000 }
                )
              } else {
                const answer = publishing_option !== option.value ? checkChangeOptionFunction(option) : false

                if (answer) {
                  handleUpdateCustomization({
                    type: 'publishing_option',
                    value: option.value,
                  })

                  if ((device_gid || email_user_gid) && option.value === PUBLISHING_OPTION_DIRECT) {
                    if (device_gid) {
                      handleUpdateCustomization({
                        type: 'device_gid',
                        value: null,
                      })
                      handleUpdateCustomization({
                        type: 'device_name',
                        value: '',
                      })
                    } else if (email_user_gid) {
                      handleUpdateCustomization({
                        type: 'email_user_gid',
                        value: null,
                      })
                      handleUpdateCustomization({
                        type: 'email_user_name',
                        value: '',
                      })
                    }
                  }
                }
              }
            }}
            options={DEFAULT_PUBLISHING_OPTIONS}
            openMenuOnFocus
            isSearchable={false}
            menuPlacement={menuPlacement}
          />
        </Flex>
        <TooltipPromptComponent prompt_key="publishing_option" />
      </Flex>

      {publishing_option === PUBLISHING_OPTION_NOTIFICATION && (
        <Flex mt="m" alignItems="center" width="100%">
          <Flex flexDirection="column" width="100%">
            {reminderNotificationsList.length !== 0 ? (
              <Flex flexDirection="column">
                <DropDown
                  placeholder={hasMobileNotificationOption ? 'Select device or email' : 'Select email'}
                  value={
                    notification_value
                      ? reminderNotificationsList.find(({ value }) => notification_value === value)
                      : null
                  }
                  onChange={(option) => {
                    const { value = null, label = null, enabled, description, type } = option || {}

                    if (option) {
                      if (enabled) {
                        if (type === 'device') {
                          handleUpdateCustomization({
                            type: 'device_gid',
                            value,
                          })
                          handleUpdateCustomization({
                            type: 'device_name',
                            value: label,
                          })

                          if (email_user_gid) {
                            handleUpdateCustomization({
                              type: 'email_user_gid',
                              value: null,
                            })
                            handleUpdateCustomization({
                              type: 'email_user_name',
                              value: '',
                            })
                          }
                        } else if (type === 'user') {
                          handleUpdateCustomization({
                            type: 'email_user_gid',
                            value,
                          })
                          handleUpdateCustomization({
                            type: 'email_user_name',
                            value: label,
                          })

                          if (device_gid) {
                            handleUpdateCustomization({
                              type: 'device_gid',
                              value: null,
                            })
                            handleUpdateCustomization({
                              type: 'device_name',
                              value: '',
                            })
                          }
                        }
                      } else {
                        Alert.error(description, { timeout: 5000 })
                      }
                    } else if (device_gid) {
                      handleUpdateCustomization({
                        type: 'device_gid',
                        value: null,
                      })
                      handleUpdateCustomization({
                        type: 'device_name',
                        value: '',
                      })
                    } else if (email_user_gid) {
                      handleUpdateCustomization({
                        type: 'email_user_gid',
                        value: null,
                      })
                      handleUpdateCustomization({
                        type: 'email_user_name',
                        value: '',
                      })
                    }
                  }}
                  formatOptionLabel={(props, { selectValue }) => {
                    return formatOptionLabel(props, selectValue)
                  }}
                  options={
                    hasMobileNotificationOption
                      ? reminderNotificationsList
                      : reminderNotificationsList
                      ? reminderNotificationsList.filter(({ type }) => type !== 'device')
                      : reminderNotificationsList
                  }
                  openMenuOnFocus
                  error={
                    notificationReminderLengthLimit
                      ? hasMobileNotificationOption
                        ? 'Device or a team member is required when scheduling reminders.'
                        : 'Team member is required when scheduling reminders.'
                      : ''
                  }
                />
              </Flex>
            ) : (
              <Flex alignItems="center">
                <Text>
                  {hasMobileNotificationOption
                    ? 'You do not have any registered mobile devices or team members with publishing access.'
                    : 'You do not have any team members with publishing access.'}
                </Text>
              </Flex>
            )}
          </Flex>

          <TooltipPromptComponent prompt_key="publishing_options" />
        </Flex>
      )}
    </Flex>
  )
}

PublicationOptionComponent.defaultProps = {
  publishing_option: null,
  device_gid: null,
  email_user_gid: null,
  reminderNotificationsList: [],
  checkChangeOptionFunction: () => {
    return true
  },
  notificationReminderLengthLimit: false,
  hasMobileNotificationOption: false,
}

PublicationOptionComponent.propTypes = {
  publishing_option: PropTypes.string,
  handleUpdateCustomization: PropTypes.func.isRequired,
  device_gid: PropTypes.string,
  email_user_gid: PropTypes.number,
  reminderNotificationsList: PropTypes.array,
  checkChangeOptionFunction: PropTypes.func,
  notificationReminderLengthLimit: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  hasMobileNotificationOption: PropTypes.bool,
  network: PropTypes.string.isRequired,
}

export default withTheme(PublicationOptionComponent)
