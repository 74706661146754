import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { radius, space } from 'theme'
import { IMAGE, IMAGE_DYNAMIC, VIDEO, VIDEO_DYNAMIC } from 'consts'
import { getFileIconByExtension, pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import { POST_IMAGE, POST_VIDEO, POST_DOCUMENT, POST_MIXED_MEDIA } from 'routes/Calendar/consts'
import UploadMediaComponent from '../../PostCreationComponent/components/UploadMediaComponent'
import UploadedMediasComponent from '../../PostCreationComponent/components/UploadedMediasComponent'

const StyledAccordion = styled(Accordion)`
  width: 100%;
  background: ${({ theme }) => theme.colors.background_modal} !important;
  border-radius: ${radius.l} !important;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0;

  .MuiAccordionDetails-root {
    padding: 0 ${space.m};
    color: ${({ theme }) => theme.colors.primaryText};
  }

  .MuiIconButton-root {
    padding: 0;
    margin-right: ${space.xs};
  }

  &:before {
    display: none;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
  min-height: 38px !important;
  .MuiAccordionSummary-content {
    margin: 0 !important;
    overflow: hidden;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledSelectedMediasWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.m};
  margin-bottom: ${space.m};
`

const PostMediaWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  flex-direction: column;
  text-align: center;
  background: ${({ theme }) => theme.colors.background_item_light};
`

const StyledMediasWrapper = styled(Flex)`
  overflow: hidden;
  flex: 1;
  gap: ${space.xxs};
  margin-left: ${space.s};
`

const StyledPlayIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MAX_PREVIEW_MEDIA_WIDTH = '24px'
const MAX_PREVIEW_MEDIA_HEIGHT = '24px'

const CustomizationsMediaComponent = ({
  uploadMediaComponentRef,
  user,
  network,
  profileId,
  selectedEntities,
  selectedProfiles,
  customized_post_data,
  showLinkComponent,
  showImageComponent,
  showVideoComponent,
  showDocumentComponent,
  showMixedMediaComponent,
  mediasPreviewDisplay,
  mediasPreviewSizeSmall,
  menuPlacement,
}) => {
  const [isFormExpanded, setIsFormExpanded] = useState(false)

  const { postComponent, postImages, postVideos, postDocuments, postMixedMedias } = customized_post_data

  return (
    <StyledAccordion defaultExpanded expanded={isFormExpanded}>
      <StyledAccordionSummary
        onClick={() => {
          setIsFormExpanded(!isFormExpanded)
        }}
        expandIcon={
          <StyledArrowWrapper>
            <Icon.VistaSocialChevronDown />
          </StyledArrowWrapper>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Flex alignItems="center" pl="s" width="100%" justifyContent="space-between">
          <Text fontSize="xs" color="input_placeholder_color">
            Click to edit media
          </Text>

          {!isFormExpanded && (
            <Fragment>
              {postComponent === POST_IMAGE && postImages && postImages.length !== 0 && (
                <StyledMediasWrapper>
                  {postImages.map((media) => {
                    const { isNew, type, thumbnail_url_small, thumbnail_url, url, id } = media

                    if (isNew) {
                      return null
                    }

                    return (
                      <PostMediaWrapper key={id}>
                        {type === IMAGE && (
                          <ImageWithFallback
                            source={thumbnail_url_small || thumbnail_url || url}
                            fallbackSource={url}
                            width={MAX_PREVIEW_MEDIA_WIDTH}
                            height={MAX_PREVIEW_MEDIA_HEIGHT}
                            borderRadius={radius.l}
                          />
                        )}

                        {type === IMAGE_DYNAMIC && (
                          <Flex width={MAX_PREVIEW_MEDIA_WIDTH} height={MAX_PREVIEW_MEDIA_HEIGHT}>
                            <DynamicMedia media={media} profiles={selectedProfiles} isSmall />
                          </Flex>
                        )}
                      </PostMediaWrapper>
                    )
                  })}
                </StyledMediasWrapper>
              )}

              {postComponent === POST_VIDEO && postVideos && postVideos.length !== 0 && (
                <StyledMediasWrapper>
                  {postVideos.map((media) => {
                    const { isNew, type, thumbnail_url_small, thumbnail_url, url, id } = media

                    if (isNew) {
                      return null
                    }

                    return (
                      <PostMediaWrapper key={id}>
                        {type === VIDEO && (
                          <VideoWithFallback
                            url={url}
                            controls
                            width={MAX_PREVIEW_MEDIA_WIDTH}
                            style={{
                              position: 'relative',
                              borderRadius: radius.l,
                            }}
                            height={MAX_PREVIEW_MEDIA_HEIGHT}
                            fallbackSourceImage={thumbnail_url}
                            config={{
                              file: {
                                attributes: {
                                  poster: thumbnail_url_small || thumbnail_url,
                                },
                              },
                            }}
                            playIcon={
                              <StyledPlayIconWrapper position="absolute">
                                <Image src="/assets/vistasocial/instagram_play.svg" width="10px" height="10px" />
                              </StyledPlayIconWrapper>
                            }
                          />
                        )}
                        {type === VIDEO_DYNAMIC && (
                          <Flex width={MAX_PREVIEW_MEDIA_WIDTH} height={MAX_PREVIEW_MEDIA_HEIGHT}>
                            <DynamicMedia media={media} profiles={selectedProfiles} isSmall />
                          </Flex>
                        )}
                      </PostMediaWrapper>
                    )
                  })}
                </StyledMediasWrapper>
              )}

              {postComponent === POST_DOCUMENT && postDocuments && postDocuments.length !== 0 && (
                <StyledMediasWrapper>
                  {postDocuments.map((media) => {
                    const { isNew, extension, id, thumbnail_url_small, thumbnail_url } = media

                    if (isNew) {
                      return null
                    }

                    return (
                      <PostMediaWrapper key={id}>
                        <ImageWithFallback
                          source={thumbnail_url_small || thumbnail_url || getFileIconByExtension({ extension })}
                          fallbackSource={getFileIconByExtension({ extension })}
                          width={MAX_PREVIEW_MEDIA_WIDTH}
                          height={MAX_PREVIEW_MEDIA_HEIGHT}
                          borderRadius={radius.l}
                        />
                      </PostMediaWrapper>
                    )
                  })}
                </StyledMediasWrapper>
              )}

              {postComponent === POST_MIXED_MEDIA && postMixedMedias && postMixedMedias.length !== 0 && (
                <StyledMediasWrapper>
                  {postMixedMedias.map((media) => {
                    const { isNew, type, thumbnail_url_small, thumbnail_url, url, id } = media

                    if (isNew) {
                      return null
                    }

                    return (
                      <PostMediaWrapper key={id}>
                        {type === IMAGE && (
                          <ImageWithFallback
                            source={thumbnail_url_small || thumbnail_url || url}
                            fallbackSource={url}
                            width={MAX_PREVIEW_MEDIA_WIDTH}
                            height={MAX_PREVIEW_MEDIA_HEIGHT}
                            borderRadius={radius.l}
                          />
                        )}

                        {type === VIDEO && (
                          <VideoWithFallback
                            url={url}
                            controls
                            width={MAX_PREVIEW_MEDIA_WIDTH}
                            style={{
                              position: 'relative',
                              borderRadius: radius.l,
                            }}
                            height={MAX_PREVIEW_MEDIA_HEIGHT}
                            fallbackSourceImage={thumbnail_url}
                            config={{
                              file: {
                                attributes: {
                                  poster: thumbnail_url_small || thumbnail_url,
                                },
                              },
                            }}
                            playIcon={
                              <StyledPlayIconWrapper position="absolute">
                                <Image src="/assets/vistasocial/instagram_play.svg" width="10px" height="10px" />
                              </StyledPlayIconWrapper>
                            }
                          />
                        )}

                        {type === IMAGE_DYNAMIC && (
                          <Flex width={MAX_PREVIEW_MEDIA_WIDTH} height={MAX_PREVIEW_MEDIA_HEIGHT}>
                            <DynamicMedia media={media} profiles={selectedProfiles} isSmall />
                          </Flex>
                        )}

                        {type === VIDEO_DYNAMIC && (
                          <Flex width={MAX_PREVIEW_MEDIA_WIDTH} height={MAX_PREVIEW_MEDIA_HEIGHT}>
                            <DynamicMedia media={media} profiles={selectedProfiles} isSmall />
                          </Flex>
                        )}
                      </PostMediaWrapper>
                    )
                  })}
                </StyledMediasWrapper>
              )}
            </Fragment>
          )}
        </Flex>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="flex-end" mr={`-${pxToRem(12)}`}>
            <UploadMediaComponent
              ref={uploadMediaComponentRef}
              user={user}
              post={customized_post_data}
              network={network}
              profileId={profileId}
              selectedProfiles={selectedProfiles}
              selectedEntities={selectedEntities}
              showLinkComponent={showLinkComponent}
              showImageComponent={showImageComponent}
              showVideoComponent={showVideoComponent}
              showDocumentComponent={showDocumentComponent}
              showMixedMediaComponent={showMixedMediaComponent}
              menuPlacement={menuPlacement}
            />
          </Flex>
          <StyledSelectedMediasWrapper display={mediasPreviewDisplay}>
            <UploadedMediasComponent
              user={user}
              post={customized_post_data}
              network={network}
              profileId={profileId}
              selectedProfiles={selectedProfiles}
              selectedEntities={selectedEntities}
              uploadMediaComponentRef={uploadMediaComponentRef}
              isSmall={mediasPreviewSizeSmall}
            />
          </StyledSelectedMediasWrapper>
        </Flex>
      </AccordionDetails>
    </StyledAccordion>
  )
}

CustomizationsMediaComponent.defaultProps = {
  selectedEntities: [],
  selectedProfiles: [],
  showLinkComponent: true,
  showImageComponent: true,
  showVideoComponent: true,
  showDocumentComponent: true,
  showMixedMediaComponent: true,
  mediasPreviewDisplay: 'flex',
  mediasPreviewSizeSmall: false,
  menuPlacement: 'bottom',
}

CustomizationsMediaComponent.propTypes = {
  uploadMediaComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  user: PropTypes.object.isRequired,
  network: PropTypes.string.isRequired,
  profileId: PropTypes.number.isRequired,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  customized_post_data: PropTypes.object.isRequired,
  showLinkComponent: PropTypes.bool,
  showImageComponent: PropTypes.bool,
  showVideoComponent: PropTypes.bool,
  showDocumentComponent: PropTypes.bool,
  showMixedMediaComponent: PropTypes.bool,
  mediasPreviewDisplay: PropTypes.string,
  mediasPreviewSizeSmall: PropTypes.bool,
  menuPlacement: PropTypes.string,
}

export default CustomizationsMediaComponent
