import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import useDrivePicker from 'react-google-drive-picker-no-default-scope'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  FEATURE_FIND_CONTENT,
  FEATURE_CANVA,
  FEATURE_DRIVES,
  FEATURE_LINK_SHORTENER,
  IMAGE,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  MAX_VIDEO_SIZE,
  MAX_VIDEO_SIZE_TEXT,
  MAX_DOCUMENT_SIZE,
  MAX_DOCUMENT_SIZE_TEXT,
  VIDEO,
  DOCUMENT,
} from 'consts'
import request from 'utils/request'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Divider from 'components/atoms/Divider'
import { Text } from 'components/atoms/Typography'
import MediaSettings from 'routes/Media/MediaSettings'
import ShowComponentDropdownMenu from './ShowComponentDropdownMenu'
import MediaLibraryModal from '../../MediaLibraryModal'
import MediaDiscoverModal from '../../MediaDiscoverModal'
import MediaUploadByLinkModal from '../../MediaUploadByLinkModal'
import CanvaDesignModal from '../../CanvaDesignModal'
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPES,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  ROUTE_MEDIA,
  ROUTE_URL,
  URL_REGEXP,
  VIDEO_EXTENSIONS,
  VIDEO_MIME_TYPES,
  DOCUMENT_EXTENSIONS,
  DOCUMENT_MIME_TYPES,
} from '../../../consts'
import {
  dropboxPickerConfiguration,
  googleDrivePickerConfiguration,
  oneDrivePickerConfiguration,
  uploadMediaToServerByFormDataHelper,
} from '../../../helpers'
import VistaCreateDesignModal from '../../VistaCreateDesignModal'
// eslint-disable-next-line import/no-cycle
import DynamicMediaModal from '../../DynamicMediaModal'
import CalendarItemTooltip from '../../CalendarItemTooltip'
import { usePostCreationDispatch } from '../../../contexts/PostCreationContext'

const IconWrapper = styled(Box)`
  padding: 0 ${space.s};
  width: 36px;
  height: 36px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.composer_icons_color};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
   svg {
      fill: ${theme.colors.primary};
   }
  `}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledDropDownMenuItem = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${space.s};
  text-align: left;
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.primaryText};
  min-width: 175px;
  width: 100%;
  cursor: pointer;
  height: 30px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary_text_reverted} !important;

    svg {
      stroke: ${({ theme }) => theme.colors.primary_text_reverted};
      fill: ${({ theme }) => theme.colors.primary_text_reverted};
    }

    p {
      color: ${({ theme }) => theme.colors.primary_text_reverted};
    }
  }
`

const StyledDropDownSubmenuWrapper = styled(Flex)`
  display: none;
  flex-direction: column;
  position: absolute;
  width: 210px;

  padding: ${space.xs} 0;
  background: ${({ theme }) => theme.colors.dropdown_component_background};
  ${({ showAddMediaDropdownPosition }) => (showAddMediaDropdownPosition === 'left' ? 'left: -211px' : 'right: -211px')};

  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
`

const LINK_URL_INITIAL_STATES = {
  isGettingSiteInformation: false,
  isLinkUrlCorrect: undefined, // ideally null
  isLinkUrlParsed: undefined,
  hasLinkUrlInformation: false,
  isTitleEditing: false,
  isDescriptionEditing: false,
}

const MEDIA_LIBRARY_MODAL_OPTIONS = {
  isOpen: false,
  postComponent: null,
}

const MEDIA_DISCOVER_MODAL_OPTIONS = {
  isOpen: false,
  isAddingMedia: false,
  postComponent: null,
}

const MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS = {
  isOpen: false,
  hasError: '',
  postComponent: null,
}

const MEDIA_SETTINGS_MODAL_OPTIONS = {
  isOpen: false,
  uploadedMedias: [],
}

const DYNAMIC_MEDIA_MODAL_OPTIONS = {
  isOpen: false,
  media: null,
  postComponent: null,
}

const DEFAULT_CANVA_DESIGN_OPTIONS = { isOpen: false, type: null }

const DEFAULT_BLOCK_MEDIA_OPTIONS = {
  iconNameEnding: 'Lock',
  iconWidthEnding: '14px',
  iconColorEnding: 'error',
}

const DROPDOWN_INTERVALS = {
  [IMAGE]: null,
  [VIDEO]: null,
}

const UploadMediaComponent = forwardRef(
  (
    {
      post,
      network,
      profileId,
      selectedEntities,
      selectedProfiles,
      uploadingMediasAmount,
      setUploadingMediasAmount,
      user,
      showLinkComponent,
      showImageComponent,
      showVideoComponent,
      showDocumentComponent,
      showMixedMediaComponent,
      handleSpecialRerender,
      specialPostKey,
      maxImageAttachmentsAllowed,
      maxVideoAttachmentsAllowed,
      maxMixedMediaAttachmentsAllowed,
      handleUpdateLinkInput,
      menuPlacement,
    },
    ref
  ) => {
    const inputImageFileRef = useRef(null)
    const inputVideoFileRef = useRef(null)
    const inputDocumentFileRef = useRef(null)
    const inputMixedMediaFileRef = useRef(null)
    const tooltipRef = useRef(null)

    const { postDispatch } = usePostCreationDispatch()

    const [newMediasViaInput, setNewMediasViaInput] = useState([])
    const [openPicker, authResponse] = useDrivePicker()
    const [savedGoogleDocsAuthResponse, setSavedGoogleDocsAuthResponse] = useState({})
    const [sharedDocsForUpload, setSharedDocsForUpload] = useState([])

    const { postImages = [], postVideos = [], postDocuments = [], postMixedMedias = [], link, postComponent } = post

    const {
      url = '',
      picture_url = '',
      // thumbnail_url = '',
    } = link || {}

    const [linkUrlStates, setLinkUrlStates] = useState({ ...LINK_URL_INITIAL_STATES })
    const [mediaLibraryOptions, setMediaLibraryOptions] = useState({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
    const [mediaDiscoverOptions, setMediaDiscoverOptions] = useState({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
    const [mediaUploadByLinkOptions, setMediaUploadByLinkOptions] = useState({ ...MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
    const [mediaSettingsOptions, setMediaSettingsOptions] = useState({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
    const [dynamicMediaOptions, setDynamicMediaOptions] = useState({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })
    const [canvaDesignOptions, setCanvaDesignOptions] = useState({ ...DEFAULT_CANVA_DESIGN_OPTIONS })
    const [vistaCreateDesignOptions, setVistaCreateDesignOptions] = useState({ isOpen: false })

    const [featuresEnabled, setFeaturesEnabled] = useState({
      [FEATURE_LINK_SHORTENER]: { enabled: true },
      [FEATURE_FIND_CONTENT]: { enabled: true },
      [FEATURE_CANVA]: { enabled: true },
      [FEATURE_DRIVES]: { enabled: true },
    })

    const onChangeDispatchPost = ({ type, data }) => {
      const postDispatchData = { type, data, network, profileId, specialPostKey }

      data.selectedProfiles = selectedProfiles

      postDispatch(postDispatchData)
    }

    useImperativeHandle(ref, () => ({
      handleReturnPost() {
        return { post }
      },
      setUploadingMedias(medias) {
        const postImages = []
        const postVideos = []
        const postDocuments = []
        const postMixedMedias = []

        const media_types = new Set()

        medias.forEach(({ type }) => {
          media_types.add(type)
        })

        const allAttachmentsAreOneType = media_types.size <= 1

        medias.forEach((media) => {
          const { type, tempId } = media

          if (allAttachmentsAreOneType) {
            if (type === IMAGE) {
              postImages.push({ tempId, isNew: true, network })
            } else if (type === VIDEO) {
              postVideos.push({ tempId, isNew: true, network })
            } else if (type === DOCUMENT) {
              postDocuments.push({ tempId, isNew: true, network })
            }
          } else {
            postMixedMedias.push({ tempId, isNew: true, network })
          }
        })

        if (postImages.length !== 0) {
          onChangeDispatchPost({ type: 'add_media', data: { medias: postImages, post_media_key: 'postImages' } })
        }

        if (postVideos.length !== 0) {
          onChangeDispatchPost({ type: 'add_media', data: { medias: postVideos, post_media_key: 'postVideos' } })
        }

        if (postDocuments.length !== 0) {
          onChangeDispatchPost({
            type: 'add_media',
            data: { medias: postDocuments, post_media_key: 'postDocuments' },
          })
        }

        if (postMixedMedias.length !== 0) {
          onChangeDispatchPost({
            type: 'add_media',
            data: { medias: postMixedMedias, post_media_key: 'postMixedMedias' },
          })
        }

        setNewMediasViaInput([...medias])
      },
      async getLinkUrlInformation({ url }) {
        // eslint-disable-next-line no-use-before-define
        await getLinkUrlInformation({ url })
      },
      uploadMediaToServerByStringData(data) {
        // eslint-disable-next-line no-use-before-define
        uploadMediaToServerByStringData(data)
      },
      setLinkUrlStates(data) {
        if (data) {
          setLinkUrlStates({ ...data })
        } else {
          setLinkUrlStates({ ...LINK_URL_INITIAL_STATES })
        }
      },
      getLinkUrlStates() {
        return linkUrlStates
      },
    }))

    useEffect(() => {
      if (authResponse && authResponse.access_token) {
        setSavedGoogleDocsAuthResponse({ ...authResponse })
      }
    }, [authResponse])

    useEffect(() => {
      if (sharedDocsForUpload && sharedDocsForUpload.length !== 0) {
        let MEDIA_DATA = []

        if (postComponent === POST_IMAGE) {
          MEDIA_DATA = postImages
        } else if (postComponent === POST_VIDEO) {
          MEDIA_DATA = postVideos
        } else if (postComponent === POST_DOCUMENT) {
          MEDIA_DATA = postDocuments
        } else if (postComponent === POST_MIXED_MEDIA) {
          MEDIA_DATA = postMixedMedias
        }

        // eslint-disable-next-line no-use-before-define
        handleClickAddMedias({
          selectedMedias: [
            ...MEDIA_DATA,
            ...sharedDocsForUpload.map(({ url, name, mimeType, sizeBytes }) => ({
              url: savedGoogleDocsAuthResponse.access_token
                ? `${url}&access_token=${savedGoogleDocsAuthResponse.access_token}`
                : url,
              tempId: new Date().getTime() + Math.floor(Math.random() * 1000),
              name,
              mimeType,
              size: sizeBytes,
            })),
          ],
          postComponent,
        })
        setSharedDocsForUpload([])
      }
    }, [sharedDocsForUpload])

    useEffect(() => {
      const { link } = post || {}

      const { hasLinkUrlInformation, isLinkUrlCorrect, isLinkUrlParsed, needsUpload } = link || {}

      if (hasLinkUrlInformation !== undefined || isLinkUrlCorrect !== undefined || isLinkUrlParsed !== undefined) {
        linkUrlStates.hasLinkUrlInformation = !!hasLinkUrlInformation
        linkUrlStates.isLinkUrlCorrect = !!isLinkUrlCorrect
        linkUrlStates.isLinkUrlParsed = !!isLinkUrlParsed

        setLinkUrlStates({ ...linkUrlStates })
      }

      if (
        (post.postComponent === POST_LINK || needsUpload) &&
        !linkUrlStates.isLinkUrlParsed
        //  && linkUrlStates.isLinkUrlCorrect // removed this flag for links to be parsed and checked in the getLinkUrlInformation function
      ) {
        if (url) {
          // eslint-disable-next-line no-use-before-define
          getLinkUrlInformation({ url })

          link.needsUpload = false
        }
      }

      const keys = [
        { key: 'postImages', postDataForRemoving: [], uploadingTo: 'postMediaImage', mediaURL: 'imageURL' },
        { key: 'postVideos', postDataForRemoving: [], uploadingTo: 'postMediaVideo', mediaURL: 'videoURL' },
        { key: 'postDocuments', postDataForRemoving: [], uploadingTo: 'postMediaDocument', mediaURL: 'documentURL' },
        {
          key: 'postMixedMedias',
          postDataForRemoving: [],
          uploadingTo: 'postMediaMixedMedia',
          mediaURL: 'mixedMediaURL',
        },
      ]

      keys.forEach(({ key, postDataForRemoving, uploadingTo, mediaURL }) => {
        if (post[key] && post[key].length !== 0) {
          post[key].forEach((media) => {
            const { tempId, uploadingId, needsUpload } = media
            if (needsUpload && ((!network && !media.network) || media.network === network)) {
              if (media[mediaURL]) {
                // eslint-disable-next-line no-use-before-define
                uploadMediaToServerByStringData({
                  mediaURL: media[mediaURL],
                  thumbnailUrl: media.thumbnail_url,
                  uploadingId: tempId || uploadingId,
                  uploadingTo,
                  network,
                  post_id: post.post_id,
                  profile_id: post.profile_id,
                  post_type: post.customizations_post_type,
                })
                media.needsUpload = false
              } else {
                postDataForRemoving.push(tempId || uploadingId)
              }
            }
          })
        }
      })

      keys.forEach(({ key, postDataForRemoving }) => {
        if (postDataForRemoving.length !== 0) {
          const postDataForRemovingTemp = [...post[key]]

          postDataForRemoving.forEach((id) => {
            const foundIndex = postDataForRemovingTemp.findIndex(
              ({ tempId, uploadingId }) => tempId === id || uploadingId === id
            )
            if (foundIndex > -1) {
              postDataForRemovingTemp.splice(foundIndex, 1)
            }
          })

          onChangeDispatchPost({
            type: 'rewrite_medias',
            data: { medias: postDataForRemovingTemp, post_media_key: key },
          })
        }
      })
    }, [post])

    useEffect(() => {
      let foundUploadedMedias = []

      const keys = [
        { key: 'postImages', type: IMAGE },
        { key: 'postVideos', type: VIDEO },
        { key: 'postDocuments', type: DOCUMENT },
        { key: 'postMixedMedias', type: undefined },
      ]

      keys.forEach(({ key, type }) => {
        if (post[key]) {
          for (let i = 0; i < post[key].length; i++) {
            if ((!network && !post[key][i].network) || post[key][i].network === network) {
              if (post[key][i].tempId) {
                foundUploadedMedias = []
                break
              } else if (post[key][i].isNew) {
                // // type setting could be not needed. Didn't find code where this is used (04-29-2024)
                // if (type) {
                //   post[key][i].type = type
                // }

                foundUploadedMedias.push({ ...post[key][i] })
              }
            }
          }
        }
      })

      if (foundUploadedMedias.length !== 0 && !mediaSettingsOptions.isOpen) {
        setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: foundUploadedMedias } })
      }
    }, [post])

    useEffect(() => {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_FIND_CONTENT, FEATURE_LINK_SHORTENER, FEATURE_CANVA, FEATURE_DRIVES],
        }),
      })
    }, [])

    useEffect(() => {
      if (newMediasViaInput.length !== 0) {
        newMediasViaInput.forEach((item) => {
          // eslint-disable-next-line no-use-before-define
          uploadMediaToServerByFormData(item)
        })

        setNewMediasViaInput([])
      }
    }, [newMediasViaInput])

    const handleClickSelectPostComponent = (component) => {
      const showComponent = !(postComponent && postComponent === component)

      const postComponents = [POST_LINK, POST_VIDEO, POST_IMAGE, POST_DOCUMENT, POST_MIXED_MEDIA]

      if (postComponents.includes(component)) {
        let postComponent

        if (showComponent) {
          postComponent = component
        } else {
          postComponent = null
        }

        if (!network && !profileId && !specialPostKey) {
          const { link = {} } = post
          const { url = '' } = link || {}

          let foundLink = ''

          if (!url) {
            const words = post.postText
              ? post.postText
                  .split('\n')
                  .join(' ')
                  .split(' ')
              : []

            for (let i = 0; i < words.length; i++) {
              const res = words[i].match(URL_REGEXP)
              if (res !== null) {
                foundLink = words[i]
                break
              }
            }
          } else {
            foundLink = url
          }

          if (foundLink) {
            link.url = foundLink
            link.active = postComponent === POST_LINK
            link.present = true

            handleUpdateLinkInput({ value: foundLink })
          } else if (url && postComponent === POST_LINK) {
            link.active = true
            link.present = true
          } else {
            link.active = false
          }

          onChangeDispatchPost({ type: 'link', data: { link } })
        }

        onChangeDispatchPost({ type: 'post_component', data: { postComponent } })
      }
    }

    const uploadMediaToServerByStringData = async ({
      base64url,
      mediaURL,
      thumbnailUrl,
      uploadingId,
      uploadingTo = 'postMediaImage',
      network,
      post_id,
      profile_id,
      post_type,
    }) => {
      let tempId
      let type = IMAGE

      if (uploadingTo === 'postMediaVideo') {
        type = VIDEO
      } else if (uploadingTo === 'postMediaDocument') {
        type = DOCUMENT
      } else if (uploadingTo === 'postMediaMixedMedia') {
        type = ''
      }

      const uploadingToData = {
        postMediaImage: { key: 'postImages', type: IMAGE },
        postMediaVideo: { key: 'postVideos', type: VIDEO },
        postMediaDocument: { key: 'postDocuments', type: DOCUMENT },
        postMediaMixedMedia: { key: 'postMixedMedias', type: undefined },
      }

      try {
        const response = await request({
          method: 'POST',
          body: { base64url, mediaURL, thumbnailUrl, mediaType: type, post_id, profile_id, post_type },
          path: `${ROUTE_MEDIA}?tempId=${uploadingId}`,
        })

        const { error, ...media } = response || {}

        ;({ tempId } = response || {})

        const { media_gid } = media || {}

        if (!response || error || !media_gid) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })

          if (mediaUploadByLinkOptions.isOpen) {
            mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
            setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
          }
        } else if (mediaUploadByLinkOptions.isOpen) {
          // eslint-disable-next-line no-use-before-define
          handleClickCloseMediaUploadByLinkModal()
        }

        delete media.tempId

        if (uploadingToData[uploadingTo]) {
          const { key, type } = uploadingToData[uploadingTo]

          if (error || !response || !media_gid) {
            onChangeDispatchPost({
              type: 'remove_temporary_media',
              data: { post_media_key: key, media_temp_id: Number(tempId) },
            })
          } else {
            // this if type code could be not needed, 04-29-2024
            // if (type) {
            //   media.type = type
            // }

            onChangeDispatchPost({
              type: 'update_temporary_media',
              data: {
                post_media_key: key,
                media_temp_id: Number(tempId),
                media: { ...media, id: media_gid, isNew: true, network },
              },
            })
          }
        }
      } catch (error) {
        if (uploadingToData[uploadingTo]) {
          const { key } = uploadingToData[uploadingTo]

          onChangeDispatchPost({
            type: 'remove_temporary_media',
            data: { post_media_key: key, media_temp_id: Number(tempId) },
          })
        }

        if (mediaUploadByLinkOptions.isOpen) {
          mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
          setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
        }

        errorHelper({
          error,
          componentName: UploadMediaComponent.displayName,
          functionName: 'uploadMediaToServerByStringData',
        })
      }
    }

    const uploadMediaToServerByFormData = async ({ file, uploadingId, network }) => {
      const { tempId, media_gid, media, error } = await uploadMediaToServerByFormDataHelper({
        file,
        uploadingId,
      })

      delete media.tempId

      let foundMedia = false

      const media_keys = [
        { key: 'postVideos', type: VIDEO, postComponent: POST_VIDEO },
        { key: 'postImages', type: IMAGE, postComponent: POST_IMAGE },
        { key: 'postDocuments', type: DOCUMENT, postComponent: POST_DOCUMENT },
        { key: 'postMixedMedias', type: undefined, postComponent: POST_MIXED_MEDIA },
      ]

      media_keys.forEach(({ key, type, postComponent }) => {
        if (post[key]) {
          const foundPostMediaIndex = post[key].findIndex(
            (media) => (media.tempId || media.uploadingId) === Number(tempId)
          )

          if (foundPostMediaIndex > -1) {
            foundMedia = true
            if (error) {
              onChangeDispatchPost({
                type: 'remove_temporary_media',
                data: { post_media_key: key, media_temp_id: Number(tempId) },
              })
            } else {
              // this if type code could be not needed, 04-29-2024
              // if (type) {
              //   media.type = type
              // }

              onChangeDispatchPost({
                type: 'update_temporary_media',
                data: {
                  post_media_key: key,
                  media_temp_id: Number(tempId),
                  media: { ...media, id: media_gid, isNew: true, network },
                },
              })

              if (!post.postComponent) {
                onChangeDispatchPost({ type: 'post_component', data: { postComponent } })
              }
            }
          }
        }
      })

      if (!foundMedia && !error) {
        media_keys.forEach(({ key, type, postComponent }) => {
          if (media.type === type || !type) {
            onChangeDispatchPost({
              type: 'update_temporary_media',
              data: {
                post_media_key: key,
                media_temp_id: Number(tempId),
                media: { ...media, id: media_gid, isNew: true, network },
              },
            })

            if (!post.postComponent) {
              onChangeDispatchPost({ type: 'post_component', data: { postComponent } })
            }
          }
        })
      }
      setUploadingMediasAmount(uploadingMediasAmount - 1)

      if (error) {
        Alert.error(error, { timeout: 5000 })
      }
    }

    const handlePostMediaFileChange = ({ event, key }) => {
      if (event.currentTarget.files.length !== 0) {
        let MAX_MEDIA_ATTACHMENTS_ALLOWED = 0
        let MAX_MEDIA_SIZE = 0
        let MAX_MEDIA_SIZE_TEXT = '0'
        let EXTENSIONS = []

        if (key === 'postImages') {
          MAX_MEDIA_ATTACHMENTS_ALLOWED = MAX_IMAGES_ATTACHMENTS_ALLOWED
          MAX_MEDIA_SIZE = MAX_IMAGE_SIZE
          MAX_MEDIA_SIZE_TEXT = MAX_IMAGE_SIZE_TEXT
          EXTENSIONS = IMAGE_EXTENSIONS
        } else if (key === 'postVideos') {
          MAX_MEDIA_ATTACHMENTS_ALLOWED = MAX_VIDEOS_ATTACHMENTS_ALLOWED
          MAX_MEDIA_SIZE = MAX_VIDEO_SIZE
          MAX_MEDIA_SIZE_TEXT = MAX_VIDEO_SIZE_TEXT
          EXTENSIONS = VIDEO_EXTENSIONS
        } else if (key === 'postDocuments') {
          MAX_MEDIA_ATTACHMENTS_ALLOWED = MAX_DOCUMENTS_ATTACHMENTS_ALLOWED
          MAX_MEDIA_SIZE = MAX_DOCUMENT_SIZE
          MAX_MEDIA_SIZE_TEXT = MAX_DOCUMENT_SIZE_TEXT
          EXTENSIONS = DOCUMENT_EXTENSIONS
        } else if (key === 'postMixedMedias') {
          MAX_MEDIA_ATTACHMENTS_ALLOWED = MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED
          EXTENSIONS = [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS]
        }

        const { files } = event.currentTarget

        let extensionError = false
        let sizeError = false

        let mediasCounter = post[key] ? post[key].length : 0

        const newMedias = []

        for (const file of files) {
          if (mediasCounter <= MAX_MEDIA_ATTACHMENTS_ALLOWED) {
            const { name, size } = file

            const extension = name.substring(name.lastIndexOf('.')).toLowerCase()

            let foundExtension = false

            if (key === 'postMixedMedias') {
              if (IMAGE_EXTENSIONS.includes(extension)) {
                foundExtension = true
                MAX_MEDIA_SIZE = MAX_IMAGE_SIZE
                MAX_MEDIA_SIZE_TEXT = MAX_IMAGE_SIZE_TEXT
              } else if (VIDEO_EXTENSIONS.includes(extension)) {
                foundExtension = true
                MAX_MEDIA_SIZE = MAX_VIDEO_SIZE
                MAX_MEDIA_SIZE_TEXT = MAX_VIDEO_SIZE_TEXT
              }
            } else if (EXTENSIONS.includes(extension)) {
              foundExtension = true
            }

            if (foundExtension) {
              if (size <= MAX_MEDIA_SIZE) {
                const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
                newMedias.push({ tempId: uploadingId, id: uploadingId, isNew: true, network })
                mediasCounter++
                newMediasViaInput.push({ file, uploadingId, network })
              } else {
                sizeError = true
              }
            } else {
              extensionError = true
            }
          }
        }
        if (extensionError) {
          Alert.error(`Only ${EXTENSIONS.join(', ')} files allowed!`)
        }
        if (sizeError) {
          Alert.error(`The size of the media must be less than ${MAX_MEDIA_SIZE_TEXT}!`)
        }

        if (newMedias.length) {
          onChangeDispatchPost({ type: 'add_media', data: { post_media_key: key, medias: newMedias } })
        }

        if (newMediasViaInput.length !== 0) {
          setNewMediasViaInput([...newMediasViaInput])
        }
      }
    }

    const handleClickGoogleDrive = ({ postComponent }) => {
      let MIME_TYPES = ''
      let VIEW_ID = 'DOCS_IMAGES_AND_VIDEOS'

      if (postComponent === POST_IMAGE) {
        MIME_TYPES = Object.keys(IMAGE_MIME_TYPES)
          .map((mime) => `image/${mime}`)
          .join(',')
      } else if (postComponent === POST_VIDEO) {
        MIME_TYPES = Object.keys(VIDEO_MIME_TYPES)
          .map((mime) => `video/${mime}`)
          .join(',')
      } else if (postComponent === POST_DOCUMENT) {
        MIME_TYPES = Object.keys(DOCUMENT_MIME_TYPES)
          .map((mime) => `application/${mime}`)
          .join(',')
        VIEW_ID = 'DOCS'
      }

      const { access_token } = authResponse || {}

      if (access_token) {
        savedGoogleDocsAuthResponse.access_token = access_token
        setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })
      }

      const { config } = googleDrivePickerConfiguration({
        access_token,
        mime_types: MIME_TYPES,
        view_id: VIEW_ID,
        callbackFunction: (data) => {
          const { docs = [] } = data
          setSharedDocsForUpload([...docs])
        },
      })

      openPicker(config)
    }

    const handleClickDropBox = ({ postComponent }) => {
      savedGoogleDocsAuthResponse.access_token = null
      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

      let EXTENSIONS = []

      if (postComponent === POST_IMAGE) {
        EXTENSIONS = IMAGE_EXTENSIONS
      } else if (postComponent === POST_VIDEO) {
        EXTENSIONS = VIDEO_EXTENSIONS
      } else if (postComponent === POST_DOCUMENT) {
        EXTENSIONS = DOCUMENT_EXTENSIONS
      }

      const { config } = dropboxPickerConfiguration({
        extensions: EXTENSIONS,
        callbackFunction: (files) => {
          setSharedDocsForUpload([...files.map(({ link }) => ({ url: link }))])
        },
      })

      Dropbox.choose(config)
    }

    const handleClickOneDrive = ({ postComponent }) => {
      savedGoogleDocsAuthResponse.access_token = null
      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

      let EXTENSIONS = []

      if (postComponent === POST_IMAGE) {
        EXTENSIONS = IMAGE_EXTENSIONS
      } else if (postComponent === POST_VIDEO) {
        EXTENSIONS = VIDEO_EXTENSIONS
      } else if (postComponent === POST_DOCUMENT) {
        EXTENSIONS = DOCUMENT_EXTENSIONS
      }

      const { config } = oneDrivePickerConfiguration({
        extensions: EXTENSIONS.join(','),
        callbackFunction: (files) => {
          if (files && files.value && files.value.length !== 0) {
            setSharedDocsForUpload([...files.value.map((item) => ({ url: item['@microsoft.graph.downloadUrl'] }))])
          }
        },
      })

      OneDrive.open(config)
    }

    const handleClickOpenMediaLibraryModal = ({ postComponent }) => {
      mediaLibraryOptions.isOpen = true
      mediaLibraryOptions.postComponent = postComponent

      setMediaLibraryOptions({ ...mediaLibraryOptions })
    }

    const handleClickCloseMediaLibraryModal = () => {
      setMediaLibraryOptions({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
    }

    const handleClickOpenMediaDiscoverModal = ({ postComponent }) => {
      mediaDiscoverOptions.isOpen = true
      mediaDiscoverOptions.postComponent = postComponent
      setMediaDiscoverOptions({ ...mediaDiscoverOptions })
    }

    const handleClickCloseMediaDiscoverModal = () => {
      setMediaDiscoverOptions({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
    }

    const handleClickOpenMediaUploadByLinkModal = ({ postComponent }) => {
      setMediaUploadByLinkOptions({ ...{ isOpen: true, postComponent } })
    }

    const handleClickCloseMediaUploadByLinkModal = () => {
      setMediaUploadByLinkOptions({ ...MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
    }

    const handleClickCloseCanvaModal = () => {
      setCanvaDesignOptions({ ...DEFAULT_CANVA_DESIGN_OPTIONS })
    }

    const handleClickCloseVistaCreateModal = () => {
      setVistaCreateDesignOptions({ isOpen: false })
    }

    const handleClickCloseMediaSettingsModal = () => {
      setMediaSettingsOptions({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
    }

    const handleClickAddMedias = async ({ selectedMedias, postComponent }) => {
      const uploadedMedias = []
      const mediasForUpload = []

      selectedMedias.forEach((item) => {
        const { id } = item
        if (id) {
          uploadedMedias.push(item)
        } else {
          mediasForUpload.push(item)
        }
      })

      const newMedias = []

      const postComponents = [POST_IMAGE, POST_VIDEO, POST_DOCUMENT, POST_MIXED_MEDIA]

      for (const media of mediasForUpload) {
        if (postComponents.includes(postComponent)) {
          let uploadingTo = 'postMediaImage'

          if (postComponent === POST_VIDEO) {
            uploadingTo = 'postMediaVideo'
          } else if (postComponent === POST_DOCUMENT) {
            uploadingTo = 'postMediaDocument'
          } else if (postComponent === POST_MIXED_MEDIA) {
            uploadingTo = 'postMediaMixedMedia'
          }

          const { url } = media
          const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
          newMedias.push({ tempId: uploadingId, id: uploadingId, isNew: true, network })
          uploadMediaToServerByStringData({
            mediaURL: url,
            uploadingId,
            uploadingTo,
            network,
          })
        }
      }

      if (mediaDiscoverOptions.isOpen) {
        handleClickCloseMediaDiscoverModal()
      } else if (mediaLibraryOptions.isOpen) {
        handleClickCloseMediaLibraryModal()
      }

      const keys = [
        { key: 'postImages', postComponent: POST_IMAGE },
        { key: 'postVideos', postComponent: POST_VIDEO },
        { key: 'postDocuments', postComponent: POST_DOCUMENT },
        { key: 'postMixedMedias', postComponent: POST_MIXED_MEDIA },
      ]

      const foundKey = keys.find((key) => key.postComponent === postComponent)

      if (foundKey) {
        onChangeDispatchPost({
          type: 'rewrite_medias',
          data: { post_media_key: foundKey.key, medias: [...uploadedMedias, ...newMedias] },
        })
      }
    }

    const handleAddDesignMedias = ({ urls }) => {
      let postMedias = []

      if (postComponent === POST_IMAGE) {
        postMedias = postImages
      } else if (postComponent === POST_VIDEO) {
        postMedias = postVideos
      } else if (postComponent === POST_MIXED_MEDIA) {
        postMedias = postMixedMedias
      }

      handleClickAddMedias({
        selectedMedias: [...postMedias, ...urls.map(({ url }) => ({ url, tempId: new Date().getTime() }))],
        postComponent,
      })
    }

    const handleUpdateUploadedMedias = ({ medias }) => {
      let lastPostComponent = null

      const keys = [
        { key: 'postImages', maxAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED, postComponent: POST_IMAGE },
        { key: 'postVideos', maxAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED, postComponent: POST_VIDEO },
        {
          key: 'postDocuments',
          maxAttachmentsAllowed: MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
          postComponent: POST_DOCUMENT,
        },
        {
          key: 'postMixedMedias',
          maxAttachmentsAllowed: MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
          postComponent: POST_MIXED_MEDIA,
        },
      ]

      medias.forEach((media) => {
        media.isNew = false

        keys.forEach(({ key, maxAttachmentsAllowed, postComponent }) => {
          const foundIndex = post[key] ? post[key].findIndex(({ media_gid }) => media_gid === media.media_gid) : -1

          if (foundIndex > -1) {
            delete media.isNew
            delete media.tempId
            delete media.uploadingId
            if (post[key].length <= maxAttachmentsAllowed) {
              onChangeDispatchPost({
                type: 'update_existing_media',
                data: { post_media_key: key, media },
              })
            } else {
              onChangeDispatchPost({
                type: 'remove_existing_media',
                data: { post_media_key: key, media },
              })
            }

            lastPostComponent = postComponent
          }
        })
      })

      if (!post.postComponent && lastPostComponent) {
        onChangeDispatchPost({ type: 'post_component', data: { postComponent: lastPostComponent } })
      }

      setUploadingMediasAmount(0)
    }

    const handleClickOpenMediaDynamicModal = ({ media, postComponent }) => {
      setDynamicMediaOptions({ ...{ isOpen: true, media, postComponent } })
    }

    const handleClickCloseMediaDynamicModal = () => {
      setDynamicMediaOptions({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })
    }

    const handleLinkUrlImageFileChange = async (event) => {
      // if (event.currentTarget.files.length !== 0) {
      //   let extensionError = false
      //   let sizeError = false
      //
      //   const file = event.currentTarget.files[0]
      //   const { name, size } = file
      //   if (IMAGE_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')).toLowerCase())) {
      //     if (size <= MAX_IMAGE_SIZE) {
      //       const base64url = await convertToBase64String(file)
      //       const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
      //       post.link.isUploadingLinkImage = true
      //       uploadMediaToServerByStringData({ base64url, uploadingId, uploadingTo: 'linkUrlImage' })
      //     } else {
      //       sizeError = true
      //     }
      //   } else {
      //     extensionError = true
      //   }
      //
      //   if (extensionError) {
      //     Alert.error(`Only ${IMAGE_EXTENSIONS.join(', ')} files allowed!`)
      //   } else if (sizeError) {
      //     Alert.error(`The size of the image must be less than ${MAX_IMAGE_SIZE_TEXT}!`)
      //   } else {
      //     setPost({ ...post })
      //     this must be rewritten to dispatch
      //      on1ChangeUpdatePost({ post })
      //   }
      // }
    }

    const getLinkUrlInformation = async ({ url }) => {
      if (!linkUrlStates.isGettingSiteInformation) {
        let { postComponent } = post

        try {
          const res = url ? url.trim().match(URL_REGEXP) : false

          const isLinkUrlCorrect = !!res

          setLinkUrlStates({
            ...linkUrlStates,
            ...{
              isGettingSiteInformation: isLinkUrlCorrect,
              isLinkUrlParsed: false,
              hasLinkUrlInformation: false,
              isLinkUrlCorrect,
            },
          })

          if (isLinkUrlCorrect) {
            const response = await request({
              method: 'POST',
              path: `${ROUTE_URL}?url=${url}`,
            })

            if (response) {
              if (!postComponent) {
                postComponent = POST_LINK
              }

              const {
                error,
                parsed,
                title,
                description,
                id,
                utmTrackingId,
                tracking,
                collected_media,
                ...media
              } = response
              linkUrlStates.isLinkUrlParsed = true
              if (error) {
                linkUrlStates.hasLinkUrlInformation = false
              } else {
                if (!parsed) {
                  Alert.error('Unable to generate link preview but you can still schedule this post.', {
                    timeout: 5000,
                  })
                }

                linkUrlStates.hasLinkUrlInformation = parsed
                linkUrlStates.isLinkUrlCorrect = true

                const { shorten, utm_tracking } = post.link || {}

                let { type: shortenType } = shorten || {}

                if (!shortenType) {
                  if (featuresEnabled[FEATURE_LINK_SHORTENER].enabled) {
                    shortenType = 'DEFAULT'
                  } else {
                    shortenType = 'NONE'
                  }
                }

                onChangeDispatchPost({
                  type: 'link',
                  data: {
                    link: {
                      url,
                      title,
                      description,
                      picture_url,
                      id,
                      needsUpload: false,
                      utmTrackingId,
                      tracking: { ...(post.link.tracking || {}), ...tracking },
                      save_utm_tracking: !utmTrackingId,
                      utm_tracking: utm_tracking !== undefined && utmTrackingId ? utm_tracking : !!utmTrackingId,
                      shorten: { type: shortenType },
                      active: true,
                      present: true,
                      ...media,
                    },
                  },
                })

                if (media.picture_url && collected_media && postComponent === POST_LINK) {
                  onChangeDispatchPost({
                    type: 'rewrite_medias',
                    data: {
                      medias: [collected_media],
                      post_media_key: 'postImages',
                    },
                  })
                }

                onChangeDispatchPost({ type: 'post_component', data: { postComponent } })
              }
              setLinkUrlStates({ ...linkUrlStates })
              handleSpecialRerender()
            }
          }
        } catch (error) {
          linkUrlStates.isGettingSiteInformation = false
          setLinkUrlStates({ ...linkUrlStates })
          handleSpecialRerender()
          errorHelper({
            error,
            componentName: UploadMediaComponent.displayName,
            functionName: 'getLinkUrlInformation',
          })
        }
      }
    }

    const handleUpdateDynamicMedia = ({ media }) => {
      let postAttachmentType
      if (postComponent === POST_IMAGE) {
        postAttachmentType = 'postImages'
      } else if (postComponent === POST_VIDEO) {
        postAttachmentType = 'postVideos'
      } else if (postComponent === POST_MIXED_MEDIA) {
        postAttachmentType = 'postMixedMedias'
      }

      const foundIndex = post[postAttachmentType]
        ? post[postAttachmentType].findIndex((postMedia) => postMedia.media_gid === media.media_gid)
        : -1

      if (foundIndex > -1) {
        onChangeDispatchPost({
          type: 'update_existing_media',
          data: { post_media_key: postAttachmentType, media },
        })
      } else {
        onChangeDispatchPost({ type: 'add_media', data: { post_media_key: postAttachmentType, medias: [media] } })
      }

      handleClickCloseMediaDynamicModal()
    }

    const checkIfMediaCanBeAttached = ({ func, checkFeature = true, feature = FEATURE_FIND_CONTENT }) => {
      let featureFindContentEnabled = true

      if (checkFeature && !featuresEnabled[feature].enabled) {
        featureFindContentEnabled = false
      }

      if (featureFindContentEnabled) {
        let data = []
        let MAX_ATTACHMENTS_ALLOWED = 0
        let type

        if (post.postComponent === POST_IMAGE) {
          data = post.postImages
          MAX_ATTACHMENTS_ALLOWED = MAX_IMAGES_ATTACHMENTS_ALLOWED
          type = IMAGE
        } else if (post.postComponent === POST_VIDEO) {
          data = post.postVideos
          MAX_ATTACHMENTS_ALLOWED = MAX_VIDEOS_ATTACHMENTS_ALLOWED
          type = VIDEO
        } else if (post.postComponent === POST_DOCUMENT) {
          data = post.postDocuments
          MAX_ATTACHMENTS_ALLOWED = MAX_DOCUMENTS_ATTACHMENTS_ALLOWED
          type = DOCUMENT
        } else if (post.postComponent === POST_MIXED_MEDIA) {
          data = post.postMixedMedias
          MAX_ATTACHMENTS_ALLOWED = MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED
          type = 'media'
        }

        if (!data || data.length < MAX_ATTACHMENTS_ALLOWED) {
          const foundUploadingMedia = data ? data.find(({ isNew }) => isNew) : null
          if (foundUploadingMedia) {
            Alert.warning(`Your ${type} is still uploading. Please wait just a bit longer before continuing.`, {
              timeout: 5000,
            })
          } else {
            func()
          }
        } else {
          Alert.warning(`You have reached the limit of ${MAX_ATTACHMENTS_ALLOWED} attached ${type}s!`)
        }
      } else {
        Alert.error(featuresEnabled[feature].description, { timeout: 5000 })
      }
    }

    const checkIfMediaIsStillUploading = ({ blockedKeyForChecking = '' }) => {
      const keys = ['postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

      let isMediaStillUploading = false

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== blockedKeyForChecking) {
          isMediaStillUploading = post[keys[i]] ? post[keys[i]].find(({ tempId }) => tempId) : false
          if (isMediaStillUploading) {
            break
          }
        }
      }

      if (isMediaStillUploading) {
        Alert.warning(`Your media is still uploading. Please wait just a bit longer before continuing.`, {
          timeout: 5000,
        })
      }

      return isMediaStillUploading
    }

    let blockedAddMediaOptions = {}
    let blockedAddMediaCanvaOptions = {}
    let blockedAddMediaDriversOptions = {}

    if (!featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
      blockedAddMediaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }
    if (!featuresEnabled[FEATURE_CANVA].enabled) {
      blockedAddMediaCanvaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }
    if (!featuresEnabled[FEATURE_DRIVES].enabled) {
      blockedAddMediaDriversOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }

    let postMedias = []

    if (postComponent === POST_IMAGE) {
      postMedias = postImages
    } else if (postComponent === POST_VIDEO) {
      postMedias = postVideos
    } else if (postComponent === POST_DOCUMENT) {
      postMedias = postDocuments
    } else if (postComponent === POST_MIXED_MEDIA) {
      postMedias = postMixedMedias
    }

    const ADD_MEDIA_DROPDOWN_VALUES = [
      {
        key: IMAGE,
        title: 'Add image',
        icon: <Icon.Camera width="14px" fill="icon_color_gray" height="100%" />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill="icon_color_gray" height="12px" />,
        children: [
          {
            key: 'computer',
            label: 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  inputMixedMediaFileRef.current.click()
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaUploadByLinkModal({ postComponent: POST_IMAGE })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'library',
            label: 'Choose from Library',
            iconName: 'VistaSocialPicture',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaLibraryModal({ postComponent: POST_IMAGE })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },

          {
            key: 'discover',
            label: 'Discover images',
            iconName: 'Search',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaDiscoverModal({ postComponent: POST_IMAGE })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickGoogleDrive({ postComponent: POST_IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickDropBox({ postComponent: POST_IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOneDrive({ postComponent: POST_IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },

          {
            key: 'canva',
            label: 'Canva',
            iconName: 'Canva',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  setCanvaDesignOptions({ ...{ isOpen: true, type: IMAGE } })
                },
                feature: FEATURE_CANVA,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaCanvaOptions,
          },

          { type: 'divider' },

          {
            key: 'dynamic',
            label: 'Dynamic image',
            iconName: 'VistaSocialPicture',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaDynamicModal({ postComponent: POST_IMAGE })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },
        ],
      },
      {
        key: VIDEO,
        title: 'Add video',
        icon: <Icon.VideoCamera width="14px" fill="icon_color_gray" height="100%" />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill="icon_color_gray" height="12px" />,
        children: [
          {
            key: 'computer',
            label: 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  inputMixedMediaFileRef.current.click()
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaUploadByLinkModal({ postComponent: POST_VIDEO })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'library',
            label: 'Choose from Library',
            iconName: 'VistaSocialPicture',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaLibraryModal({ postComponent: POST_VIDEO })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },

          {
            key: 'discover',
            label: 'Discover videos',
            iconName: 'Search',
            iconWidth: '14px',
            iconColor: 'icon_color_gray',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaDiscoverModal({ postComponent: POST_VIDEO })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickGoogleDrive({ postComponent: POST_VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickDropBox({ postComponent: POST_VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOneDrive({ postComponent: POST_VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },

          // {
          //   key: 'canva',
          //   label: 'Canva',
          //   iconName: 'Canva',
          //   iconWidth: '14px',
          //   onClick: () => {
          //     checkIfMediaCanBeAttached({
          //       func: () => {
          //         setCanvaDesignOptions({ ...{ isOpen: true, type: VIDEO } })
          //       },
          //       feature: FEATURE_CANVA,
          //     })
          //   },
          //   isCursorPointer: true,
          //   ...blockedAddMediaCanvaOptions,
          // },

          { type: 'divider' },

          {
            key: 'dynamic',
            label: 'Dynamic video',
            iconName: 'VistaSocialPicture',
            iconWidth: '14px',
            onClick: () => {
              checkIfMediaCanBeAttached({
                func: () => {
                  handleClickOpenMediaDynamicModal({ postComponent: POST_VIDEO })
                },
                checkFeature: false,
              })
            },
            isCursorPointer: true,
          },
        ],
      },
    ]

    let dropdownProps = {}

    if (menuPlacement === 'top') {
      dropdownProps = { top: 'unset', bottom: '40px' }
    }

    return (
      <Flex>
        <Flex alignItems="center" flexWrap="wrap">
          {showLinkComponent && (
            <IconWrapper
              onClick={() => {
                if (!checkIfMediaIsStillUploading({ blockedKeyForChecking: '' })) {
                  handleClickSelectPostComponent(POST_LINK)
                }
              }}
              isActive={postComponent === POST_LINK}
            >
              <Icon.Link width="18px" height="18px" />
            </IconWrapper>
          )}

          {showVideoComponent && (
            <ShowComponentDropdownMenu
              boxId="idea-modal-video-dropdown"
              isActive={postComponent === POST_VIDEO}
              iconName="VideoCamera"
              handleClickToggleMenu={() => {
                if (!checkIfMediaIsStillUploading({ blockedKeyForChecking: 'postVideos' })) {
                  handleClickSelectPostComponent(POST_VIDEO)
                }
              }}
              {...dropdownProps}
              tooltipRef={tooltipRef}
              // tooltipMessage="Attach one or more video to publish videos and video-only carousel posts."
            >
              <DropdownMenu.Item
                label="Upload from computer"
                iconName="Upload"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      inputVideoFileRef.current.click()
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              <DropdownMenu.Item
                label="Upload from URL"
                iconName="Link"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaUploadByLinkModal({ postComponent: POST_VIDEO })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Choose from Library"
                iconName="VistaSocialPicture"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaLibraryModal({ postComponent: POST_VIDEO })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />

              <DropdownMenu.Item
                label="Discover"
                iconName="Search"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaDiscoverModal({ postComponent: POST_VIDEO })
                    },
                  })
                }}
                isCursorPointer
                {...blockedAddMediaOptions}
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Google drive"
                iconName="GoogleDrive"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickGoogleDrive({ postComponent: POST_VIDEO })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="Dropbox"
                iconName="Dropbox"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickDropBox({ postComponent: POST_VIDEO })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="OneDrive"
                iconName="OneDrive"
                iconWidth="18px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOneDrive({ postComponent: POST_VIDEO })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />

              <DropdownMenu.Item
                label="Canva"
                iconName="Canva"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      setCanvaDesignOptions({ ...{ isOpen: true, type: VIDEO } })
                    },
                    feature: FEATURE_CANVA,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaCanvaOptions}
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Dynamic video"
                iconName="VistaSocialPicture"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaDynamicModal({ postComponent: POST_VIDEO })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              {/* <DropdownMenu.Item
                      label="Design with Vista Create"
                      iconName="Canva"
                      iconWidth="14px"
                      iconColor="icon_color_gray"
                      onClick={() => {
                        checkIfMediaCanBeAttached({
                          func: () => {
                            setVistaCreateDesignOptions({ isOpen: true })
                          },
                        })
                      }}
                      isCursorPointer
                      {...blockedAddMediaOptions}
                    /> */}
            </ShowComponentDropdownMenu>
          )}

          {showImageComponent && (
            <ShowComponentDropdownMenu
              boxId="idea-modal-image-dropdown"
              isActive={postComponent === POST_IMAGE}
              iconName="Camera"
              handleClickToggleMenu={() => {
                if (!checkIfMediaIsStillUploading({ blockedKeyForChecking: 'postImages' })) {
                  handleClickSelectPostComponent(POST_IMAGE)
                }
              }}
              {...dropdownProps}
              tooltipRef={tooltipRef}
              // tooltipMessage="Attach one or more images to publish image and image-only carousel posts."
            >
              <DropdownMenu.Item
                label="Upload from computer"
                iconName="Upload"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      inputImageFileRef.current.click()
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              <DropdownMenu.Item
                label="Upload from URL"
                iconName="Link"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaUploadByLinkModal({ postComponent: POST_IMAGE })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Choose from Library"
                iconName="VistaSocialPicture"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaLibraryModal({ postComponent: POST_IMAGE })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              <DropdownMenu.Item
                label="Discover"
                iconName="Search"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaDiscoverModal({ postComponent: POST_IMAGE })
                    },
                  })
                }}
                isCursorPointer
                {...blockedAddMediaOptions}
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Google drive"
                iconName="GoogleDrive"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickGoogleDrive({ postComponent: POST_IMAGE })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="Dropbox"
                iconName="Dropbox"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickDropBox({ postComponent: POST_IMAGE })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="OneDrive"
                iconName="OneDrive"
                iconWidth="18px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOneDrive({ postComponent: POST_IMAGE })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />

              <DropdownMenu.Item
                label="Canva"
                iconName="Canva"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      setCanvaDesignOptions({ ...{ isOpen: true, type: IMAGE } })
                    },
                    feature: FEATURE_CANVA,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaCanvaOptions}
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Dynamic image"
                iconName="VistaSocialPicture"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaDynamicModal({ postComponent: POST_IMAGE })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
            </ShowComponentDropdownMenu>
          )}

          {showDocumentComponent && (
            <ShowComponentDropdownMenu
              boxId="idea-modal-document-dropdown"
              isActive={postComponent === POST_DOCUMENT}
              iconName="File"
              iconHeight="16px"
              handleClickToggleMenu={() => {
                if (!checkIfMediaIsStillUploading({ blockedKeyForChecking: 'postDocuments' })) {
                  handleClickSelectPostComponent(POST_DOCUMENT)
                }
              }}
              {...dropdownProps}
              tooltipRef={tooltipRef}
              tooltipMessage="Attach one or more documents (pdf, ppt, pptx, doc, docx). Only supported by LinkedIn."
            >
              <DropdownMenu.Item
                label="Upload from computer"
                iconName="Upload"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      inputDocumentFileRef.current.click()
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              <DropdownMenu.Item
                label="Upload from URL"
                iconName="Link"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaUploadByLinkModal({ postComponent: POST_DOCUMENT })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />

              <Divider height="1px" />

              <DropdownMenu.Item
                label="Choose from Library"
                iconName="VistaSocialPicture"
                iconWidth="14px"
                iconColor="icon_color_gray"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOpenMediaLibraryModal({ postComponent: POST_DOCUMENT })
                    },
                    checkFeature: false,
                  })
                }}
                isCursorPointer
              />
              <Divider height="1px" />

              <DropdownMenu.Item
                label="Google drive"
                iconName="GoogleDrive"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickGoogleDrive({ postComponent: POST_DOCUMENT })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="Dropbox"
                iconName="Dropbox"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickDropBox({ postComponent: POST_DOCUMENT })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />
              <DropdownMenu.Item
                label="OneDrive"
                iconName="OneDrive"
                iconWidth="18px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      handleClickOneDrive({ postComponent: POST_DOCUMENT })
                    },
                    feature: FEATURE_DRIVES,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaDriversOptions}
              />

              <DropdownMenu.Item
                label="Canva"
                iconName="Canva"
                iconWidth="14px"
                onClick={() => {
                  checkIfMediaCanBeAttached({
                    func: () => {
                      setCanvaDesignOptions({ ...{ isOpen: true, type: DOCUMENT } })
                    },
                    feature: FEATURE_CANVA,
                  })
                }}
                isCursorPointer
                {...blockedAddMediaCanvaOptions}
              />
            </ShowComponentDropdownMenu>
          )}

          {showMixedMediaComponent && (
            <ShowComponentDropdownMenu
              boxId="idea-modal-mixed-dropdown"
              isActive={postComponent === POST_MIXED_MEDIA}
              iconName="MixedMedia"
              handleClickToggleMenu={() => {
                if (!checkIfMediaIsStillUploading({ blockedKeyForChecking: 'postMixedMedias' })) {
                  handleClickSelectPostComponent(POST_MIXED_MEDIA)
                }
              }}
              {...dropdownProps}
              tooltipRef={tooltipRef}
              tooltipMessage={
                postComponent === POST_MIXED_MEDIA
                  ? ''
                  : 'Mixed media. Please use this when posting mixed attachments: images and videos.'
              }
            >
              {ADD_MEDIA_DROPDOWN_VALUES.map(({ key, title, icon, iconEnding, children }, index) => {
                const id = `composer${network ? `-${network}` : ''}-add-media-${key}-dropdown`

                return (
                  <Flex
                    key={key}
                    onMouseEnter={() => {
                      const keys = [IMAGE, VIDEO]
                      keys.forEach((item) => {
                        clearInterval(DROPDOWN_INTERVALS[item])
                        const el = document.getElementById(
                          `composer${network ? `-${network}` : ''}-add-media-${item}-dropdown`
                        )
                        if (el) {
                          el.style.display = item === key ? 'flex' : 'none'

                          if (item === key) {
                            const { left } = el.getBoundingClientRect()

                            if (left < 210) {
                              el.style.left = 'unset'
                              el.style.right = '-211px'
                            } else {
                              el.style.right = 'unset'
                              el.style.left = '-211px'
                            }
                          }
                        }
                      })
                    }}
                    onMouseLeave={() => {
                      const el = document.getElementById(id)
                      if (el) {
                        DROPDOWN_INTERVALS[key] = setInterval(() => {
                          el.style.display = 'none'
                          clearInterval(DROPDOWN_INTERVALS[key])
                        }, 300)
                      }
                    }}
                  >
                    <StyledDropDownMenuItem type="button">
                      <Flex alignItems="center" width="100%" height="100%" justifyContent="space-between">
                        <Flex alignItems="center" width="100%" height="100%">
                          <Flex alignItems="center" justifyContent="center" width="20px" height="100%" mr="xs">
                            {icon}
                          </Flex>
                          <Flex flexDirection="column">
                            <Text fontSize="xs" maxWidth="150px">
                              {title}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" justifyContent="center" width="20px" height="100%" mr="xs">
                          {iconEnding}
                        </Flex>
                      </Flex>
                    </StyledDropDownMenuItem>
                    <StyledDropDownSubmenuWrapper
                      onMouseEnter={() => {
                        const el = document.getElementById(id)
                        if (el) {
                          el.style.display = 'flex'
                          clearInterval(DROPDOWN_INTERVALS[key])
                        }
                      }}
                      id={id}
                      top={menuPlacement === 'top' ? 'unset' : `${index * 30}px`}
                      bottom={menuPlacement === 'top' ? 0 : 'unset'}
                      showAddMediaDropdownPosition="left"
                    >
                      {children.map(({ key, type, ...props }, index) => {
                        if (type === 'divider') {
                          return <Divider height="1px" key={index} />
                        }
                        return <DropdownMenu.Item key={key} {...props} />
                      })}
                    </StyledDropDownSubmenuWrapper>
                  </Flex>
                )
              })}
            </ShowComponentDropdownMenu>
          )}
        </Flex>

        {showImageComponent && (
          <input
            hidden
            type="file"
            multiple
            ref={inputImageFileRef}
            onChange={(event) => handlePostMediaFileChange({ event, key: 'postImages' })}
            accept={IMAGE_EXTENSIONS.join(',')}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        )}

        {/* <input */}
        {/*  hidden */}
        {/*  type="file" */}
        {/*  accept={IMAGE_EXTENSIONS.join(',')} */}
        {/*  onChange={(e) => handleLinkUrlImageFileChange(e)} */}
        {/*  id="linkUrlPicture" */}
        {/*  onClick={(event) => { */}
        {/*    event.target.value = null */}
        {/*  }} */}
        {/* /> */}

        {showVideoComponent && (
          <input
            hidden
            type="file"
            ref={inputVideoFileRef}
            onChange={(event) => handlePostMediaFileChange({ event, key: 'postVideos' })}
            accept={VIDEO_EXTENSIONS.join(',')}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        )}

        {showDocumentComponent && (
          <input
            hidden
            type="file"
            multiple
            ref={inputDocumentFileRef}
            onChange={(event) => handlePostMediaFileChange({ event, key: 'postDocuments' })}
            accept={DOCUMENT_EXTENSIONS.join(',')}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        )}

        {showMixedMediaComponent && (
          <input
            hidden
            type="file"
            multiple
            ref={inputMixedMediaFileRef}
            onChange={(event) => handlePostMediaFileChange({ event, key: 'postMixedMedias' })}
            accept={[...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS].join(',')}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        )}

        {mediaLibraryOptions.isOpen && (
          <MediaLibraryModal
            user={user}
            handleDismiss={handleClickCloseMediaLibraryModal}
            isOpen={mediaLibraryOptions.isOpen}
            type={mediaLibraryOptions.postComponent}
            handleClickAddMedias={(data) => {
              data.postComponent = post.postComponent
              handleClickAddMedias(data)
            }}
            postMedias={postMedias}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
            maxImageAttachmentsAllowed={maxImageAttachmentsAllowed}
            maxVideoAttachmentsAllowed={maxVideoAttachmentsAllowed}
            maxMixedMediaAttachmentsAllowed={maxMixedMediaAttachmentsAllowed}
          />
        )}

        {mediaDiscoverOptions.isOpen && (
          <MediaDiscoverModal
            handleDismiss={handleClickCloseMediaDiscoverModal}
            isOpen={mediaDiscoverOptions.isOpen}
            type={mediaDiscoverOptions.postComponent}
            handleClickAddMedias={(data) => {
              data.postComponent = post.postComponent
              handleClickAddMedias(data)
            }}
            postMedias={postMedias}
            maxImageAttachmentsAllowed={maxImageAttachmentsAllowed}
            maxVideoAttachmentsAllowed={maxVideoAttachmentsAllowed}
          />
        )}

        {mediaUploadByLinkOptions.isOpen && (
          <MediaUploadByLinkModal
            handleDismiss={handleClickCloseMediaUploadByLinkModal}
            isOpen={mediaUploadByLinkOptions.isOpen}
            type={mediaUploadByLinkOptions.postComponent}
            handleClickAddMedias={(data) => {
              data.postComponent = post.postComponent
              handleClickAddMedias(data)
            }}
            postMedias={postMedias}
            hasError={mediaUploadByLinkOptions.hasError}
          />
        )}

        {canvaDesignOptions.isOpen && (
          <CanvaDesignModal
            user={user}
            handleDismiss={handleClickCloseCanvaModal}
            isOpen={canvaDesignOptions.isOpen}
            handleClickAddMedias={handleAddDesignMedias}
            type={canvaDesignOptions.type}
          />
        )}

        {/* {vistaCreateDesignOptions.isOpen && ( */}
        {/*   <VistaCreateDesignModal */}
        {/*     handleDismiss={handleClickCloseVistaCreateModal} */}
        {/*     isOpen={vistaCreateDesignOptions.isOpen} */}
        {/*     handleClickAddMedias={handleAddDesignMedias} */}
        {/*   /> */}
        {/* )} */}

        {mediaSettingsOptions.isOpen && (
          <MediaSettings
            user={user}
            uploadedMedias={mediaSettingsOptions.uploadedMedias}
            isOpen={mediaSettingsOptions.isOpen}
            handleDismiss={handleClickCloseMediaSettingsModal}
            handleUpdateUploadedMedias={handleUpdateUploadedMedias}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
          />
        )}

        {dynamicMediaOptions.isOpen && (
          <DynamicMediaModal
            user={user}
            isOpen={dynamicMediaOptions.isOpen}
            handleDismiss={handleClickCloseMediaDynamicModal}
            media={dynamicMediaOptions.media}
            post={{ ...post, postComponent: dynamicMediaOptions.postComponent }}
            handleUpdateDynamicMedia={handleUpdateDynamicMedia}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
          />
        )}

        <CalendarItemTooltip ref={tooltipRef} />
      </Flex>
    )
  }
)

UploadMediaComponent.defaultProps = {
  post: { customizations: {} },
  network: '',
  profileId: null,
  selectedProfiles: [],
  selectedEntities: [],
  uploadingMediasAmount: 0,
  setUploadingMediasAmount: () => {},
  showLinkComponent: true,
  showImageComponent: true,
  showVideoComponent: true,
  showDocumentComponent: true,
  showMixedMediaComponent: true,
  handleSpecialRerender: () => {},
  specialPostKey: '',
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  maxVideoAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  maxMixedMediaAttachmentsAllowed: MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
  handleUpdateLinkInput: () => {},
  menuPlacement: 'bottom',
}

UploadMediaComponent.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  network: PropTypes.string,
  profileId: PropTypes.number,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  uploadingMediasAmount: PropTypes.number,
  setUploadingMediasAmount: PropTypes.func,
  showLinkComponent: PropTypes.bool,
  showImageComponent: PropTypes.bool,
  showVideoComponent: PropTypes.bool,
  showDocumentComponent: PropTypes.bool,
  showMixedMediaComponent: PropTypes.bool,
  handleSpecialRerender: PropTypes.func,
  specialPostKey: PropTypes.string,
  maxImageAttachmentsAllowed: PropTypes.number,
  maxVideoAttachmentsAllowed: PropTypes.number,
  maxMixedMediaAttachmentsAllowed: PropTypes.number,
  handleUpdateLinkInput: PropTypes.func,
  menuPlacement: PropTypes.string,
}

UploadMediaComponent.displayName = 'UploadMediaComponent'

export default UploadMediaComponent
