import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import { generateWriter } from '../helpers'

const RunningText = ({ text, onFinish, isNew, direction }) => {
  const [writer, setWriter] = useState([])

  const callGenerateWriter = async () => {
    await generateWriter({ text, isNew, setWriter })
    onFinish()
  }

  useEffect(() => {
    if (text) {
      callGenerateWriter()
    }
  }, [text])

  return (
    <Flex width="100%">
      <Text
        dangerouslySetInnerHTML={{
          __html: writer.join(''),
        }}
        dir={direction}
        width="100%"
      />
    </Flex>
  )
}

RunningText.defaultProps = {
  isNew: false,
}

RunningText.propTypes = {
  text: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  direction: PropTypes.string.isRequired,
}

export default RunningText
