import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Icon from 'components/atoms/Icon'

const StyledImageUploadWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  padding: ${space.s} ${space.m};
  width: 100%;
`

const StyledImageWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  width: 48px;
  height: 48px;
`

const StyledImage = styled(ImageWithFallback)`
  cursor: pointer;
  object-fit: contain;
  border-radius: ${radius.l};
  width: 48px;
  height: 48px;
`

const StyledGrayButton = styled(Flex)`
  padding: ${space.s};
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
`

const ProfileImageUploadComponent = ({ pictureFile }) => {
  return (
    <StyledImageUploadWrapper>
      <StyledImageWrapper>
        {pictureFile ? (
          <StyledImage source={pictureFile} fallbackSource="/assets/avatar.svg" />
        ) : (
          <Icon.VistaSocialAvatar stroke="icon_color_gray" width="24px" height="24px" />
        )}
      </StyledImageWrapper>

      <Flex alignItems="center">
        <StyledGrayButton ml="s">
          <Icon.Image fill="icon_color_gray" width="14px" height="14px" />
          <Text ml="s" color="secondaryText">
            Upload image
          </Text>
        </StyledGrayButton>
      </Flex>
    </StyledImageUploadWrapper>
  )
}

ProfileImageUploadComponent.defaultProps = {
  pictureFile: null,
}

ProfileImageUploadComponent.propTypes = {
  pictureFile: PropTypes.string,
}

ProfileImageUploadComponent.displayName = 'ProfileImageUploadComponent'

export default ProfileImageUploadComponent
