import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactDOMServer from 'react-dom/server'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { pxToRem } from 'helpers'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { default as ImageComponent } from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import { COLORS_PALETTE } from 'routes/Calendar/consts'
import { SOCIALS, OTHER } from './consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 500px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
    min-height: 400px;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const StyledIconsBodyWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: ${space.m};
`

const StyledIconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${radius.l};
  width: 24px;
  height: 24px;

  ${({ $isSelected, theme }) => $isSelected && `box-shadow: 0 0 5px 0 ${transparentize(0.5, theme.colors.box_shadow)};`}

  &:hover {
    box-shadow: 0 0 5px 0 ${({ theme }) => transparentize(0.5, theme.colors.box_shadow)};
  }

  ${({ $svgFill }) =>
    $svgFill &&
    `svg {
      fill: ${$svgFill};
      }
    `}
`

const StyledInputColor = styled.input`
  width: 100%;
  padding: ${space.xs} ${space.m};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  background: transparent;
  border-radius: ${radius.l};
  height: ${pxToRem(40)};
  cursor: pointer;
`

const StyledColorPaletteItemWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  margin-right: 14px;
  margin-bottom: 14px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
  &:hover {
    transform: scale(1.2);
  }
`

const StyledColorPaletteItem = styled(Box)`
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: ${({ boxColor }) => boxColor} 0px 0px 0px ${({ selected }) => (selected ? '3px' : '14px')} inset;
  transition: box-shadow 100ms ease 0s;
  border: 1px solid ${({ hasBorder }) => (hasBorder ? COLOR_CONSTANTS.SOLITUDE : 'transparent')};
`

const { TAB_TYPE_PLATFORMS, TAB_TYPE_CUSTOM } = { TAB_TYPE_PLATFORMS: 'socials', TAB_TYPE_CUSTOM: 'custom' }

const TABS = [
  { name: 'Platforms', type: TAB_TYPE_PLATFORMS, path: '/assets/vista_page/networks' },
  { name: 'Custom', type: TAB_TYPE_CUSTOM, path: '/assets/vistasocial/custom_network_icons' },
]

const SvgComponent = ({ svg }) => svg

const ChooseNetworkIconModal = ({ isOpen, handleClickCloseModal, handleSaveNetworkIcon }) => {
  const [activeTab, setActiveTab] = useState(TABS[0])
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [inputColor, setInputColor] = useState(COLOR_CONSTANTS.BLACK)

  const toDataURL = (src, callback, outputFormat) => {
    const img = new Image()

    img.crossOrigin = 'Anonymous'

    img.onload = function() {
      const canvas = document.createElement('CANVAS')
      const ctx = canvas.getContext('2d')

      canvas.height = this.naturalHeight
      canvas.width = this.naturalWidth
      ctx.drawImage(this, 0, 0)
      const dataURL = canvas.toDataURL(outputFormat)
      callback(dataURL)
    }

    img.src = src
    if (img.complete || img.complete === undefined) {
      img.src = src
    }
  }

  const encodeSvg = (reactElement) => {
    return `data:image/svg+xml,${encodeURIComponent(ReactDOMServer.renderToStaticMarkup(reactElement))}`
  }

  const handleClickSaveNetworkIcon = () => {
    if (!selectedIcon) {
      Alert.error('Please select an icon.', { timeout: 5000 })
    } else {
      if (selectedIcon.type === TAB_TYPE_CUSTOM) {
        const foundIcon = OTHER.find(({ network }) => network === selectedIcon.network)

        const el = React.cloneElement(foundIcon.svg, { fill: inputColor })

        const base64 = encodeSvg(el)

        toDataURL(base64, (dataUrl) => {
          handleSaveNetworkIcon({ base64: dataUrl })
        })
      } else if (selectedIcon.type === TAB_TYPE_PLATFORMS) {
        const { path, network } = selectedIcon

        toDataURL(`${path}/${network}.svg`, (dataUrl) => {
          handleSaveNetworkIcon({ base64: dataUrl })
        })
      }
    }
  }

  const handleDismissModal = () => {
    handleClickCloseModal()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Network icons</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1">
            <Flex width="100%" height="100%" flexDirection="column" position="relative" px="m">
              <Flex mt="m" justifyContent="center" height="30px" alignItems="center">
                {TABS.map((item) => {
                  const { name, type } = item
                  const { type: activeTabType } = activeTab || {}
                  return (
                    <Tab
                      key={name}
                      onClick={() => {
                        setActiveTab(item)
                      }}
                      isActive={activeTabType && type === activeTabType}
                    >
                      <Text color={activeTabType && type === activeTabType ? 'primary' : 'secondaryText'} fontSize="s">
                        {name}
                      </Text>
                    </Tab>
                  )
                })}
              </Flex>

              <Flex flexDirection="column" mt="m">
                {activeTab.type === TAB_TYPE_PLATFORMS && (
                  <StyledIconsBodyWrapper>
                    {SOCIALS.map(({ name, network }) => {
                      const isSelected = selectedIcon
                        ? selectedIcon.type === activeTab.type && selectedIcon.network === network
                        : false

                      return (
                        <StyledIconWrapper
                          key={name}
                          onClick={() => {
                            if (isSelected) {
                              setSelectedIcon(null)
                            } else {
                              setSelectedIcon({ type: activeTab.type, network, path: activeTab.path })
                            }
                          }}
                          $isSelected={isSelected}
                        >
                          <ImageComponent src={`${activeTab.path}/${network}.svg`} width="24px" />
                        </StyledIconWrapper>
                      )
                    })}
                  </StyledIconsBodyWrapper>
                )}

                {activeTab.type === TAB_TYPE_CUSTOM && (
                  <Flex flexDirection="column">
                    <StyledIconsBodyWrapper>
                      {OTHER.map(({ name, network, svg }) => {
                        const isSelected = selectedIcon
                          ? selectedIcon.type === activeTab.type && selectedIcon.network === network
                          : false

                        return (
                          <StyledIconWrapper
                            key={name}
                            onClick={() => {
                              if (isSelected) {
                                setSelectedIcon(null)
                              } else {
                                setSelectedIcon({ type: activeTab.type, network, path: activeTab.path })
                              }
                            }}
                            $isSelected={isSelected}
                            $svgFill={inputColor}
                          >
                            <SvgComponent svg={svg} />
                          </StyledIconWrapper>
                        )
                      })}
                    </StyledIconsBodyWrapper>

                    <Flex mt="m" flexDirection="column">
                      <Flex flexDirection="column" width="100%">
                        <StyledInputColor
                          type="color"
                          value={inputColor}
                          onChange={(event) => {
                            const { value } = event.target

                            if (value === '#ffffff') {
                              Alert.error('Please select not white color.', { timeout: 5000 })
                            } else {
                              setInputColor(event.target.value)
                            }
                          }}
                        />
                        <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(27px, max-content))">
                          {COLORS_PALETTE.map(({ color, hasBorder }) => {
                            if (hasBorder) {
                              return null
                            }

                            return (
                              <StyledColorPaletteItemWrapper key={color}>
                                <span>
                                  <StyledColorPaletteItem
                                    boxColor={color}
                                    hasBorder={hasBorder}
                                    selected={color === inputColor}
                                    onClick={() => {
                                      setInputColor(color)
                                    }}
                                  />
                                </span>
                              </StyledColorPaletteItemWrapper>
                            )
                          })}
                        </Grid>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray onClick={handleDismissModal} isSmall>
              Cancel
            </Button.Gray>

            <Button.Gradient type="submit" isSmall onClick={handleClickSaveNetworkIcon}>
              {selectedIcon ? 'Pick selected' : 'Pick'}
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleDismissModal}>
            <ImageComponent width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ChooseNetworkIconModal.defaultProps = {}

ChooseNetworkIconModal.propTypes = {
  handleClickCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSaveNetworkIcon: PropTypes.func.isRequired,
}

ChooseNetworkIconModal.displayName = 'ChooseNetworkIconModal'

export default ChooseNetworkIconModal
