import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Scrollbars from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import { radius } from 'theme'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import Checkbox from 'components/atoms/Checkbox'
import Badge from 'components/atoms/Badge'
import Divider from 'components/atoms/Divider'
import Icon from 'components/atoms/Icon'
import { STATUS_COLORS, STATUS_FILTERS } from 'routes/Calendar/consts'
// eslint-disable-next-line import/no-cycle
import { formatTimezoneToGMT } from 'routes/Calendar/helpers'
import ProfileItemTooltip from '../../ProfileItemTooltip'
import SocialProfileImage from '../../SocialProfileImage'
import CalendarItemTooltip from '../../CalendarItemTooltip'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    display: flex;
    flex-direction: column;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  outline: none;
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 400px;
`

const TitleWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledArrowWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledTextWrappedName = styled(Text)`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledTextSelector = styled(Text)`
  cursor: pointer;
`

const SendForApprovalProfileGroupsModal = ({
  isOpen,
  handleDismiss,
  selectedEntities: entitiesForSelection,
  callbackFunc,
}) => {
  const tooltipRef = useRef(null)

  const [selectedEntities, setSelectedEntities] = useState([...entitiesForSelection])

  const handleClickSelectAll = () => {
    setSelectedEntities([
      ...selectedEntities.map((item) => {
        item.selected = true
        return item
      }),
    ])
  }

  const handleClickUnSelectAll = () => {
    setSelectedEntities([
      ...selectedEntities.map((item) => {
        item.selected = false
        return item
      }),
    ])
  }

  const handleClickSelectOneProfileGroup = ({ index, selected }) => {
    selectedEntities[index].selected = !selected
    setSelectedEntities([...selectedEntities])
  }

  const handleClickExpandOneProfileGroup = ({ index, isOpen }) => {
    selectedEntities[index].isOpen = !isOpen
    setSelectedEntities([...selectedEntities])
  }

  const handleClickSendForApproval = () => {
    const selectedEntitiesFiltered = selectedEntities.filter(({ selected }) => selected)

    if (selectedEntitiesFiltered.length === 0) {
      Alert.error(`Please select at least one profile group for sending for approval.`, { timeout: 5000 })
    } else {
      callbackFunc({ selectedEntities: selectedEntitiesFiltered })
    }
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" tabIndex={0} $isTop>
            <H4 my="m">Send for Approval</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flexDirection="column">
            <Scrollbars universal>
              <Flex flexDirection="column" p="m" width="100%" height="100%">
                <Text>Please select which posts you would like to submit for approval</Text>

                <Flex alignItems="center" mt="m">
                  <StyledTextSelector onClick={handleClickSelectAll} fontSize="xs" color="primary">
                    Select all
                  </StyledTextSelector>

                  <Divider width="2px" mx="s" height="10px" />
                  <StyledTextSelector onClick={handleClickUnSelectAll} fontSize="xs" color="primary">
                    Unselect all
                  </StyledTextSelector>
                </Flex>

                <Flex mt="m" flexDirection="column">
                  {selectedEntities.map(
                    (
                      { entityId, entityType, timezone, name, profiles = [], selected = false, isOpen = true },
                      index
                    ) => {
                      return (
                        <Flex key={entityId} flexDirection="column" mb="s">
                          <TitleWrapper alignItems="center" justifyContent="space-between">
                            <Flex alignItems="center">
                              <Flex alignItems="center">
                                <Flex
                                  alignItems="center"
                                  onMouseEnter={(e) => {
                                    tooltipRef.current.handleShowTooltip({
                                      contentComp: (
                                        <Flex justifyContent="center" flexDirection="column" width="300px" p="s">
                                          <Text>
                                            {name} ({entityType.toLowerCase()})
                                          </Text>
                                          <Text>{formatTimezoneToGMT(timezone).long}</Text>
                                        </Flex>
                                      ),
                                      wrapperComp: e.currentTarget,
                                    })
                                  }}
                                  onMouseLeave={() => {
                                    tooltipRef.current.handleHideTooltip()
                                  }}
                                >
                                  <Checkbox
                                    isChecked={selected}
                                    onClick={() => {
                                      handleClickSelectOneProfileGroup({ index, selected })
                                    }}
                                  >
                                    <StyledTextWrappedName ml="m" pl="s" fontWeight="light" fontSize="s" width="160px">
                                      {name}
                                    </StyledTextWrappedName>
                                  </Checkbox>
                                </Flex>
                              </Flex>
                            </Flex>
                            <StyledArrowWrapper
                              width="24px"
                              height="24px"
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => {
                                handleClickExpandOneProfileGroup({ index, isOpen })
                              }}
                            >
                              {isOpen ? <Icon.VistaSocialChevronUp /> : <Icon.VistaSocialChevronDown />}
                            </StyledArrowWrapper>
                          </TitleWrapper>
                          <Flex mt="s" flexDirection="column" display={isOpen ? 'flex' : 'none'}>
                            {profiles.map((profile) => {
                              const {
                                id,
                                code,
                                name,
                                nickname,
                                username,
                                picture_url,
                                status,
                                custom_network_picture_url,
                              } = profile

                              const foundStatus = status ? STATUS_FILTERS.find(({ id }) => id === status) : null

                              return (
                                <Flex mb="s" key={id} alignItems="center" justifyContent="space-between" width="100%">
                                  <Flex alignItems="center">
                                    <Flex alignItems="center">
                                      <Flex
                                        ml="m"
                                        pl="s"
                                        alignItems="center"
                                        onMouseEnter={(e) => {
                                          tooltipRef.current.handleShowTooltip({
                                            contentComp: <ProfileItemTooltip profile={profile} />,
                                            wrapperComp: e.currentTarget,
                                          })
                                        }}
                                        onMouseLeave={() => {
                                          tooltipRef.current.handleHideTooltip()
                                        }}
                                      >
                                        <SocialProfileImage
                                          picture_url={picture_url}
                                          code={code}
                                          custom_network_picture_url={custom_network_picture_url}
                                          width="20px"
                                          height="20px"
                                        />
                                        <StyledTextWrappedName
                                          ml="s"
                                          fontWeight="light"
                                          fontSize="xs"
                                          color="primaryText"
                                        >
                                          {nickname || name || username}
                                        </StyledTextWrappedName>
                                      </Flex>
                                    </Flex>
                                  </Flex>

                                  {foundStatus && (
                                    <Badge.Status color={STATUS_COLORS[status]}>
                                      <Excerpt fontSize="xs" textAlign="center">
                                        {foundStatus.name}
                                      </Excerpt>
                                    </Badge.Status>
                                  )}
                                </Flex>
                              )
                            })}
                          </Flex>
                        </Flex>
                      )
                    }
                  )}
                </Flex>
              </Flex>
            </Scrollbars>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray onClick={handleDismiss} isSmall>
              Continue editing
            </Button.Gray>
            <Flex>
              <Button.Gradient isSmall onClick={handleClickSendForApproval}>
                Send for approval
              </Button.Gradient>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>

          <CalendarItemTooltip ref={tooltipRef} />
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

SendForApprovalProfileGroupsModal.defaultProps = {}

SendForApprovalProfileGroupsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedEntities: PropTypes.array.isRequired,
  callbackFunc: PropTypes.func.isRequired,
}

export default SendForApprovalProfileGroupsModal
