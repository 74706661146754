import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { IMAGE } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 550px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
  outline: none;
`

const StyledTemplateWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  cursor: pointer;

  ${({ $isSelected, theme }) => $isSelected && `border-color: ${theme.colors.primary};`};

  &:hover {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const StyledIconWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background: ${({ $background }) => $background};
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CANVA_TEMPLATES = [
  {
    name: 'FacebookPost',
    label: 'Facebook post',
    icon: '/assets/vistasocial/canva/design_options/facebook_post.svg',
    background_color: '#fee5e6',
    description: '940 x 788 px',
  },
  {
    name: 'InstagramPost',
    label: 'Instagram post',
    link: 'https://www.canva.com/instagram-posts/templates/',
    icon: '/assets/vistasocial/canva/design_options/instagram_post.svg',
    background_color: '#fee5e6',
    description: '1080 x 1350 px',
  },
  {
    name: 'InstagramStory',
    label: 'Instagram story',
    link: 'https://www.canva.com/instagram-stories/templates/',
    icon: '/assets/vistasocial/canva/design_options/instagram_story.svg',
    background_color: '#f9d9ff',
    description: '1080 x 1920 px',
  },
  {
    name: 'PinterestGraphic',
    label: 'Pinterest graphic',
    icon: '/assets/vistasocial/canva/design_options/pinterest_pin.svg',
    background_color: '#fee5e6',
    description: '1000 x 1500 px',
  },
  {
    name: 'Poster',
    label: 'Poster',
    icon: '/assets/vistasocial/canva/design_options/poster.svg',
    background_color: '#ead4ff',
    description: '1588 x 2246 px',
  },
  {
    name: 'SocialMedia',
    label: 'Social media post',
    icon: '/assets/vistasocial/canva/design_options/social_media_post.svg',
    background_color: '#f2f3f5',
    description: '1080 x 1080 px',
  },

  {
    name: 'TwitterPost',
    label: 'Twitter post',
    icon: '/assets/vistasocial/canva/design_options/twitter_post.svg',
    background_color: '#fee5e6',
    description: '1600 x 900 px',
  },

  {
    name: 'YouTubeThumbnail',
    label: 'YouTube thumbnail',
    icon: '/assets/vistasocial/canva/design_options/youtube_thumbnail.svg',
    background_color: '#fee5e6',
    description: '1280 x 720 px',
  },
]

const CanvaCreateFromScratchModal = ({ isOpen, handleDismiss, handleClickAddMedias }) => {
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (isOpen) {
      const foundTemplate = CANVA_TEMPLATES.find(({ name }) => name === 'SocialMedia')

      setTemplate(foundTemplate.name)
    }
  }, [isOpen])

  const designWithCanva = () => {
    handleDismiss()
    window.canva.createDesign({
      design: {
        type: template,
      },
      onDesignPublish: (opts) => {
        handleClickAddMedias({ urls: [{ url: opts.exportUrl, type: IMAGE }] })
      },
    })
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Select Canva design category</H4>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper flex="1" tabIndex="0">
            <Flex flexDirection="column" width="100%" px="m">
              <Grid my="m" gridGap="m" gridTemplateColumns="repeat(2, 1fr)">
                {CANVA_TEMPLATES.map(({ name, label, description, icon, background_color }) => {
                  const isSelected = name === template

                  return (
                    <StyledTemplateWrapper
                      key={name}
                      alignItems="center"
                      $isSelected={isSelected}
                      onClick={() => {
                        setTemplate(name)
                      }}
                      p="s"
                    >
                      <Flex>
                        <StyledIconWrapper $background={background_color}>
                          <Image src={icon} width="32px" height="32px" />
                        </StyledIconWrapper>
                      </Flex>

                      <Flex ml="s" flexDirection="column">
                        <Text>{label}</Text>
                        <Text color="secondaryText" fontSize="xs">
                          {description}
                        </Text>
                      </Flex>

                      {/* <Radio */}
                      {/*   id={name} */}
                      {/*   onChange={({ target: { id } }) => { */}
                      {/*     selectTemplate(id) */}
                      {/*   }} */}
                      {/*   name="canva_design_template" */}
                      {/*   value={name} */}
                      {/*   checked={selected} */}
                      {/* /> */}
                      {/* /!* eslint-disable-next-line jsx-a11y/label-has-for *!/ */}
                      {/* <label htmlFor={name}> */}
                      {/*   <Text ml="m" pl="xs"> */}
                      {/*     {label} */}
                      {/*   </Text> */}
                      {/* </label> */}
                    </StyledTemplateWrapper>
                  )
                })}
              </Grid>
            </Flex>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>
            <Button.Primary onClick={designWithCanva} isSmall>
              <Text>Design with Canva</Text>
            </Button.Primary>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

CanvaCreateFromScratchModal.defaultProps = {}

CanvaCreateFromScratchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickAddMedias: PropTypes.func.isRequired,
}

CanvaCreateFromScratchModal.displayName = 'CanvaCreateFromScratchModal'

export default CanvaCreateFromScratchModal
