import {
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
  GOOGLE,
  REDDIT,
  PINTEREST,
  TIKTOK,
  SNAPCHAT,
  VISTAPAGE,
  TUMBLR,
  THREADS,
  BLUESKY,
  CUSTOM,
} from 'consts'

// TODO:: VIMEO has been removed
export const TABS = [
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
  GOOGLE,
  REDDIT,
  PINTEREST,
  TIKTOK,
  SNAPCHAT,
  VISTAPAGE,
  TUMBLR,
  THREADS,
  BLUESKY,
  CUSTOM,
]
