import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import dynamic from 'next/dynamic'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, fontWeights, radius, space } from 'theme'
import {
  CUSTOM,
  DEFAULT_VIDEO_THUMBNAIL,
  DIRECTION_LEFT_TO_RIGHT,
  DIRECTION_RIGHT_TO_LEFT,
  DOCUMENT,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
} from 'consts'
import { getFileIconByExtension } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import MediaCarousel from 'components/molecules/MediaCarousel'
import DynamicMedia from 'components/molecules/DynamicMedia'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import PublicationOptionComponent from './components/PublicationOptionComponent'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_MIXED_MEDIA,
  POST_VIDEO,
  POST_DOCUMENT,
  PREVIEW_MEDIA_CLASS_NAME,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  CUSTOM_COLORS,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import { variableTransformer } from '../../helpers'
import SocialProfileImage from '../SocialProfileImage'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'

const StyledFlex = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  p,
  span,
  li {
    font-size: 14px;
    font-weight: ${fontWeights.semiLight};
    white-space: pre-line;
    line-height: 1.25em;
    color: ${({ theme }) => theme.colors.primaryText};
  }
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledFirstImage = styled(ImageWithFallback)`
  object-fit: cover;
  width: auto;
  max-width: 100%;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const ReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill')

    // eslint-disable-next-line react/prop-types
    return ({ forwardedRef, ...props }) => {
      return <RQ ref={forwardedRef} {...props} />
    }
  },
  {
    ssr: false,
  }
)

const editorModules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const editorFormats = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
]

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === CUSTOM)

const { postTextMaxLimit: MAX_TEXT_LENGTH, hasMobileNotificationOption } = networkLimits

const CustomPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  isEditable,
  isSmallPreview,
  noVideoAttachment,
  hasPreview,
  postErrors,
  featuresEnabled,
  selectedEntities,
  selectedProfiles,
  previewProfileNumber,
  reminderNotificationsList,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  theme,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const reactQuillRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })

  const { isRTL } = theme || {}
  const TEXT_DIRECTION = isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT

  const MAX_PREVIEW_HEIGHT = isSmallPreview ? 125 : 255
  const MAX_PREVIEW_WIDTH = isSmallPreview ? 250 : 510

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  const {
    profileId,
    network,
    display,
    profileName,
    profilePictureUrl,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
    custom_network_picture_url,
  } = profile

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postDocuments = [],
    postMixedMedias = [],
    customizations = {},
    zoneShort,
  } = post

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const { hasErrors, flags, flags: { notificationReminderLengthLimit } = {}, warning_flags } =
    profilesWithErrors[profileId] || {}

  let updatedPostText
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostDocuments = postDocuments
  let customizedPostMixedMedias = postMixedMedias
  let device_gid = null
  let email_user_gid = null
  let publishing_option = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostDocuments = profileCustomizations.postDocuments || postDocuments
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({ device_gid = null, email_user_gid = null, publishing_option = null } = profileCustomizations)
  }

  const setEditorText = () => {
    if (reactQuillRef && reactQuillRef.current) {
      const editor = reactQuillRef.current.getEditor()

      editor.setText(postText || '')
    }
  }

  useEffect(() => {
    if (isEditable && !updatedPostText) {
      setEditorText()
    }
  }, [postText])

  useEffect(() => {
    if (!updatedPostText) {
      setEditorText()
    }
  }, [updatedPostText])

  const handleUpdateCustomization = ({ type, secondaryType, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    if (secondaryType) {
      if (!post.customizations[network][profileId][type]) {
        post.customizations[network][profileId][type] = {}
      }
      post.customizations[network][profileId][type][secondaryType] = value
    } else {
      post.customizations[network][profileId][type] = value
    }
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { variableTransformedText, transformed_text_to_html_for_textarea } = useMemo(() => {
    // let transformed_text_to_html_for_textarea = ''

    let { variableTransformedText } = variableTransformer({
      str: postText,
      savedVariables,
      post,
      profileId,
    })

    variableTransformedText = variableTransformedText
      ? `<p>${variableTransformedText.split('\n').join('<br/>')}</p>`
      : ''

    // const transformed_text_to_html_for_textarea = linkTransformer({
    //   body: variableTransformedText,
    //   color: CUSTOM_COLORS.LAST_LIGHT_BLUE,
    //   fontWeight: 400,
    //   transformHashstag: true,
    //   hashtagColor: CUSTOM_COLORS.LAST_LIGHT_BLUE,
    //   post_id: post.id,
    // })

    return { variableTransformedText, transformed_text_to_html_for_textarea }
  }, [postText, savedVariables])

  let sourceTypeTemplate = ''

  let medias = []

  switch (customizedPostComponent) {
    case POST_IMAGE:
      medias = customizedPostImages
      break
    case POST_VIDEO:
      medias = customizedPostVideos
      break

    case POST_DOCUMENT:
      medias = customizedPostDocuments
      break
    case POST_MIXED_MEDIA:
      medias = customizedPostMixedMedias
      break
    default:
      break
  }

  const generateMediaTemplate = ({ media }) => {
    const { url, thumbnail_url, id, [network]: networkErrors = {}, isNew, type, extension } = media
    const { hasAspectRatioError, hasSizeError, hasResolutionError, hasExtensionError } = networkErrors || {}
    const hasError = hasAspectRatioError || hasSizeError || hasResolutionError || hasExtensionError
    return (
      <Flex width={`${MAX_PREVIEW_WIDTH}px`} height={`${MAX_PREVIEW_HEIGHT}px`} key={id}>
        <StyledBox position="relative" width="100%" justifyContent="center" hasError={hasError}>
          {isNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              {type === IMAGE && (
                <StyledFirstImage
                  name={id}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  source={url}
                  width="100%"
                  height="100%"
                />
              )}

              {(type === VIDEO || type === VIDEO_DYNAMIC) && (
                <Fragment>
                  {noVideoAttachment ? (
                    <Flex justifyContent="center" alignItems="center" position="relative" width="100%" height="100%">
                      {type === VIDEO && (
                        <Fragment>
                          <Box position="absolute" top={space.xs} right={space.xs}>
                            <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                          </Box>
                          <StyledVideoThumbnailImage
                            height="100%"
                            width="100%"
                            source={thumbnail_url || DEFAULT_VIDEO_THUMBNAIL}
                            fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                            name={id}
                            id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                          />
                        </Fragment>
                      )}
                      {type === VIDEO_DYNAMIC && (
                        <DynamicMedia
                          media={media}
                          post={post}
                          profiles={[profile]}
                          verticalPreview
                          isSmall={isSmallPreview}
                        />
                      )}
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === VIDEO && (
                        <VideoWithFallback
                          id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                          url={url}
                          controls={false}
                          loop
                          width="100%"
                          style={{ position: 'relative' }}
                          height="100%"
                          config={{
                            file: {
                              attributes: {
                                poster: thumbnail_url,
                              },
                            },
                          }}
                          fallbackSourceImage={thumbnail_url}
                          name={id}
                        />
                      )}

                      {type === VIDEO_DYNAMIC && (
                        <Flex width={`${MAX_PREVIEW_HEIGHT}px`} height={`${MAX_PREVIEW_HEIGHT}px`}>
                          <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={isSmallPreview} />
                        </Flex>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}

              {type === IMAGE_DYNAMIC && (
                <Flex width={`${MAX_PREVIEW_HEIGHT}px`} height={`${MAX_PREVIEW_HEIGHT}px`}>
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={isSmallPreview} />
                </Flex>
              )}

              {type === DOCUMENT && (
                <Flex flexDirection="column">
                  <ImageWithFallback
                    source={thumbnail_url || getFileIconByExtension({ extension })}
                    width="100%"
                    maxHeight="200px"
                    fallbackSource={getFileIconByExtension({ extension })}
                    name={id}
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  />
                </Flex>
              )}
            </Fragment>
          )}
        </StyledBox>
      </Flex>
    )
  }

  if (medias && medias.length !== 0) {
    sourceTypeTemplate = (
      <MediaCarousel
        post={post}
        width={MAX_PREVIEW_WIDTH}
        height={MAX_PREVIEW_HEIGHT}
        medias={medias}
        sliderElements={medias.map((media) => {
          return (
            <Flex width="100%" height="100%">
              {generateMediaTemplate({ media })}
            </Flex>
          )
        })}
        activeDotsColor={COLOR_CONSTANTS.WHITE}
      />
    )
  }

  return (
    <Flex flexDirection="column" height="100%">
      {!isEditable ? (
        <Fragment>
          {hasPreview && (
            <StyledFlex px="m" flexDirection="column">
              <Box mt="0.5em">
                <Flex>
                  <SocialProfileImage
                    code={network}
                    picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                    width="2.1875em"
                    height="2.1875em"
                    custom_network_picture_url={custom_network_picture_url}
                  />
                  <Flex ml="1em" flexDirection="column" justifyContent="space-between">
                    <Flex
                      alignItems="center"
                      onMouseEnter={(e) => {
                        if (!isMultipleProfilesPreview) {
                          tooltipRef.current.handleShowTooltip({
                            contentComp: (
                              <ProfileItemTooltip
                                profile={{
                                  code: network,
                                  picture_url: profilePictureUrl,
                                  profile_url: profileUrl,
                                  name: profileName,
                                  username: profileUsername,
                                  display,
                                  profile_id,
                                  type: profile_type,
                                  custom_network_picture_url,
                                }}
                                message=""
                              />
                            ),
                            wrapperComp: e.currentTarget,
                          })
                        }
                      }}
                      onMouseLeave={() => {
                        tooltipRef.current.handleHideTooltip()
                      }}
                    >
                      <StyledLink
                        as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                        href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                        target="_blank"
                        color={CUSTOM_COLORS.BLACK}
                        fontWeight="medium"
                        fontSize="1em"
                      >
                        {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                      </StyledLink>
                    </Flex>
                    <StyledLink fontSize="0.5em" color={CUSTOM_COLORS.GLUON_GREY}>
                      {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'}
                    </StyledLink>
                  </Flex>
                </Flex>
              </Box>

              <Text
                dangerouslySetInnerHTML={{
                  __html: updatedPostText || variableTransformedText,
                }}
                dir={TEXT_DIRECTION}
              />

              {customizedPostComponent ? <Box my="s">{sourceTypeTemplate}</Box> : <Box mt="s" />}
            </StyledFlex>
          )}
        </Fragment>
      ) : (
        <Flex pt="s" pb="l" flexDirection="column" height="100%">
          <PreviewPromptComponent
            networkLimits={networkLimits}
            hasErrors={hasErrors}
            flags={flags}
            warning_flags={warning_flags}
            network={network}
            display={display}
            customizedPostComponent={customizedPostComponent}
            customizedPostImages={customizedPostImages}
            customizedPostVideos={postVideos}
            customizedPostMixedMedias={customizedPostMixedMedias}
            initialCustomizedPostMixedMedias={customizedPostMixedMedias}
          />

          <Flex
            flexDirection="column"
            height="100%"
            className={isRTL ? 'ql-editor-wrapper-right' : 'ql-editor-wrapper-left'}
          >
            <ReactQuill
              id="custom-network-quill-caption"
              forwardedRef={reactQuillRef}
              theme="snow"
              defaultValue={updatedPostText || ''}
              textareaPlaceholder={isRTL ? '... Write your content' : 'Write your content ...'}
              modules={editorModules}
              formats={editorFormats}
              onChange={(value, delta, changed_by, api) => {
                if (changed_by === 'user') {
                  const text = api.getText()

                  // eslint-disable-next-line eqeqeq
                  if (!text || text === '\n' || text === `${postText}\n` || value === `<p>${postText}</p>`) {
                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                  } else {
                    handleUpdateCustomizationDebounce({ type: 'message', value })
                  }
                }
              }}
              readOnly={!isEditable}
            />
          </Flex>

          <Flex flexDirection="column" px="m">
            {hasCustomMedias && (
              <Fragment>
                <Flex mt="m" alignItems="center" width="100%">
                  <Flex flexDirection="column" width="calc(100% - 18px)">
                    <CustomizationsMediaComponent
                      uploadMediaComponentRef={uploadMediaComponentRef}
                      user={user}
                      network={network}
                      profileId={profileId}
                      selectedProfiles={selectedProfiles}
                      selectedEntities={selectedEntities}
                      customized_post_data={{
                        postComponent: customizedPostComponent,
                        postImages: customizedPostImages,
                        postVideos: customizedPostVideos,
                        postDocuments: customizedPostDocuments,
                        postMixedMedias: customizedPostMixedMedias,
                      }}
                      showLinkComponent={false}
                      mediasPreviewDisplay={
                        (customizedPostComponent === POST_IMAGE &&
                          customizedPostImages &&
                          customizedPostImages.length !== 0) ||
                        (customizedPostComponent === POST_VIDEO &&
                          customizedPostVideos &&
                          customizedPostVideos.length !== 0) ||
                        (customizedPostComponent === POST_DOCUMENT &&
                          customizedPostDocuments &&
                          customizedPostDocuments.length !== 0) ||
                        (customizedPostComponent === POST_MIXED_MEDIA &&
                          customizedPostMixedMedias &&
                          customizedPostMixedMedias.length !== 0)
                          ? 'block'
                          : 'none'
                      }
                      mediasPreviewSizeSmall
                      menuPlacement="top"
                    />
                  </Flex>
                  <TooltipPromptComponent prompt_key="media" />
                </Flex>
              </Fragment>
            )}

            <PublicationOptionComponent
              handleUpdateCustomization={handleUpdateCustomization}
              publishing_option={publishing_option}
              device_gid={device_gid}
              email_user_gid={email_user_gid}
              reminderNotificationsList={reminderNotificationsList}
              notificationReminderLengthLimit={notificationReminderLengthLimit}
              hasMobileNotificationOption={hasMobileNotificationOption}
              network={network}
            />

            {applyCustomizationsToAllNetworksTemplate && (
              <Flex width="100%" mt="m">
                {applyCustomizationsToAllNetworksTemplate}
              </Flex>
            )}
          </Flex>
        </Flex>
      )}

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

CustomPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  isEditable: true,
  isSmallPreview: false,
  noVideoAttachment: false,
  hasPreview: true,
  postErrors: { networksErrors: {} },
  featuresEnabled: {},
  selectedEntities: [],
  selectedProfiles: [],
  previewProfileNumber: 1,
  reminderNotificationsList: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
}

CustomPost.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isEditable: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  postErrors: PropTypes.object,
  featuresEnabled: PropTypes.object,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  previewProfileNumber: PropTypes.number,
  reminderNotificationsList: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  theme: PropTypes.object.isRequired,
}

CustomPost.displayName = 'CustomPost'

export default withTheme(CustomPost)
