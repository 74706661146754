import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { space, fontSizes, fontWeights, radius } from 'theme'
import { DIRECTION_LEFT_TO_RIGHT, DIRECTION_RIGHT_TO_LEFT } from 'consts'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import { sanitize, variableRegExp } from 'routes/Calendar/helpers'

const StyledTextAreaComponentWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: ${space.s} ${space.s} 0 ${space.s};
  border: none;
  border-radius: ${radius.l} ${radius.l} 0 0;
  font: 400 ${fontSizes.xs} Poppins, sans-serif;
  color: ${({ theme }) => theme.colors.denimText};
  background: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 30px;
  max-height: 300px;
  outline: 0;
  resize: none;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
`

const StyledTextAreaHiddenBox = styled(Box)`
  width: 100%;
  // z-index: 1;
  padding: ${space.s} ${space.s} 0;
  font: 400 ${fontSizes.xs} Poppins, sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 30px;
  max-height: 300px;
  outline: 0;
  resize: none;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
  pointer-events: none;
  border-color: transparent;
  span.selected_range {
    border-radius: ${radius.s};
    background-color: ${({ theme }) => theme.colors.composer_selected_text_range_color};
    color: ${({ theme }) => theme.colors.white};
  }
  span.variable {
    border-radius: ${radius.s};
    background-color: ${({ theme }) => theme.colors.composer_variable_color};
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledAIWrapper = styled(Flex)`
  position: absolute;
  top: 4px;
  align-items: center;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  padding: ${space.xxs} ${space.xs};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const TextAreaComponent = forwardRef(
  (
    { textValue = '', handleUpdateTextFromTextArea, handleOpenMentionComponent, handleClickOpenAIModal, theme },
    ref
  ) => {
    const textAreaRef = useRef(null)
    const hiddenBlockRef = useRef(null)

    const [textAreaValue, setTextAreaValue] = useState(textValue)
    const [selectionRange, setSelectionRange] = useState(null)
    const [isInitial, setIsInitial] = useState(true)

    const { isRTL } = theme || {}

    const syncSize = () => {
      if (hiddenBlockRef && hiddenBlockRef.current && textAreaRef && textAreaRef.current) {
        hiddenBlockRef.current.style.height = textAreaRef.current.style.height
      }
    }

    useEffect(() => {
      new MutationObserver(syncSize).observe(textAreaRef.current, {
        attributes: true,
        attributeFilter: ['style'],
      })

      syncSize()
    }, [])

    useEffect(() => {
      if (isInitial && textValue) {
        if (textAreaRef && textAreaRef.current) {
          textAreaRef.current.style.height = textValue ? `${textAreaRef.current.scrollHeight}px` : 'auto'
          setIsInitial(false)
        }
      }
    }, [textValue])

    useEffect(() => {
      if (selectionRange) {
        const { start, end } = selectionRange

        const text = textAreaRef.current.value

        hiddenBlockRef.current.innerHTML = variableRegExp({
          str: `${sanitize(text.substring(0, start))}<span class="selected_range">${sanitize(
            text.substring(start, end)
          )}</span>${sanitize(text.substring(end))}`,
        })

        hiddenBlockRef.current.style.display = 'block'
      }
    }, [selectionRange])

    const handleChangePostText = (text) => {
      setTextAreaValue(text)
      handleUpdateTextFromTextArea(text)
      if (textAreaRef && textAreaRef.current) {
        textAreaRef.current.style.height = text ? `${textAreaRef.current.scrollHeight}px` : 'auto'
        hiddenBlockRef.current.innerHTML = variableRegExp({ str: text })
      }
    }

    const handleShowRefineWithAI = ({ displayStyle }) => {
      const el = document.getElementById('publish-modal-ai-prompt-refine')
      if (el) {
        el.style.display = displayStyle
      }
    }

    useImperativeHandle(ref, () => ({
      handleChangePostText(text) {
        handleChangePostText(text)
      },
      getTextAreaValue() {
        return textAreaValue
      },
      getTextAreaCurrent() {
        return textAreaRef.current
      },
      getSelectedTextRange() {
        return selectionRange
      },
      setSelectedTextRangeToDefault() {
        return setSelectionRange({ start: 0, end: 0 })
      },
      handleChangeHiddenBoxText(text) {
        hiddenBlockRef.current.innerHTML = text
        hiddenBlockRef.current.innerHTML = variableRegExp({ str: text })
      },
      handleShowRefineWithAI({ displayStyle }) {
        handleShowRefineWithAI({ displayStyle })
      },
    }))

    return (
      <StyledTextAreaComponentWrapper>
        <StyledTextAreaHiddenBox ref={hiddenBlockRef} dir={isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT} />

        {!textAreaValue && (
          <StyledAIWrapper
            onClick={handleClickOpenAIModal}
            id="publish-modal-use-ai-assistant"
            right={isRTL ? '140px' : 'unset'}
            left={isRTL ? 'unset' : '140px'}
          >
            <Image source="/assets/magic_wand.svg" width="20px" height="20px" />
            <Text ml="s">Use the AI Assistant</Text>
          </StyledAIWrapper>
        )}

        <StyledTextArea
          ref={textAreaRef}
          value={textAreaValue}
          onChange={(e) => {
            handleChangePostText(e.target.value)
            syncSize()
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 50 && e.shiftKey) {
              handleOpenMentionComponent()
            }
          }}
          onBlur={() => {
            setSelectionRange({ start: textAreaRef.current.selectionStart, end: textAreaRef.current.selectionEnd })
          }}
          onFocus={() => {
            hiddenBlockRef.current.style.display = 'none'

            handleShowRefineWithAI({ displayStyle: 'none' })
          }}
          onScroll={() => {
            hiddenBlockRef.current.style.top = `${-textAreaRef.current.scrollTop}px`
          }}
          onSelect={() => {
            const { selectionStart, selectionEnd } = textAreaRef.current

            if (
              typeof selectionStart !== 'undefined' &&
              typeof selectionEnd !== 'undefined' &&
              selectionStart !== selectionEnd
            ) {
              if (selectionStart >= 0 && selectionEnd > 0) {
                let textAreaValueString = textAreaRef.current.value.substr(
                  selectionStart,
                  selectionEnd - selectionStart
                )

                textAreaValueString = textAreaValueString.trim()

                if (textAreaValueString) {
                  handleShowRefineWithAI({ displayStyle: 'flex' })
                }
              }
            } else {
              handleShowRefineWithAI({ displayStyle: 'none' })
            }
          }}
          placeholder="Write your content or"
          rows={10}
          dir={isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT}
        />
      </StyledTextAreaComponentWrapper>
    )
  }
)

TextAreaComponent.propTypes = {
  textValue: PropTypes.string.isRequired,
  handleUpdateTextFromTextArea: PropTypes.func.isRequired,
  handleOpenMentionComponent: PropTypes.func.isRequired,
  handleClickOpenAIModal: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(TextAreaComponent)
