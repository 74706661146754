import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import withConfirm from 'utils/withConfirm'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import { AutoSizer, List } from 'react-virtualized'
import { fontSizes, radius, space } from 'theme'
import {
  DEFAULT_SOCIAL_PROFILE_SOURCE,
  FACEBOOK,
  // eslint-disable-next-line no-unused-vars
  FEATURE_POST_TAGGING,
  FEATURE_POST_ENGAGEMENT,
  FEATURE_POST_TARGETING,
} from 'consts'
import { Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Tooltip from 'components/molecules/Tooltip'
import NoData from 'components/molecules/NoData'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { defaultValuesForPost, getPostComponentByNetworks } from '../helpers'
import { MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS, MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS, TABS } from '../consts'
import CalendarItemTooltip from './CalendarItemTooltip'
import ProfileItemTooltip from './ProfileItemTooltip'

const StyledWrapper = styled(Flex)`
  overflow-x: hidden;
`

const TabsWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_color};
`

const StyledBox = styled(Box)`
  position: relative;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `}
  ${({ isNotVisible }) =>
    isNotVisible &&
    `
  display:none;
  `}
`

const StyledImg = styled(ImageWithFallback)`
  width: 22px;
  margin-bottom: -2px;
  margin-left: ${space.sm};
  margin-right: ${space.sm};
  padding-bottom: ${space.m};
  border-radius: ${radius.s};
  object-fit: cover;
  cursor: pointer;
  border-bottom: solid transparent;
  ${({ isActive, theme }) =>
    isActive &&
    `
    opacity: 1;
    border-bottom: solid ${theme.colors.primary};
  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `filter: grayscale(1);
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  `}
`

const StyledErrorWrapper = styled(Flex)`
  box-shadow: 0 0 0 0 ${({ theme }) => theme.colors.error};
  animation: pulse 2s cubic-bezier(0.66, 0, 0, 1) infinite;
  border-radius: ${radius.pill};
`

const NetworkImageWrapper = styled(Flex)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${radius.pill};
  cursor: pointer;
`

const StyledApplyTextWrapper = styled(Text)`
  cursor: pointer;
`

const StyledDropDownProfileImage = styled(ImageWithFallback)`
  height: 20px;
  margin-right: ${space.s};
`

const StyledNotIncludedProfilesWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.xs};
  border-radius: ${radius.pill};
  width: 32px;
  height: 32px;
  margin-top: -${space.xs};
  background: ${({ theme }) => theme.colors.background_item_light};
  &:hover {
    background: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const CUSTOMIZATION_KEYS_NOT_FOR_COPY = [
  'kind',
  'profile_gid',
  // 'message', // removing caption copy blocking for a while. This has been done when adding notification dropdown
  'board_id',
  'board_id_name',
  'section_id',
  'section_id_name',
  'subreddit',
  'subreddit_name',
  'flair_id',
  'flair_text',
  'flair_text_edited',
  'category',
  'category_name',
  'playlist',
  'playlist_name',
  // 'device_gid', // removing device copy blocking for a while. This has been done when adding notification dropdown
  // 'device_name',
  // 'email_user_gid',
  // 'email_user_name',
  'publish_as_user',
  'boost_gid',
]

const PostPreviewComponent = ({
  user,
  onChangeUpdateAllProfilesCustomizations,
  onChangeUpdateSpecificProfileCustomizations,
  postForEdit,
  isPostFormDirty,
  dataForCustomizations,
  setDataForCustomizations,
  postErrors,
  hasPreview,
  confirm,
  selectedEntities,
  reminderNotificationsListByEntity,
  savedVariables,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  blockCommentsMediaAttachment,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [boosts, setBoosts] = useState([])
  const [activeTab, setActiveTab] = useState({
    network: '',
    profile: null,
    activeProfiles: [],
    filteredActiveProfiles: [],
    notIncludedActiveProfiles: [],
    notIncludedActiveProfilesLength: 0,
    networkActiveProfiles: 0,
  })

  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_POST_TAGGING]: { enabled: true },
    [FEATURE_POST_ENGAGEMENT]: { enabled: true },
    [FEATURE_POST_TARGETING]: { enabled: true },
  })

  const {
    id: profileId,
    name: profileName = '',
    picture_url: profilePictureUrl = DEFAULT_SOCIAL_PROFILE_SOURCE,
    display: profileNetworkDisplay,
    username: profileUsername,
    profile_url: profileUrl = '',
    entityId,
    profile_id,
    type,
    custom_network_picture_url,
  } = activeTab.profile || {}

  const {
    filteredActiveProfiles = [],
    networkActiveProfiles = 0,
    notIncludedActiveProfiles = [],
    notIncludedActiveProfilesLength = 0,
  } = activeTab

  let isFacebookGroupProfilePage = false

  if (activeTab.profile) {
    const { needUpdatePost } = defaultValuesForPost({
      selectedProfiles,
      post: postForEdit,
      dataForCustomizations,
      setDataForCustomizations,
      user,
    })

    if (needUpdatePost) {
      onChangeUpdateAllProfilesCustomizations({ post: postForEdit, isDefault: true })
    }

    if (activeTab.network === FACEBOOK) {
      if (profileUrl && profileUrl.includes('/groups/')) {
        isFacebookGroupProfilePage = true
      }
    }
  }

  useEffect(() => {
    setFeaturesEnabled({
      ...checkIfPlanHasFeatureEnabled({
        user,
        features: [FEATURE_POST_TAGGING, FEATURE_POST_ENGAGEMENT, FEATURE_POST_TARGETING],
      }),
    })
  }, [])

  const collectedProfilesData = ({ data }) => {
    // this filter removes duplicate profiles. Same profile with multiple profile groups
    const filtered = data.activeProfiles.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)

    if (filtered.length > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS) {
      data.filteredActiveProfiles = filtered.slice(0, MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS)
      data.notIncludedActiveProfiles = filtered.slice(MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS)
    } else {
      data.filteredActiveProfiles = filtered
      data.notIncludedActiveProfiles = []
    }

    data.networkActiveProfiles = filtered.length

    data.notIncludedActiveProfilesLength = data.notIncludedActiveProfiles.length
  }

  useEffect(() => {
    if (postForEdit.id && props.selectedProfiles.length > 0 && !activeTab.profile) {
      const {
        0: { code },
      } = props.selectedProfiles

      const data = {
        network: code,
        profile: props.selectedProfiles[0],
        activeProfiles: props.selectedProfiles.filter((value) => value.code === code),
      }

      collectedProfilesData({ data })

      setActiveTab({ ...data })
    }
  }, [postForEdit])

  useEffect(() => {
    setSelectedProfiles([...props.selectedProfiles])
    if (props.selectedProfiles.length !== 0) {
      let code = activeTab.network
      if (!code || props.selectedProfiles.findIndex((value) => value.code === code) === -1) {
        // eslint-disable-next-line prefer-destructuring
        code = props.selectedProfiles[0].code
      }

      const activeProfiles = props.selectedProfiles.filter((value) => value.code === code)

      let foundProfile = activeTab.profile ? props.selectedProfiles.find(({ id }) => id === activeTab.profile.id) : null

      if (!foundProfile) {
        // eslint-disable-next-line prefer-destructuring
        foundProfile = activeProfiles[0]
      }

      const data = {
        network: code,
        profile: foundProfile,
        activeProfiles,
      }

      collectedProfilesData({ data })

      setActiveTab({ ...data })
    } else {
      setActiveTab({
        network: '',
        profile: null,
        activeProfiles: [],
        filteredActiveProfiles: [],
        notIncludedActiveProfiles: [],
        notIncludedActiveProfilesLength: 0,
        networkActiveProfiles: 0,
      })
    }
  }, [props.selectedProfiles])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  useEffect(() => {
    if (props.boosts && props.boosts.length !== 0 && activeTab.profile) {
      setBoosts([...props.boosts.filter(({ profile: { id } }) => id === activeTab.profile.id)])
    }
  }, [props.boosts, activeTab])

  const handleClickChangeNetwork = ({ network }) => {
    setBoosts(null)

    const activeProfiles = selectedProfiles.filter((value) => value.code === network)

    const data = {
      network,
      profile: activeProfiles[0],
      activeProfiles,
    }

    collectedProfilesData({ data })

    setActiveTab({ ...data })
  }

  const handleClickChangeProfile = ({ profile }) => {
    setBoosts(null)

    activeTab.profile = profile
    setActiveTab({ ...activeTab })
  }

  const copyCustomizations = ({ customizations }) => {
    postForEdit.customizations[activeTab.network] = customizations
    onChangeUpdateAllProfilesCustomizations({ post: postForEdit, syncData: true })
    Alert.success(`Customizations have been applied to all ${profileNetworkDisplay || ''} profiles`)
  }

  const handleClickCopyCustomizationsToAllProfiles = () => {
    const { customizations = {} } = postForEdit

    const networkCustomization = JSON.parse(JSON.stringify(customizations[activeTab.network] || {}))

    const activeProfileId = activeTab.profile.id
    const activeProfileCustomizations =
      (customizations[activeTab.network] && customizations[activeTab.network][activeProfileId]) || {}

    activeTab.activeProfiles.forEach(({ id }) => {
      if (!networkCustomization[id]) {
        networkCustomization[id] = { profile_gid: id }
      }
    })

    const collectedCustomizationsForEachProfile = {}
    const otherProfilesWithCustomizations = []

    for (const profileId in networkCustomization) {
      if (Number(profileId) !== activeProfileId) {
        collectedCustomizationsForEachProfile[profileId] = { kind: activeTab.network, profile_gid: profileId }
        // checking all other active profiles for customizations that are different from the active profile tab customizations
        for (const key in activeProfileCustomizations) {
          if (!CUSTOMIZATION_KEYS_NOT_FOR_COPY.includes(key)) {
            collectedCustomizationsForEachProfile[profileId][key] = activeProfileCustomizations[key]
            if (
              !networkCustomization[profileId][key] ||
              JSON.stringify(networkCustomization[profileId][key]) === JSON.stringify(activeProfileCustomizations[key])
            ) {
              delete networkCustomization[profileId][key]
            }
          }
        }

        let hasNotBlockedCustomizations = false

        for (const key in networkCustomization[profileId]) {
          if (!CUSTOMIZATION_KEYS_NOT_FOR_COPY.includes(key)) {
            hasNotBlockedCustomizations = true
          }
        }
        if (hasNotBlockedCustomizations) {
          const foundProfile = activeTab.activeProfiles.find(({ id }) => id === Number(profileId))
          if (foundProfile) {
            otherProfilesWithCustomizations.push(foundProfile)
          }
        }
      } else {
        collectedCustomizationsForEachProfile[profileId] = {
          ...activeProfileCustomizations,
          ...{ kind: activeTab.network, profile_gid: profileId },
        }
      }
    }

    if (otherProfilesWithCustomizations.length !== 0) {
      const profileNames = otherProfilesWithCustomizations.map(({ name }) => name).join(', ')
      confirm({
        fn: () => () => copyCustomizations({ customizations: collectedCustomizationsForEachProfile }),
        message: `The following profiles already have post customizations: ${profileNames}. Are you sure you want to reset them?`,
        action: 'Apply customizations',
      })
    } else {
      copyCustomizations({ customizations: collectedCustomizationsForEachProfile })
    }
  }

  /*
  const CUSTOMIZATION_COPY_WARNINGS = {
    [YOUTUBE]: 'YouTube category and playlist is set manually for each profile.',
    [REDDIT]: 'Subreddit is set manually for each profile.',
    [PINTEREST]: 'Pinterest board and section is set manually for each profile.',
    [FACEBOOK]: isFacebookGroupProfilePage && 'Facebook publish option is set manually for each group profile.',
  }
  */

  const PostComponent = getPostComponentByNetworks(activeTab.network)

  let isPostFormDirtyProfile = false

  if (activeTab) {
    const { customizations } = postForEdit
    const { [activeTab.network]: networkCustomizations } = customizations || {}
    const { [profileId]: profileCustomizations } = networkCustomizations || {}
    ;({ isPostFormDirty: isPostFormDirtyProfile = false } = profileCustomizations || {})
  }

  return (
    <StyledWrapper m="0 auto" width="100%" height="100%" flexDirection="column">
      <TabsWrapper pt="m" mt="s" pb="xxs" position="relative" alignItems="center">
        <Flex width="100%" justifyContent="center" flexWrap="wrap">
          {TABS.map((network) => {
            const isDisabled = selectedProfiles.findIndex((value) => value.code === network) === -1

            if (isDisabled) {
              return null
            }

            const { hasErrors = false } = postErrors.networksErrors[network] || {}

            return (
              <StyledBox
                key={network}
                isDisabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) {
                    handleClickChangeNetwork({ network })
                  }
                }}
              >
                <StyledImg
                  src={getSocialNetworkIconByName(network).enabled}
                  isActive={activeTab.network === network}
                  isDisabled={isDisabled}
                />
                {hasErrors && (
                  <StyledErrorWrapper position="absolute" width="16px" height="16px" top="-12px" right="0">
                    <Image src="/assets/bell_red.svg" width="16px" height="16px" />
                  </StyledErrorWrapper>
                )}
              </StyledBox>
            )
          })}
        </Flex>
      </TabsWrapper>
      {networkActiveProfiles > MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS && (
        <TabsWrapper pt="m" mt="s" pb="xxs" position="relative" alignItems="center">
          <Flex width="100%" justifyContent="center" flexWrap="wrap">
            {filteredActiveProfiles.map((profile) => {
              const {
                id,
                picture_url,
                profile_url,
                name,
                code,
                username,
                display,
                profile_id,
                type,
                custom_network_picture_url,
              } = profile
              const networkImage = getSocialNetworkIconByName(code, custom_network_picture_url).enabled

              const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[code] || {}
              const { hasErrors = false } = profilesWithErrors[id] || {}
              return (
                <Flex
                  key={id}
                  onClick={() => {
                    handleClickChangeProfile({ profile })
                  }}
                >
                  <Flex>
                    <Flex
                      position="relative"
                      onMouseEnter={(e) => {
                        tooltipRef.current.handleShowTooltip({
                          contentComp: (
                            <ProfileItemTooltip
                              profile={{
                                code,
                                picture_url,
                                profile_url,
                                name,
                                username,
                                display,
                                profile_id,
                                type,
                                custom_network_picture_url,
                              }}
                            />
                          ),
                          wrapperComp: e.currentTarget,
                        })
                      }}
                      onMouseLeave={() => {
                        tooltipRef.current.handleHideTooltip()
                      }}
                    >
                      <StyledImg
                        height="40px"
                        source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                        fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                        isActive={profileId === id}
                      />
                      <NetworkImageWrapper justifyContent="center" alignItems="center">
                        <Image src={networkImage} width="12px" height="12px" />
                      </NetworkImageWrapper>
                      {hasErrors && (
                        <StyledErrorWrapper position="absolute" width="16px" height="16px" top="-12px" right="0">
                          <Image src="/assets/bell_red.svg" width="16px" height="16px" />
                        </StyledErrorWrapper>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              )
            })}
            {networkActiveProfiles > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS && (
              <Flex>
                <DropdownMenu
                  WrapperComp={
                    <StyledNotIncludedProfilesWrapper>
                      +{notIncludedActiveProfilesLength}
                    </StyledNotIncludedProfilesWrapper>
                  }
                  right={0}
                  isTriangleVisible={false}
                  isDismissedOnClickInside
                  top={30}
                  minWidth="250px"
                >
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <List
                        height={
                          notIncludedActiveProfilesLength > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS
                            ? 300
                            : notIncludedActiveProfilesLength * 30
                        }
                        overscanRowCount={10}
                        noRowsRenderer={() => {}}
                        rowCount={notIncludedActiveProfilesLength}
                        rowHeight={30}
                        width={width}
                        rowRenderer={({ index, key, style }) => {
                          const profile = notIncludedActiveProfiles[index]
                          const { id, picture_url, name, code, username } = profile
                          const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[code] || {}
                          const { hasErrors = false } = profilesWithErrors[id] || {}
                          const isActive = profileId === id

                          return (
                            <Flex flexDirection="column" style={style} key={key}>
                              <DropdownMenu.Item
                                label={name || username}
                                PictureComp={
                                  <Flex position="relative">
                                    <StyledDropDownProfileImage
                                      source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                                      fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                                    />
                                    {hasErrors && (
                                      <StyledErrorWrapper
                                        position="absolute"
                                        width="16px"
                                        height="16px"
                                        top="8px"
                                        right="0"
                                      >
                                        <Image src="/assets/bell_red.svg" width="16px" height="16px" />
                                      </StyledErrorWrapper>
                                    )}
                                  </Flex>
                                }
                                onClick={() => {
                                  handleClickChangeProfile({ profile })
                                }}
                                iconNameEnding="Checkmark"
                                iconWidthEnding={isActive ? '10px' : '0px'}
                                iconColorEnding="primaryText"
                              />
                            </Flex>
                          )
                        }}
                      />
                    )}
                  </AutoSizer>
                </DropdownMenu>
              </Flex>
            )}
          </Flex>
        </TabsWrapper>
      )}
      {activeTab.profile ? (
        <Scrollbars
          className="scrollbars-hidden-x"
          universal
          renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
          renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
          autoHide
        >
          <Flex flexDirection="column" width="100%" height="100%">
            <PostComponent
              key={profileId}
              user={user}
              post={postForEdit}
              profile={{
                profileId,
                profileName,
                profilePictureUrl,
                profileUrl,
                fallbackLogoImage: DEFAULT_SOCIAL_PROFILE_SOURCE,
                display: profileNetworkDisplay,
                profileUsername,
                network: activeTab.network,
                isFacebookGroupProfilePage,
                entityId,
                profile_id,
                type,
                custom_network_picture_url,
              }}
              onChangeUpdatePost={(post) => {
                const { customizations } = post
                const { [activeTab.network]: networkCustomizations } = customizations || {}
                const { [profileId]: profileCustomizations = {} } = networkCustomizations || {}

                onChangeUpdateSpecificProfileCustomizations({
                  customizations: profileCustomizations,
                  profileId,
                  network: activeTab.network,
                })
              }}
              dataForCustomizations={dataForCustomizations}
              setDataForCustomizations={setDataForCustomizations}
              isPostFormDirty={isPostFormDirty || isPostFormDirtyProfile}
              postErrors={postErrors}
              agencyMentions={agencyMentions}
              hasPreview={hasPreview}
              featuresEnabled={featuresEnabled}
              previewProfileNumber={networkActiveProfiles}
              selectedEntities={selectedEntities}
              selectedProfiles={selectedProfiles}
              reminderNotificationsList={
                reminderNotificationsListByEntity[entityId] ? reminderNotificationsListByEntity[entityId].options : []
              }
              savedVariables={savedVariables}
              applyCustomizationsToAllNetworksTemplate={
                networkActiveProfiles > MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS ? (
                  <Flex width="100%" alignItems="center" flexDirection="column">
                    <Tooltip
                      wrapperComp={
                        <StyledApplyTextWrapper
                          fontSize="s"
                          color="primary"
                          onClick={handleClickCopyCustomizationsToAllProfiles}
                        >
                          Apply to all {profileNetworkDisplay} profiles
                        </StyledApplyTextWrapper>
                      }
                      message={`Apply these customizations to all ${profileNetworkDisplay || ''} profiles`}
                      isTriangleVisible={false}
                      left="50%"
                      right="50%"
                    />
                  </Flex>
                ) : null
              }
              hasCustomCaption={hasCustomCaption}
              hasCustomMedias={hasCustomMedias}
              forceCloseNetworkPreviewCaption={forceCloseNetworkPreviewCaption}
              boosts={boosts}
              blockCommentsMediaAttachment={blockCommentsMediaAttachment}
              isAdvocacy={isAdvocacy}
            />
          </Flex>
        </Scrollbars>
      ) : (
        <Flex height="100%" alignItems="center" justifyContent="center" width="100%" p="m">
          <NoData message="Please select a social profile" showHeaderText={false} />
        </Flex>
      )}
      <CalendarItemTooltip ref={tooltipRef} />
    </StyledWrapper>
  )
}

PostPreviewComponent.defaultProps = {
  selectedProfiles: [],
  selectedEntities: [],
  postForEdit: {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  hasPreview: true,
  savedVariables: [],
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isAdvocacy: false,
}

PostPreviewComponent.propTypes = {
  user: PropTypes.object.isRequired,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  onChangeUpdateAllProfilesCustomizations: PropTypes.func.isRequired,
  onChangeUpdateSpecificProfileCustomizations: PropTypes.func.isRequired,
  postForEdit: PropTypes.object,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isPostFormDirty: PropTypes.bool,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  hasPreview: PropTypes.bool,
  confirm: PropTypes.func.isRequired,
  reminderNotificationsListByEntity: PropTypes.object.isRequired,
  savedVariables: PropTypes.array,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

export default withConfirm(PostPreviewComponent)
