import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius } from 'theme'
import {
  DEFAULT_SOCIAL_PROFILE_SOURCE,
  LABELS_TYPE_PUBLICATION,
  DEFAULT_TIME_ZONE,
  ERROR_MESSAGE,
  COMMENT_TYPE_PUBLICATION,
  COMMENT_TYPE_MESSAGE,
} from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Image from 'components/atoms/Image'
import Badge from 'components/atoms/Badge'
import Icon from 'components/atoms/Icon'
import Divider from 'components/atoms/Divider'
import Tooltip from 'components/molecules/Tooltip'
import GradientBadge from 'components/molecules/GradientBadge'
import LabelEditModal from 'shared/LabelEditModal'
import { checkIfNetworkIsAllowedForBoosting } from 'routes/Settings/PublishingSettings/BoostConfigurationSettings/BoostConfigurationModal/helpers'
import BoostConfigurationModal from 'routes/Settings/PublishingSettings/BoostConfigurationSettings/BoostConfigurationModal'
import { getHeaderTitleAndColor, getPostComponentByNetworks } from '../../helpers'
import {
  PUBLISHED,
  APPROVED,
  POST_TYPE_SOCIAL_POST,
  ROUTE_CALENDAR,
  REVIEW,
  REJECTED,
  SPECIAL_UI_STATUS_REVIEW_REJECTED,
  ADVOCACY,
  DELETED,
  REMINDER_SENT,
} from '../../consts'
import HistoryPostModal from '../HistoryPostModal'
import CustomizationsPostModal from '../CustomizationsPostModal'
// import PerformancePostModal from '../PerformancePostModal'
import SliderCommentsPreview from '../SliderCommentsPreview/SliderCommentsPreview'
import SliderPostMediasPreview from './components/SliderPostMediasPreview'
import SliderPostNetworkCommentsPreview from './components/SliderPostNetworkCommentsPreview'
import BoostScheduleManagementModal from '../Posts/components/BoostScheduleManagementModal'

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border-radius: ${radius.l} ${radius.l} 0 0;
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: inherit;
  height: 100%;
  outline: none;
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ color, theme }) => transparentize(0.9, theme.colors[color] || theme.colors.error)};
  border-radius: ${radius.l};
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`

const ThreeDotsWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const ThreeDots = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 14px;
  height: 14px;
`

const PerformanceItemWrapper = styled(Flex)`
  box-shadow: 0 0 8px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
  overflow: hidden;
`

const StyledCommentIconWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledBoostedHelpText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const DEFAULT_SLIDER_OPTIONS = {
  isOpen: false,
  data: null,
}

const DEFAULT_VIEW_POST_MEDIA_SLIDER_OPTIONS = {
  isOpen: false,
  medias: [],
  post_type: null,
  profile_display: '',
  post_gid: null,
}

const DEFAULT_VIEW_POST_NETWORK_COMMENTS_SLIDER_OPTIONS = {
  isOpen: false,
}

const DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS = {
  isOpen: null,
  boostId: null,
  selectedProfile: null,
  publicationId: null,
}

const DEFAULT_BOOST_SCHEDULE_MANAGEMENT_MODAL_OPTIONS = {
  isOpen: null,
  boostId: null,
  boostScheduleAt: null,
  selectedProfile: null,
  publicationId: null,
}

const PreviewComponent = ({
  user,
  showHeader,
  hasComponentControls,
  postForEdit,
  noVideoAttachment,
  handleClickOpenDeletePostModal,
  handleClickDuplicatePost,
  handleClickOpenEvent,
  timezone,
  handleUpdateCalendarPostLabels,
  showPerformance,
  handleUpdateCommentsCounter,
  sharedCalendarData,
  posts,
  showAnonymous,
  handleUpdatePostBoostedData,
  handleUpdatePostBoostScheduleData,
  isSmallPreview,
  handleClickOpenBoostModal,
}) => {
  const [post, setPost] = useState({})
  const [isHistoryPostModalOpen, setIsHistoryPostModalOpen] = useState(false)
  const [isCustomizationsPostModalOpen, setIsCustomizationsPostModalOpen] = useState(false)
  const [isPostLabelModalOpen, setIsPostLabelModalOpen] = useState(false)
  const [performance, setPerformance] = useState([])
  const [sliderOptions, setSliderOptions] = useState({ ...DEFAULT_SLIDER_OPTIONS })
  const [viewPostMediaSliderOptions, setViewPostMediaSliderOptions] = useState({
    ...DEFAULT_VIEW_POST_MEDIA_SLIDER_OPTIONS,
  })
  const [viewPostNetworkCommentsSliderOptions, setViewPostNetworkCommentsSliderOptions] = useState({
    ...DEFAULT_VIEW_POST_NETWORK_COMMENTS_SLIDER_OPTIONS,
  })
  const [boostConfigurationModalOptions, setBoostConfigurationsModalOptions] = useState({
    ...DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS,
  })
  const [boostScheduleManagementModalOptions, setBoostScheduleManagementModalOptions] = useState({
    ...DEFAULT_BOOST_SCHEDULE_MANAGEMENT_MODAL_OPTIONS,
  })

  //  const [isPerformancePostModalOpen, setIsPerformancePostModalOpen] = useState(false)
  // TODO:: performance post modal is hidden. Need to create beautiful analytics modal

  const { sharedCalendarId, can_comment = false } = sharedCalendarData || {}

  const {
    id,
    publication_group_id,
    profile = {},
    result,
    status,
    labels = [],
    queue_labels = [],
    type = '',
    systemUserOnlyForView,
    postImages = [],
    postVideos = [],
    postDocuments = [],
    postMixedMedias = [],
    link,
    insights = {},
    comments = 0,
    entity_gid,
    entities = [],
    profile_gid,
    customizations = {},
    advocacy_groups,
  } = post || {}

  let collaborators

  if (postForEdit) {
    const { profile: { profileId, network } = {} } = postForEdit || {}

    if (profileId && network) {
      const by_network = insights[network]

      if (by_network) {
        const by_profile = by_network[profileId]

        if (by_profile) {
          collaborators = by_profile.collaborators
        }
      }
    }
  }

  const getPostPerformance = async () => {
    try {
      let { profileNetwork, type } = postForEdit || {}
      const { profile = {}, profile: { profileId, profileUrl } = {}, insights = {} } = postForEdit || {}

      if (!profileNetwork) {
        profileNetwork = profile.network
      }

      const response = await request({
        method: 'POST',
        body: { network: profileNetwork, id: profileId, type, insights, profile_url: profileUrl },
        path: `${ROUTE_CALENDAR}/post/transform-insights`,
      })
      const { error, data } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setPerformance([...data])
      }
    } catch (error) {
      errorHelper({ error, componentName: PreviewComponent.displayName, functionName: 'getPostPerformance' })
    }
  }

  useEffect(() => {
    setPost({ ...postForEdit })
    if (showPerformance) {
      getPostPerformance()
    }
  }, [postForEdit])

  const handleClickOpenHistoryModal = () => {
    setIsHistoryPostModalOpen(true)
  }

  const handleClickCloseHistoryModal = () => {
    setIsHistoryPostModalOpen(false)
  }

  const handleClickOpenCustomizationsPostModal = () => {
    setIsCustomizationsPostModalOpen(true)
  }

  const handleClickCloseCustomizationsPostModal = () => {
    setIsCustomizationsPostModalOpen(false)
  }

  const handleClickOpenPostLabelModal = () => {
    setIsPostLabelModalOpen(true)
  }

  const handleClickClosePostLabelModal = () => {
    setIsPostLabelModalOpen(false)
  }

  const handleClickOpenComments = () => {
    const entity_gids = posts.map(({ entity_gid }) => entity_gid)

    let comment_type = COMMENT_TYPE_PUBLICATION
    let comment_type_id = publication_group_id

    if (type === POST_TYPE_SOCIAL_POST) {
      comment_type = COMMENT_TYPE_MESSAGE
      comment_type_id = post.id
    }

    setSliderOptions({
      isOpen: true,
      data: {
        comment_type_id,
        type: comment_type,
        entity_gids: [...new Set(entity_gids)],
        timezone,
        context: { publication_id: post.id },
      },
    })
  }

  const handleClickCloseComments = ({ commentsCounter }) => {
    handleUpdateCommentsCounter({ commentsCounter })
    setSliderOptions({ ...DEFAULT_SLIDER_OPTIONS })
  }

  // const handleClickOpenPerformancePostModal = () => {
  //   setIsPerformancePostModalOpen(true)
  // }
  //
  // const handleClickClosePerformancePostModal = () => {
  //   setIsPerformancePostModalOpen(false)
  // }

  const handleUpdatePostLabels = ({ labels }) => {
    post.labels = labels
    setPost({ ...post })
    handleClickClosePostLabelModal()
    handleUpdateCalendarPostLabels({ id: post.id, labels })
  }

  const handleClickOpenViewPostMediaSlider = () => {
    const {
      postImages = [],
      postVideos = [],
      postDocuments = [],
      postMixedMedias = [],
      type,
      profile: { display } = {},
      id: post_gid,
      link,
    } = post

    const medias = [...postImages, ...postVideos, ...postDocuments, ...postMixedMedias]

    if (link && link.id) {
      medias.push({ id: link.id })
    }

    setViewPostMediaSliderOptions({
      ...{
        isOpen: true,
        medias,
        post_type: type,
        profile_display: display,
        post_gid,
      },
    })
  }

  const handleClickCloseViewPostMediaSlider = () => {
    setViewPostMediaSliderOptions({ ...DEFAULT_VIEW_POST_MEDIA_SLIDER_OPTIONS })
  }

  const handleClickOpenViewPostNetworkCommentsSlider = () => {
    setViewPostNetworkCommentsSliderOptions({ ...{ isOpen: true } })
  }

  const handleClickCloseViewPostNetworkCommentsSlider = () => {
    setViewPostNetworkCommentsSliderOptions({ ...DEFAULT_VIEW_POST_NETWORK_COMMENTS_SLIDER_OPTIONS })
  }

  const handleClickOpenBoostConfigurationModal = () => {
    const { profileId, profileName, profileUsername, network, profilePictureUrl, display } = profile
    const { customizations, id } = post
    const { [network]: networkCustomizations } = customizations || {}
    const { [profileId]: profileCustomizations } = networkCustomizations || {}
    const { boost_gid } = profileCustomizations || {}

    if (boost_gid) {
      setBoostConfigurationsModalOptions({
        ...{
          isOpen: true,
          boostId: boost_gid,
          selectedProfile: {
            value: profileId,
            label: profileName || profileUsername,
            network: { code: network, display },
            picture_url: profilePictureUrl,
          },
          publicationId: id,
          postType: type,
        },
      })
    } else {
      Alert.error('Boost setting is not specified for this post.', { timeout: 5000 })
    }
  }

  const handleClickCloseBoostConfigurationModal = (props) => {
    const { target_result_boosted } = props || {}

    if (target_result_boosted) {
      handleUpdatePostBoostedData({
        id: boostConfigurationModalOptions.publicationId,
        target_result_boosted,
      })
    }

    setBoostConfigurationsModalOptions({ ...DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS })
  }

  const handleClickOpenBoostScheduleManagementModal = () => {
    const { profileId, network } = profile

    const { [network]: networkCustomizations } = customizations || {}
    const { [profileId]: profileCustomizations } = networkCustomizations || {}
    const { boost_gid } = profileCustomizations || {}

    const { boost_schedule_at, boost_schedule_gid } = result

    if (boost_schedule_at) {
      setBoostScheduleManagementModalOptions({
        ...{
          isOpen: true,
          boostId: boost_gid,
          boostScheduleId: boost_schedule_gid,
          boostScheduleAt: boost_schedule_at,
          selectedProfile: profile,
          publicationId: id,
          postType: type,
        },
      })
    } else {
      Alert.error('Boost schedule setting is not specified for this post.', { timeout: 5000 })
    }
  }

  const handleClickCloseBoostScheduleManagementModal = (props) => {
    const { method, data } = props || {}

    if (method) {
      const { boost_gid, boost_schedule_at, boost_schedule_gid } = data || {}

      const { profileId, network } = profile

      if (method === 'update') {
        post.has_boost = true
        post.customizations[network][profileId].boost_gid = boost_gid
        post.result.boost_schedule_at = boost_schedule_at
        post.result.boost_schedule_gid = boost_schedule_gid

        Alert.success(`Boost has been updated.`, {
          timeout: 5000,
        })
      } else if (method === 'delete') {
        delete post.has_boost
        delete post.customizations[network][profileId].boost_gid
        delete post.result.boost_schedule_at
        delete post.result.boost_schedule_gid

        Alert.success(`Boost has been removed. This post will not be boosted.`, {
          timeout: 5000,
        })
      }

      handleUpdatePostBoostScheduleData({
        id: boostScheduleManagementModalOptions.publicationId,
        post,
      })
    }

    setBoostScheduleManagementModalOptions({ ...DEFAULT_BOOST_SCHEDULE_MANAGEMENT_MODAL_OPTIONS })
  }

  const handleClickCopyPostLink = async ({ id, type }) => {
    let link = `${process.env.NEXT_PUBLIC_SITE_URL}/calendar?id=${id}`

    if (type === POST_TYPE_SOCIAL_POST) {
      link += `&type=external`
    }

    await navigator.clipboard.writeText(link)
    Alert.success(`Post link has been copied.`, {
      timeout: 5000,
    })
  }

  let { profileNetwork, has_boost } = post || {}

  // eslint-disable-next-line prefer-const
  let { profileId, display: displayNetwork = '', network } = profile || {}

  if (!profileNetwork && profile) {
    ;({ network: profileNetwork, display: displayNetwork } = profile)
  }

  const { published_link, error, boosted, boost_schedule_at } = result || {}

  const { statusLabel, bgColor } = getHeaderTitleAndColor({ status, network: profileNetwork || network, post })

  profile.fallbackLogoImage = DEFAULT_SOCIAL_PROFILE_SOURCE
  const PostComponent = getPostComponentByNetworks(profileNetwork)

  const { [network]: networkInsights = {} } = insights || {}
  const { [profileId]: profileInsights = {} } = networkInsights || {}
  const { updated: insightsUpdated } = profileInsights || {}

  let boosted_color = 'primaryText'

  let boosted_component = null

  if (!error && has_boost) {
    if (boosted && boosted.processed) {
      if (boosted.error) {
        boosted_color = 'error'

        boosted_component = (
          <Text color={boosted_color}>
            Boost failed due to: <Text as="span">{boosted.error}</Text>{' '}
            {!sharedCalendarId && (
              <StyledBoostedHelpText as="span" color="primary" onClick={handleClickOpenBoostConfigurationModal}>
                Fix
              </StyledBoostedHelpText>
            )}
          </Text>
        )
      } else if (boosted.link) {
        boosted_color = 'success'

        boosted_component = (
          <Text color={boosted_color}>
            Successfully boosted.{' '}
            {boosted.link && !sharedCalendarId && (
              <StyledBoostedHelpText as="a" href={boosted.link} target="_blank">
                View details
              </StyledBoostedHelpText>
            )}
          </Text>
        )
      }
    } else if (boost_schedule_at) {
      boosted_component = (
        <Text color={boosted_color}>
          This post will be boosted {boost_schedule_at.since}.{' '}
          {!sharedCalendarId && (
            <StyledBoostedHelpText as="span" color="primary" onClick={handleClickOpenBoostScheduleManagementModal}>
              Manage
            </StyledBoostedHelpText>
          )}
        </Text>
      )
    } else if (status === PUBLISHED) {
      boosted_component = <Text color={boosted_color}>Your post will be boosted shortly.</Text>
    } else {
      boosted_component = <Text color={boosted_color}>This post will be boosted once published</Text>
    }
  }

  return (
    <Fragment>
      {showHeader && (
        <StyledDialogEnvironmentWrapper
          name={postForEdit.id}
          px="m"
          justifyContent="space-between"
          alignItems="center"
          bg={bgColor}
          flexWrap="wrap"
        >
          <Flex my="m" flexDirection="column">
            <Text fontSize="s" fontWeight="bold" color="white">
              {statusLabel}
            </Text>
          </Flex>
          {post.createdBy && (
            <Text color="white" fontSize="s">
              {post.createdBy}
            </Text>
          )}
        </StyledDialogEnvironmentWrapper>
      )}

      <StyledDialogBodyWrapper
        flex="1"
        justifyContent="center"
        py="m"
        tabIndex={0}
        flexDirection={{ mobile: isSmallPreview ? 'row' : 'column', desktop: 'row' }}
        display={{ mobile: 'grid', desktop: 'flex' }}
      >
        <Flex
          height="100%"
          maxWidth={showAnonymous ? '100%' : '510px'}
          width={noVideoAttachment ? '100%' : '100%'}
          flexDirection="column"
          mx={showPerformance ? 'm' : 0}
        >
          {error && (
            <MessageWrapper alignItems="center" px="m" py="s" mb="m">
              <Text
                fontWeight="bold"
                color="error"
                dangerouslySetInnerHTML={{
                  __html: error,
                }}
              />
            </MessageWrapper>
          )}

          {boosted_component && (
            <MessageWrapper alignItems="center" px="m" py="s" mb="m" color={boosted_color}>
              {boosted_component}
            </MessageWrapper>
          )}

          {status === REVIEW && !sharedCalendarData && (
            <MessageWrapper flexDirection="column" justidyContent="center" px="m" py="s" mb="m">
              <Text color="error">This post is pending review. Please see post history for details.</Text>
            </MessageWrapper>
          )}

          {status === REJECTED && !sharedCalendarData && (
            <MessageWrapper flexDirection="column" justidyContent="center" px="m" py="s" mb="m">
              <Text color="error">This post has been rejected. Please see post history for details.</Text>
            </MessageWrapper>
          )}
          <Flex
            width="100%"
            // height="100%"
            position="relative"
            flexDirection="column"
          >
            <Flex position="absolute" right="-11px" top="-11px" zIndex="10">
              {has_boost && (
                <Flex mr="s" alignItems="center">
                  <Icon.Rocket width="16px" height="16px" fill={boosted_color} />
                </Flex>
              )}
              {!showAnonymous && (
                <Flex mr="s">
                  {(hasComponentControls || (sharedCalendarId && can_comment)) &&
                    (!type || type === POST_TYPE_SOCIAL_POST) && (
                      <Tooltip
                        wrapperComp={
                          <StyledCommentIconWrapper position="relative" onClick={handleClickOpenComments}>
                            <Image
                              src="/assets/vistasocial/dashboard/comment_filled.svg"
                              width={isSmallPreview ? '32px' : '24px'}
                              height={isSmallPreview ? '32px' : '24px'}
                            />
                            <StyledCommentCounterWrapper>
                              <Text fontSize="xxs" color="ternaryText">
                                {comments}
                              </Text>
                            </StyledCommentCounterWrapper>
                          </StyledCommentIconWrapper>
                        }
                        message="Comments - Platform"
                        isTriangleVisible={false}
                        width="170px"
                        defaultTopOffset={-70}
                      />
                    )}
                </Flex>
              )}
              {hasComponentControls && (
                <DropdownMenu
                  WrapperComp={
                    <ThreeDotsWrapper>
                      <ThreeDots>
                        <Icon.More stroke="icon_color_gray" />
                      </ThreeDots>
                    </ThreeDotsWrapper>
                  }
                  isDismissedOnClickInside
                  useFixedPosition
                >
                  {handleClickOpenBoostModal &&
                    !sharedCalendarData &&
                    !has_boost &&
                    checkIfNetworkIsAllowedForBoosting({ network: profileNetwork }) && (
                      <DropdownMenu.Item onClick={handleClickOpenBoostModal} isCursorPointer label="Boost" />
                    )}

                  {handleClickOpenEvent && status !== DELETED && (
                    <DropdownMenu.Item
                      onClick={() => {
                        let useStatus = null

                        if (status === REVIEW || status === REJECTED) {
                          useStatus = SPECIAL_UI_STATUS_REVIEW_REJECTED
                        } else if (status === REMINDER_SENT) {
                          useStatus = APPROVED
                        }

                        handleClickOpenEvent({
                          post,
                          useStatus,
                        })
                      }}
                      isCursorPointer
                      label="View post"
                    />
                  )}

                  {published_link && status !== DELETED && (
                    <DropdownMenu.Item
                      onClick={() => {
                        window.open(published_link)
                      }}
                      isCursorPointer
                      label={`View on ${displayNetwork || 'network'}`}
                    />
                  )}

                  {((postImages && postImages.length > 0) ||
                    (postVideos && postVideos.length > 0) ||
                    (postDocuments && postDocuments.length > 0) ||
                    (postMixedMedias && postMixedMedias.length > 0) ||
                    (link && link.id)) && (
                    <DropdownMenu.Item
                      onClick={() => {
                        handleClickOpenViewPostMediaSlider()
                      }}
                      isCursorPointer
                      label="View media"
                    />
                  )}

                  {!type && (
                    <DropdownMenu.Item onClick={handleClickOpenHistoryModal} isCursorPointer label="View history" />
                  )}

                  {!systemUserOnlyForView && status !== DELETED && (
                    <DropdownMenu.Item onClick={handleClickOpenPostLabelModal} isCursorPointer label="Edit labels" />
                  )}

                  <Divider height="1px" />

                  {(((!type || type === POST_TYPE_SOCIAL_POST) &&
                    (!sharedCalendarId || (sharedCalendarId && can_comment))) ||
                    status === PUBLISHED) && (
                    <Fragment>
                      {(!type || type === POST_TYPE_SOCIAL_POST) &&
                        (!sharedCalendarId || (sharedCalendarId && can_comment)) && (
                          <DropdownMenu.Item
                            onClick={handleClickOpenComments}
                            isCursorPointer
                            label="Comments - Platform"
                          />
                        )}

                      {status === PUBLISHED && (
                        <DropdownMenu.Item
                          onClick={handleClickOpenViewPostNetworkCommentsSlider}
                          isCursorPointer
                          label={`Comments - ${displayNetwork}`}
                        />
                      )}

                      <Divider height="1px" />
                    </Fragment>
                  )}

                  {handleClickDuplicatePost && (
                    <DropdownMenu.Item
                      onClick={() => {
                        handleClickDuplicatePost({ post })
                      }}
                      isCursorPointer
                      label="Duplicate"
                    />
                  )}

                  <DropdownMenu.Item
                    onClick={() => {
                      handleClickCopyPostLink({ id, type })
                    }}
                    isCursorPointer
                    label="Copy link"
                  />

                  {handleClickOpenDeletePostModal && !systemUserOnlyForView && status !== DELETED && (
                    <DropdownMenu.Item
                      onClick={() => {
                        handleClickOpenDeletePostModal({ post })
                      }}
                      isCursorPointer
                      label="Remove"
                    />
                  )}

                  {!type && (
                    <DropdownMenu.Item
                      onClick={handleClickOpenCustomizationsPostModal}
                      isCursorPointer
                      label="Settings"
                    />
                  )}

                  {/* {status === PUBLISHED && ( */}
                  {/*  <DropdownMenu.Item */}
                  {/*    onClick={handleClickOpenPerformancePostModal} */}
                  {/*    isCursorPointer */}
                  {/*    label="Performance" */}
                  {/*  /> */}
                  {/* )} */}
                </DropdownMenu>
              )}
            </Flex>
            <PostComponent
              user={user}
              post={post}
              profile={profile}
              agencyMentions={post.agencyMentions || []}
              isPostFormDirty
              isEditable={false}
              noVideoAttachment={noVideoAttachment}
              isSmallPreview={isSmallPreview}
            />

            {!showAnonymous && (
              <Fragment>
                {(status === PUBLISHED || status === APPROVED) && (
                  <Text mt="s" fontSize="xs" color="secondaryText">
                    {type === POST_TYPE_SOCIAL_POST
                      ? `${status === PUBLISHED ? 'Published' : 'Scheduled'} directly on ${displayNetwork}`
                      : `${status === PUBLISHED ? 'Published' : 'Scheduled'} via Vista Social ${post.via || ''}`}
                    {advocacy_groups && (
                      <Text as="span" fontSize="xs" color="secondaryText">
                        {' '}
                        {advocacy_groups.length === 1 ? 'and shared to advocacy' : 'and shared to advocacies'}
                      </Text>
                    )}
                  </Text>
                )}

                {post.createdBy && (
                  <Text mt="s" color="secondaryText" fontSize="xs">
                    {post.createdBy}
                  </Text>
                )}
              </Fragment>
            )}
          </Flex>
          {hasComponentControls && (
            <Flex flexWrap="wrap">
              {labels &&
                labels.length > 0 &&
                labels.map((label) => <GradientBadge label={label} mr="s" mt="xs" key={label.value} />)}

              {queue_labels &&
                queue_labels.length > 0 &&
                queue_labels.map((label) => (
                  <Badge.Status key={label} mr="s" mt="xs">
                    <Text fontSize="xs" textAlign="center">
                      {label}
                    </Text>
                  </Badge.Status>
                ))}
            </Flex>
          )}
        </Flex>
        {(status === PUBLISHED || status === ADVOCACY) && performance.length > 0 && showPerformance && (
          <Flex mt={{ mobile: 'm', desktop: 0 }} flexDirection="column" minWidth={{ mobile: 'auto', desktop: '520px' }}>
            <Flex px="m" mb="s" alignItems="center" justifyContent="space-between">
              <Text color="secondaryText">Performance</Text>
              {insightsUpdated && <Text color="secondaryText">Last updated on {insightsUpdated}</Text>}
            </Flex>
            <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="m" px="m" width="100%" height="max-content">
              {performance.map(({ title, value, description, key, icon }) => (
                <PerformanceItemWrapper flexDirection="column" key={key} p="s" justifyContent="space-between">
                  <Flex width="100%" flexDirection="column">
                    <Flex alignItems="center">
                      <Image src={icon} width="40px" height="40px" />
                      <Box ml="m" width="100%">
                        <Text fontWeight="medium" fontSize="m">
                          {title}
                        </Text>
                      </Box>
                    </Flex>
                    <Text color="secondaryText" mt="s" fontSize="xs">
                      {description}
                    </Text>
                  </Flex>

                  {value !== null && (
                    <Flex mt="s">
                      <Text fontWeigth="bold" fontSize="xxl">
                        {value}
                      </Text>
                    </Flex>
                  )}
                </PerformanceItemWrapper>
              ))}
            </Grid>

            {collaborators && collaborators.length > 0 && (
              <React.Fragment>
                <Flex mt="l" px="m" mb="s" alignItems="center" justifyContent="space-between">
                  <Text color="secondaryText">Collaborators</Text>
                </Flex>
                <Grid gridGap="m" px="m">
                  {collaborators.map((item) => (
                    <Tooltip
                      onClick={() => {
                        window.open(`https://instagram.com/${item.username}`, '_blank')
                      }}
                      wrapperComp={
                        <GradientBadge
                          label={{
                            label: item.username,
                            background_type: 'COLOR',

                            background_color:
                              item.invite_status === 'Accepted'
                                ? COLOR_CONSTANTS.BABY_BLUE_EYES
                                : COLOR_CONSTANTS.GLACIER_PEARL,
                          }}
                          mr="s"
                          mt="xs"
                          key={item.id}
                        />
                      }
                      contentComp={<Text fontSize="xs">{`Collab ${item.invite_status.toLowerCase()}`}</Text>}
                      isTriangleVisible={false}
                      width="120px"
                      isCursorPointer
                      right="unset"
                    />
                  ))}
                </Grid>
              </React.Fragment>
            )}
          </Flex>
        )}
      </StyledDialogBodyWrapper>
      {isHistoryPostModalOpen && (
        <HistoryPostModal
          handleClickCloseModal={handleClickCloseHistoryModal}
          timezone={timezone}
          post={post}
          isOpen={isHistoryPostModalOpen}
        />
      )}
      {isCustomizationsPostModalOpen && (
        <CustomizationsPostModal
          handleClickCloseModal={handleClickCloseCustomizationsPostModal}
          post={post}
          isOpen={isCustomizationsPostModalOpen}
        />
      )}
      {isPostLabelModalOpen && (
        <LabelEditModal
          handleDismiss={handleClickClosePostLabelModal}
          isOpen={isPostLabelModalOpen}
          data={post}
          handleUpdateDataLabels={handleUpdatePostLabels}
          type={LABELS_TYPE_PUBLICATION}
        />
      )}

      {sliderOptions.isOpen && (
        <SliderCommentsPreview
          isOpen={sliderOptions.isOpen}
          data={sliderOptions.data}
          handleDismiss={handleClickCloseComments}
          user={user}
          sharedCalendarData={sharedCalendarData}
          showProfileGroupSelector
          isSmallPreview={isSmallPreview}
        />
      )}

      {viewPostMediaSliderOptions.isOpen && (
        <SliderPostMediasPreview handleDismiss={handleClickCloseViewPostMediaSlider} {...viewPostMediaSliderOptions} />
      )}

      {viewPostNetworkCommentsSliderOptions.isOpen && (
        <SliderPostNetworkCommentsPreview
          isOpen={viewPostNetworkCommentsSliderOptions.isOpen}
          handleDismiss={handleClickCloseViewPostNetworkCommentsSlider}
          post_gid={id}
          post_type={type}
          user={user}
        />
      )}

      {boostConfigurationModalOptions.isOpen && (
        <BoostConfigurationModal
          user={user}
          handleDismiss={handleClickCloseBoostConfigurationModal}
          isOpen={boostConfigurationModalOptions.isOpen}
          boostId={boostConfigurationModalOptions.boostId}
          selectedProfile={boostConfigurationModalOptions.selectedProfile}
          publicationId={boostConfigurationModalOptions.publicationId}
          postType={boostConfigurationModalOptions.postType}
        />
      )}

      {boostScheduleManagementModalOptions.isOpen && (
        <BoostScheduleManagementModal
          user={user}
          isOpen={boostScheduleManagementModalOptions.isOpen}
          handleDismiss={handleClickCloseBoostScheduleManagementModal}
          boostId={boostScheduleManagementModalOptions.boostId}
          boostScheduleId={boostScheduleManagementModalOptions.boostScheduleId}
          boostScheduleAt={boostScheduleManagementModalOptions.boostScheduleAt}
          selectedProfile={boostScheduleManagementModalOptions.selectedProfile}
          publicationId={boostScheduleManagementModalOptions.publicationId}
          postType={boostScheduleManagementModalOptions.postType}
        />
      )}

      {/* {isPerformancePostModalOpen && ( */}
      {/*  <PerformancePostModal */}
      {/*    handleClickCloseModal={handleClickClosePerformancePostModal} */}
      {/*    post={post} */}
      {/*    isOpen={isPerformancePostModalOpen} */}
      {/*  /> */}
      {/* )} */}
    </Fragment>
  )
}

PreviewComponent.defaultProps = {
  user: null,
  postForEdit: {},
  hasComponentControls: true,
  showHeader: true,
  noVideoAttachment: false,
  handleClickOpenDeletePostModal: null,
  handleClickDuplicatePost: null,
  handleClickOpenEvent: null,
  handleUpdateCalendarPostLabels: () => {},
  showPerformance: false,
  timezone: DEFAULT_TIME_ZONE,
  handleUpdateCommentsCounter: () => {},
  sharedCalendarData: null,
  posts: [],
  showAnonymous: false,
  handleUpdatePostBoostedData: () => {},
  handleUpdatePostBoostScheduleData: () => {},
  isSmallPreview: false,
  handleClickOpenBoostModal: null,
}

PreviewComponent.propTypes = {
  user: PropTypes.object,
  postForEdit: PropTypes.object,
  hasComponentControls: PropTypes.bool,
  showHeader: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  handleClickOpenDeletePostModal: PropTypes.func,
  handleClickDuplicatePost: PropTypes.func,
  handleClickOpenEvent: PropTypes.func,
  timezone: PropTypes.string,
  handleUpdateCalendarPostLabels: PropTypes.func,
  showPerformance: PropTypes.bool,
  handleUpdateCommentsCounter: PropTypes.func,
  sharedCalendarData: PropTypes.object,
  posts: PropTypes.array,
  showAnonymous: PropTypes.bool,
  handleUpdatePostBoostedData: PropTypes.func,
  handleUpdatePostBoostScheduleData: PropTypes.func,
  isSmallPreview: PropTypes.bool,
  handleClickOpenBoostModal: PropTypes.func,
}

PreviewComponent.displayName = 'PreviewComponent'

export default PreviewComponent
