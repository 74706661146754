import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import { CANVA, DOCUMENT, VIDEO } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import CanvaCreateFromScratchModal from './CanvaCreateFromScratchModal'
import CanvaMediaLibraryModal from './CanvaMediaLibraryModal'
import { MAX_IMAGES_ATTACHMENTS_ALLOWED } from '../../consts'
import { getEntitiesAndFilterByNetwork } from '../../helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    min-width: 750px;
    max-width: 800px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
  outline: none;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledCanvaTypeItem = styled(Flex)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: ${space.xl};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: 24px;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  ${({ $isSelected, theme }) => $isSelected && `border-color: ${theme.colors.primary};`};

  &:hover {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const { CANVA_TYPE_SCRATCH, CANVA_TYPE_MEDIA_LIBRARY } = {
  CANVA_TYPE_SCRATCH: 'scratch',
  CANVA_TYPE_MEDIA_LIBRARY: 'media_library',
}

const CANVA_TYPE_OPTIONS = [
  {
    type: CANVA_TYPE_MEDIA_LIBRARY,
    name: 'Import media from your Canva account',
    description: 'Upload and incorporate media from your account into your project.',
    image: '/assets/vistasocial/canva/media_library.svg',
  },
  {
    type: CANVA_TYPE_SCRATCH,
    name: 'Create a new image design from a template',
    description: 'Create unique image designs from scratch, blending creativity and technical skills.',
    image: '/assets/vistasocial/canva/scratch.svg',
  },
]

const DEFAULT_SELCTED_CANVA_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
}

const CanvaDesignModal = ({
  user,
  selectedEntities,
  isOpen,
  handleDismiss,
  handleClickAddMedias,
  type,
  maxImageAttachmentsAllowed,
}) => {
  const [isGettingData, setIsGettingData] = useState(true)
  const [selectedModal, setSelectedModal] = useState(null)
  const [selectedCanvaModalOptions, setSelectedCanvaModalOptions] = useState({ ...DEFAULT_SELCTED_CANVA_MODAL_OPTIONS })
  const [canvaProfiles, setCanvaProfiles] = useState({
    selectedProfile: null,
    profiles: [],
  })
  const [selectedEntitiesFromStorage, setSelectedEntitiesFromStorage] = useState([])
  const [entities, setEntities] = useState([])

  const [templateOptions, setTemplateOptions] = useState([])

  const getEntitiesData = async () => {
    const { profilesByImportance } = await getEntitiesAndFilterByNetwork({
      user,
      setEntities,
      selectedEntities,
      setSelectedEntities: setSelectedEntitiesFromStorage,
      network: CANVA,
    })

    if (profilesByImportance.mainProfiles[0]) {
      // eslint-disable-next-line prefer-destructuring
      canvaProfiles.selectedProfile = profilesByImportance.mainProfiles[0]
    } else if (profilesByImportance.secondaryProfiles[0]) {
      // eslint-disable-next-line prefer-destructuring
      canvaProfiles.selectedProfile = profilesByImportance.secondaryProfiles[0]
    }

    canvaProfiles.profiles = []

    const temp_profiles = [...profilesByImportance.mainProfiles, ...profilesByImportance.secondaryProfiles]

    const included_ids = new Set()

    temp_profiles.forEach((profile) => {
      if (!included_ids.has(profile.id)) {
        canvaProfiles.profiles.push(profile)

        included_ids.add(profile.id)
      }
    })

    setCanvaProfiles({ ...canvaProfiles })

    setIsGettingData(false)
  }

  useEffect(() => {
    if (isOpen) {
      setTemplateOptions([...CANVA_TYPE_OPTIONS])
      setSelectedModal({ ...CANVA_TYPE_OPTIONS[0] })
      getEntitiesData()
    }
  }, [isOpen])

  useEffect(() => {
    if (!isGettingData) {
      if (type === VIDEO || type === DOCUMENT) {
        setTemplateOptions([...[CANVA_TYPE_OPTIONS[0]]])
        // eslint-disable-next-line no-use-before-define
        handleClickNextStep()
      }
    }
  }, [isGettingData])

  const handleClickNextStep = () => {
    if (!selectedModal) {
      Alert.error('Please select Canva option.', { timeout: 5000 })
    } else {
      setSelectedCanvaModalOptions({ ...{ isOpen: true, type: selectedModal.type } })
    }
  }

  const handleClickCloseCanvaModal = () => {
    setSelectedCanvaModalOptions({ ...DEFAULT_SELCTED_CANVA_MODAL_OPTIONS })
    handleDismiss()
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  const handleClickAddCanvaMedias = (data) => {
    handleClickAddMedias(data)
    handleClickCloseModal()
  }

  const handleAddNewProfile = ({ profile }) => {
    canvaProfiles.selectedProfile = { ...profile }

    const foundProfileIndex = canvaProfiles.profiles.find(({ id }) => id === profile.id)

    if (foundProfileIndex > -1) {
      canvaProfiles.profiles.splice(foundProfileIndex, 1)
    }

    canvaProfiles.profiles.unshift(profile)

    setCanvaProfiles({ ...canvaProfiles })
  }

  return (
    <Fragment>
      {!selectedCanvaModalOptions.isOpen && (
        <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
          <Box m="0 auto" width="100%" height="100%" p="l">
            <StyledDialogContent>
              <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
                <H4 my="m">Select Canva design option</H4>
              </StyledDialogEnvironmentWrapper>

              <StyledDialogBodyWrapper flex="1" tabIndex="0">
                <Flex flexDirection="column" width="100%" px="m">
                  {isGettingData ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                      width="100%"
                      minHeight="250px"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <Grid
                      my="l"
                      gridGap="l"
                      gridTemplateColumns={['1fr', `repeat(${templateOptions.length}, 1fr)`]}
                      justifyItems="center"
                    >
                      {templateOptions.map((option) => {
                        const { type, name, description, image } = option

                        const isSelected = selectedModal && selectedModal.type === type

                        return (
                          <StyledCanvaTypeItem
                            as="a"
                            onClick={() => {
                              setSelectedModal({ ...option })
                            }}
                            key={type}
                            $isSelected={isSelected}
                          >
                            <Image source={image} width="48px" height="48px" />
                            <Text mt="m" fontSize="m" fontWeight="bold" color="primaryText" textAlign="center">
                              {name}
                            </Text>
                            <Text mt="m" color="secondaryText" textAlign="center">
                              {description}
                            </Text>
                          </StyledCanvaTypeItem>
                        )
                      })}
                    </Grid>
                  )}
                </Flex>
              </StyledDialogBodyWrapper>

              <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
                <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
                  Cancel
                </Button.Gray>
                <Button.Primary onClick={handleClickNextStep} isSmall>
                  <Text>Continue</Text>
                </Button.Primary>
              </StyledDialogEnvironmentWrapper>
              <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
                <Image width="10px" height="10px" src="/assets/clear.svg" />
              </CloseIconWrapper>
            </StyledDialogContent>
          </Box>
        </StyledDialogOverlay>
      )}

      {selectedCanvaModalOptions.isOpen && selectedCanvaModalOptions.type === CANVA_TYPE_SCRATCH && (
        <CanvaCreateFromScratchModal
          handleDismiss={handleClickCloseCanvaModal}
          isOpen={selectedCanvaModalOptions.isOpen}
          handleClickAddMedias={handleClickAddCanvaMedias}
        />
      )}

      {selectedCanvaModalOptions.isOpen && selectedCanvaModalOptions.type === CANVA_TYPE_MEDIA_LIBRARY && (
        <CanvaMediaLibraryModal
          handleDismiss={handleClickCloseCanvaModal}
          isOpen={selectedCanvaModalOptions.isOpen}
          handleClickAddMedias={handleClickAddCanvaMedias}
          handleAddNewProfile={handleAddNewProfile}
          type={type}
          maxImageAttachmentsAllowed={maxImageAttachmentsAllowed}
          canvaProfiles={canvaProfiles}
          entities={entities}
          selectedEntities={selectedEntities || selectedEntitiesFromStorage}
        />
      )}
    </Fragment>
  )
}

CanvaDesignModal.defaultProps = {
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  selectedEntities: null,
}

CanvaDesignModal.propTypes = {
  user: PropTypes.object.isRequired,
  selectedEntities: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickAddMedias: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  maxImageAttachmentsAllowed: PropTypes.number,
}

export default CanvaDesignModal
