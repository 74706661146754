import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { Box, Flex } from 'components/atoms/Layout'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { IMAGE, VIDEO, AUDIO, ERROR_MESSAGE, IMAGE_DYNAMIC, VIDEO_DYNAMIC, DOCUMENT } from 'consts'
import { getFileIconByExtension, truncate } from 'helpers'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import AudioWithFallback from 'components/atoms/AudioWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Image from 'components/atoms/Image'
import Divider from 'components/atoms/Divider'
import { ROUTE_MEDIA, POST_IMAGE, POST_VIDEO, POST_DOCUMENT } from 'routes/Calendar/consts'
import { handleMediaFileChange } from '../helper'

const ControlsWrapper = styled(Flex)`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  height: 20px;
  width: 16px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background_card};
  border-bottom-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
`

const StyledText = styled(Text)`
  text-align: center;
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.background)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
  &:hover {
    border-color: ${({ theme }) => theme.colors.background_card};
  }
`

const FavoriteWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${({ favorited, theme }) =>
    !favorited &&
    `
    svg {
      fill-opacity: 0.3;
      fill: ${COLOR_CONSTANTS.BLACK};
      path {
        stroke: ${theme.colors.white};
      }
    }
  `}
  &:hover {
    ${({ favorited }) =>
      !favorited &&
      `
      svg {
        path {
          stroke: ${COLOR_CONSTANTS.YELLOW_STAR};
        }
      }
    `}
  }
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const StyledWrapper = styled(Flex)`
  border-radius: ${radius.l};
  position: relative;
  cursor: pointer;
  ${({ selected }) => selected && `box-shadow: 0px 0px 5px 0px rgb(145 147 169 / 50%);`}
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.icon_color_gray)};
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${({ theme }) => theme.colors.background};
`

const StyledPublicationsCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: ${space.xs};
  right: ${space.xs};
  z-index: 2;
  border-radius: ${radius.l};
  padding: ${space.xs} ${space.s};
  cursor: pointer;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  text-align: center;
`

const ThreeDots = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 14px;
  height: 14px;
`

const Item = ({
  media,
  mediaHtmlId,
  handleClickOpenImageEditModal,
  handleClickOpenVideoThumbnailModal,
  handleClickOpenVideoPickSoundModal,
  handleClickOpenMediaMetaInformationModal,
  handleClickRemoveMedia,
  confirm,
  handleClickSendToCompose,
  handleClickSelectMedia,
  handleClickOpenMediaDetailModal,
  handleClickAddToFavorite,
  handleClickRemoverFromFavorite,
  imageExtensions: IMAGE_EXTENSIONS_JOINED,
  handleUpdateAudioCover,
  isSmall,
  isSelectable,
  isFavorable,
  hasControls,
  isFirstInRow,
  handleClickOpenPosts,
  handleClickOpenMediaDynamicModal,
  handleClickDuplicateMedia,
  handleClickSelectSelectedMediasForMoving,
  tooltipRef,
  hasSelectedMedias,
}) => {
  const {
    type,
    url,
    id,
    createdBy,
    created,
    thumbnail_url,
    thumbnail_url_small,
    metaInformation = '',
    thumb_offset,
    width,
    height,
    selected,
    favorite_media_gid,
    title,
    description,
    filename,
    size,
    publications = [],
    extension,
  } = media
  const inputAudioCoverFileRef = useRef(null)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [uploadingMedias, setUploadingMedias] = useState([])

  let mediaDescription = description || title || filename || ''

  if (type === AUDIO) {
    mediaDescription = title || filename || ''
  }

  const publicationsCounter = publications ? publications.length : 0

  const uploadCoverToServerByFile = async ({ file }) => {
    try {
      const body = new FormData()
      body.append('data', file)

      const response = await request({
        method: 'PATCH',
        body,
        path: `${ROUTE_MEDIA}/${id}/audio-cover`,
        sendFile: true,
      })

      const { error, ...urls } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        handleUpdateAudioCover({ id, ...urls })
      }
    } catch (error) {
      errorHelper({ error, componentName: Item.displayName, functionName: 'uploadCoverToServerByFile' })
    } finally {
      setUploadingMedias([])
    }
  }

  useEffect(() => {
    if (uploadingMedias.length !== 0) {
      uploadCoverToServerByFile(uploadingMedias[0])
    }
  }, [uploadingMedias])

  const downloadMedia = async ({ id }) => {
    document.location = `/api/${ROUTE_MEDIA}/download/${id}`
  }

  const handleMediaFile = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      handleMediaFileChange({ acceptedFiles: event.currentTarget.files, setUploadingMedias })
    }
  }

  const handleClickCopyMediaLink = async () => {
    await navigator.clipboard.writeText(url)

    Alert.success(`Media link has been copied`, { timeout: 5000 })
  }

  const handleClickOpenAudioCoverFileModal = () => {
    inputAudioCoverFileRef.current.click()
  }

  let mediaComponent

  if (type === IMAGE) {
    mediaComponent = (
      <ImageWithFallback
        id={mediaHtmlId || id}
        width="100%"
        height="100%"
        source={thumbnail_url_small || thumbnail_url || url}
        borderRadius={isSmall ? radius.l : `${radius.l} ${radius.l} 0 0`}
        fallbackSource={extension === 'gif' ? url : '/assets/landscape.svg'}
      />
    )
  } else if (type === VIDEO) {
    mediaComponent = (
      <VideoWithFallback
        id={mediaHtmlId || id}
        url={thumbnail_url || thumbnail_url_small ? null : url}
        controls
        width="100%"
        style={{ position: 'relative', borderRadius: isSmall ? radius.l : `${radius.l} ${radius.l} 0 0` }}
        height="100%"
        config={{
          file: {
            attributes: {
              poster: thumbnail_url_small || thumbnail_url || '',
            },
          },
        }}
        fallbackSourceImage={thumbnail_url_small || thumbnail_url}
        playIcon={
          <Box position="absolute" top={`-${space.xxs}`} right="20px">
            <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
          </Box>
        }
      />
    )
  } else if (type === AUDIO) {
    mediaComponent = (
      <AudioWithFallback
        id={mediaHtmlId || id}
        url={null}
        controls
        width="100%"
        style={{ position: 'relative', borderRadius: `${radius.l} ${radius.l} 0 0` }}
        height="100%"
        config={{
          file: {
            attributes: {
              poster: thumbnail_url_small || thumbnail_url || '',
            },
          },
        }}
        name={id}
        filename={`${title}${title && filename ? ' - ' : ''}${filename}`}
        fallbackSourceImage={thumbnail_url_small || thumbnail_url}
        playIcon={
          <Box position="absolute" top={`-${space.xxs}`} right="20px">
            <StyledPlayImage src="/assets/vistasocial/musical_note.svg" width="18px" height="18px" />
          </Box>
        }
      />
    )
  } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
    mediaComponent = (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={isSmall ? '100px' : '150px'}
        height={isSmall ? '100px' : '150px'}
      >
        <DynamicMedia media={media} isSmall showDynamicIcon />
      </Flex>
    )
  } else if (type === DOCUMENT) {
    mediaComponent = (
      <ImageWithFallback
        id={mediaHtmlId || id}
        width="100%"
        height="100%"
        source={thumbnail_url_small || thumbnail_url || getFileIconByExtension({ extension })}
        fallbackSource={getFileIconByExtension({ extension })}
        borderRadius={isSmall ? radius.l : `${radius.l} ${radius.l} 0 0`}
        fallbackSourceWidth="50%"
        fallbackSourceHeight="50%"
      />
    )
  }

  return (
    <StyledWrapper
      width="100%"
      height="100%"
      flexDirection="column"
      zIndex={isDropDownOpen ? 10 : 3}
      bg="background_card"
    >
      <MediaWrapper
        selected={selected}
        onClick={(e) => {
          if (hasSelectedMedias && isSelectable) {
            handleClickSelectMedia()
          } else {
            e.preventDefault()
            handleClickOpenMediaDetailModal()
          }
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          width={isSmall ? '100%' : '100%'}
          height="100%"
          maxHeight={isSmall ? '100px' : '150px'}
          position="relative"
        >
          <StyledProgressWrapper
            display={uploadingMedias.length !== 0 ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            className="cliploader-wrapper"
          >
            <ClipLoader size="50" />
          </StyledProgressWrapper>
          {mediaComponent}
          {type !== AUDIO && publicationsCounter > 0 && (
            <StyledPublicationsCounterWrapper
              onClick={(event) => {
                event.stopPropagation()
                handleClickOpenPosts({ publications })
              }}
            >
              <Box>
                <Box
                  onMouseEnter={(e) => {
                    tooltipRef.current.handleShowTooltip({
                      contentComp: (
                        <Text p="s">
                          {publicationsCounter} {publicationsCounter === 1 ? 'post has' : 'posts have'} been created
                          using this media
                        </Text>
                      ),
                      wrapperComp: e.currentTarget,
                    })
                  }}
                  onMouseLeave={() => {
                    tooltipRef.current.handleHideTooltip()
                  }}
                >
                  <Text fontSize="xxs" color="white">
                    {publicationsCounter > 99 ? '99+' : publicationsCounter}
                  </Text>
                </Box>
              </Box>
            </StyledPublicationsCounterWrapper>
          )}
        </Flex>

        {!isSmall && (
          <Fragment>
            <Excerpt color="secondaryText" fontSize="xxs" mt="xs" px="xs">
              {mediaDescription && truncate(mediaDescription, 35)}&nbsp;
            </Excerpt>
            {createdBy && (
              <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                {createdBy} on {moment(created).format('MMM D, YYYY')}
              </StyledText>
            )}
            {metaInformation && (
              <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                {metaInformation}
              </StyledText>
            )}
          </Fragment>
        )}
      </MediaWrapper>
      {hasControls && (
        <ControlsWrapper alignItems="center" justifyContent="flex-end">
          <DropdownMenu
            handleClickToggleMenu={({ isOpen }) => {
              setIsDropDownOpen(isOpen)
            }}
            WrapperComp={
              <ThreeDots>
                <Icon.More stroke="icon_color_gray" />
              </ThreeDots>
            }
            isDismissedOnClickInside
            left={isSmall && isFirstInRow ? '-85px' : 'unset'}
            isTriangleVisible={!isSmall}
          >
            <DropdownMenu.Item
              label="Settings"
              iconName="Gear"
              iconWidth="14px"
              onClick={() => {
                handleClickOpenMediaMetaInformationModal({ media })
              }}
              isCursorPointer
            />

            {(type === IMAGE || type === IMAGE_DYNAMIC) && (
              <DropdownMenu.Item
                label="Edit image"
                iconName="VistaSocialPicture"
                onClick={() => {
                  if (type === IMAGE) {
                    handleClickOpenImageEditModal({ id, url, size })
                  } else if (type === IMAGE_DYNAMIC) {
                    handleClickOpenMediaDynamicModal()
                  }
                }}
                isCursorPointer
              />
            )}

            {type === VIDEO_DYNAMIC && (
              <DropdownMenu.Item
                label="Edit video"
                iconName="VistaSocialPicture"
                onClick={() => {
                  handleClickOpenMediaDynamicModal()
                }}
                isCursorPointer
              />
            )}

            {type === VIDEO && (
              <Fragment>
                <DropdownMenu.Item
                  label="Pick thumbnail"
                  iconName="VistaSocialPicture"
                  onClick={() => {
                    handleClickOpenVideoThumbnailModal({ id, url, thumbnail_url, thumb_offset, width, height })
                  }}
                  isCursorPointer
                />
                <DropdownMenu.Item
                  label="Pick sound"
                  iconWidth="14px"
                  iconColor="icon_color_gray"
                  iconName="Clavis"
                  onClick={() => {
                    handleClickOpenVideoPickSoundModal(media)
                  }}
                  isCursorPointer
                />
              </Fragment>
            )}

            {type === AUDIO && (
              <DropdownMenu.Item
                label="Pick cover"
                iconName="VistaSocialPicture"
                onClick={handleClickOpenAudioCoverFileModal}
                isCursorPointer
              />
            )}

            <Divider height="1px" />

            {type !== AUDIO && (
              <Fragment>
                {handleClickSendToCompose && (
                  <DropdownMenu.Item
                    label="Create a post"
                    iconName="VistaSocialPaperPlane"
                    iconWidth="14px"
                    iconStroke="icon_color_gray"
                    onClick={() => {
                      const post = { isFromFindContentModal: true }
                      if (type === IMAGE || type === IMAGE_DYNAMIC) {
                        post.postImages = [media]
                        post.postComponent = POST_IMAGE
                      } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                        post.postVideos = [media]
                        post.postComponent = POST_VIDEO
                      } else if (type === DOCUMENT) {
                        post.postDocuments = [media]
                        post.postComponent = POST_DOCUMENT
                      }
                      handleClickSendToCompose({ post })
                    }}
                    isCursorPointer
                  />
                )}

                {publicationsCounter > 0 && (
                  <DropdownMenu.Item
                    label="View posts"
                    iconName="News"
                    iconWidth="18px"
                    iconHeight="18px"
                    iconColor="icon_color_gray"
                    onClick={() => {
                      handleClickOpenPosts({ publications })
                    }}
                    isCursorPointer
                  />
                )}

                <Divider height="1px" />
              </Fragment>
            )}

            {type !== IMAGE_DYNAMIC && type !== VIDEO_DYNAMIC && (
              <Fragment>
                <DropdownMenu.Item
                  label="Copy direct link"
                  iconName="Link"
                  iconWidth="13px"
                  iconHeight="13px"
                  iconColor="icon_color_gray"
                  onClick={handleClickCopyMediaLink}
                  isCursorPointer
                />

                <DropdownMenu.Item
                  label="Download"
                  iconName="Download"
                  onClick={() => {
                    downloadMedia({ id })
                  }}
                  isCursorPointer
                />

                <DropdownMenu.Item
                  label="Duplicate"
                  iconName="Copy"
                  iconWidth="14px"
                  iconColor="icon_color_gray"
                  onClick={handleClickDuplicateMedia}
                  isCursorPointer
                />
              </Fragment>
            )}

            <DropdownMenu.Item
              label="Move"
              iconName="FileMove"
              iconWidth="16px"
              iconHeight="16px"
              onClick={() => {
                handleClickSelectSelectedMediasForMoving({ id })
              }}
              isCursorPointer
            />

            <DropdownMenu.Item
              label="Remove"
              iconName="Trash"
              onClick={() => {
                confirm({
                  fn: () => () => handleClickRemoveMedia({ medias: [id] }),
                  message: 'Are you sure you want to remove this media?',
                  action: 'Remove',
                })
              }}
              isCursorPointer
            />
          </DropdownMenu>
        </ControlsWrapper>
      )}

      {isSelectable && (
        <Box
          position="absolute"
          top={space.xs}
          left={space.xs}
          zIndex="2"
          onClick={(event) => {
            event.stopPropagation()
            handleClickSelectMedia()
          }}
        >
          <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
            <StyledIconCheckmark selected={selected} width="10px" height="10px" />
          </SelectWrapper>
        </Box>
      )}

      {isFavorable && (
        <Box
          position="absolute"
          top={space.xs}
          left={space.l}
          zIndex="2"
          onClick={(event) => {
            event.stopPropagation()
            if (favorite_media_gid) {
              handleClickRemoverFromFavorite()
            } else {
              handleClickAddToFavorite()
            }
          }}
        >
          <FavoriteWrapper alignItems="center" justifyContent="center" favorited={favorite_media_gid}>
            {favorite_media_gid ? (
              <Icon.StarEmpty width="20px" height="20px" fill={COLOR_CONSTANTS.YELLOW_STAR} />
            ) : (
              <Icon.StarEmpty width="20px" height="20px" />
            )}
          </FavoriteWrapper>
        </Box>
      )}

      <input
        hidden
        type="file"
        ref={inputAudioCoverFileRef}
        onChange={(e) => handleMediaFile(e)}
        accept={IMAGE_EXTENSIONS_JOINED}
        onClick={(event) => {
          event.target.value = null
        }}
      />
    </StyledWrapper>
  )
}

Item.defaultProps = {
  handleClickSendToCompose: null,
  mediaHtmlId: '',
}

Item.propTypes = {
  media: PropTypes.object.isRequired,
  mediaHtmlId: PropTypes.string,
  handleClickOpenImageEditModal: PropTypes.func.isRequired,
  handleClickOpenVideoThumbnailModal: PropTypes.func.isRequired,
  handleClickOpenVideoPickSoundModal: PropTypes.func.isRequired,
  handleClickOpenMediaMetaInformationModal: PropTypes.func.isRequired,
  handleClickRemoveMedia: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  handleClickSendToCompose: PropTypes.func,
  handleClickSelectMedia: PropTypes.func.isRequired,
  handleClickOpenMediaDetailModal: PropTypes.func.isRequired,
  handleClickAddToFavorite: PropTypes.func.isRequired,
  handleClickRemoverFromFavorite: PropTypes.func.isRequired,
  imageExtensions: PropTypes.string.isRequired,
  handleUpdateAudioCover: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  isFavorable: PropTypes.bool.isRequired,
  hasControls: PropTypes.bool.isRequired,
  isFirstInRow: PropTypes.bool.isRequired,
  handleClickOpenPosts: PropTypes.func.isRequired,
  handleClickOpenMediaDynamicModal: PropTypes.func.isRequired,
  handleClickDuplicateMedia: PropTypes.func.isRequired,
  handleClickSelectSelectedMediasForMoving: PropTypes.func.isRequired,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  hasSelectedMedias: PropTypes.bool.isRequired,
}

Item.displayName = 'MediaItem'

export default withConfirm(Item)
