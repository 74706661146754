import React from 'react'

export const SOCIALS = [
  {
    name: '500px',
    network: '500px',
  },
  {
    name: 'Amazon Music',
    network: 'amazonmusic',
  },
  {
    name: 'Apple Music',
    network: 'applemusic',
  },
  {
    name: 'Bandcamp',
    network: 'bandcamp',
  },
  {
    name: 'Behance',
    network: 'behance',
  },
  {
    name: 'Blog',
    network: 'blog',
    placeholder: 'Your blog URL',
  },
  {
    name: 'Clubhouse',
    network: 'clubhouse',
  },
  {
    name: 'Deezer',
    network: 'deezer',
  },
  {
    name: 'Discord',
    network: 'discord',
  },
  {
    name: 'Dribble',
    network: 'dribble',
  },
  {
    name: 'Email',
    network: 'email',
  },
  {
    name: 'Github',
    network: 'github',
  },

  {
    name: 'Kuaishou',
    network: 'kuaishou',
  },
  {
    name: 'Mastodon',
    network: 'mastodon',
  },
  {
    name: 'Medium',
    network: 'medium',
  },
  {
    name: 'OnlyFans',
    network: 'onlyfans',
  },
  {
    name: 'OpenSea',
    network: 'opensea',
  },
  {
    name: 'Patreon',
    network: 'patreon',
  },
  {
    name: 'PayPal',
    network: 'paypal',
  },
  {
    name: 'Picsart',
    network: 'picsart',
  },
  {
    name: 'Quora',
    network: 'quora',
  },
  {
    name: 'Sina Weibo',
    network: 'sinaweibo',
  },
  {
    name: 'Sms',
    network: 'sms',
  },
  {
    name: 'SoundCloud',
    network: 'soundcloud',
  },
  {
    name: 'Spotify',
    network: 'spotify',
  },
  {
    name: 'Substack',
    network: 'substack',
  },
  {
    name: 'Telegram',
    network: 'telegram',
  },
  {
    name: 'Twitch',
    network: 'twitch',
  },
  {
    name: 'Viber',
    network: 'viber',
  },
  {
    name: 'Vimeo',
    network: 'vimeo',
  },
  {
    name: 'WeChat',
    network: 'wechat',
  },
  {
    name: 'WhatsApp',
    network: 'whatsapp',
  },
]

export const OTHER = [
  {
    name: 'bell',
    network: 'bell',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m22.555 13.662-1.9-6.836A9.321 9.321 0 0 0 2.576 7.3l-1.471 6.615A5 5 0 0 0 5.986 20H7.1a5 5 0 0 0 9.8 0h.838a5 5 0 0 0 4.818-6.338ZM12 22a3 3 0 0 1-2.816-2h5.632A3 3 0 0 1 12 22Zm8.126-5.185A2.977 2.977 0 0 1 17.737 18H5.986a3 3 0 0 1-2.928-3.651l1.47-6.616a7.321 7.321 0 0 1 14.2-.372l1.9 6.836a2.977 2.977 0 0 1-.502 2.618Z" />
      </svg>
    ),
  },
  {
    name: 'biking-mountain',
    network: 'biking-mountain',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M5 13a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm14-8a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm-8.636-9.723a.988.988 0 0 1-.051-1.457c.169-.16 2.653-2.44 2.653-2.44l3.327 3.327a1 1 0 0 0 1.414-1.414l-4-4a.961.961 0 0 0-.124-.107A6.454 6.454 0 0 0 9.923 4C8.521 4 5 5.19 5 8a3 3 0 0 0 3 3c.024 0 .046-.005.069-.006a2.99 2.99 0 0 0 .993 1.8L11 14.459V18a1 1 0 0 0 2 0v-4a1 1 0 0 0-.349-.759ZM7 8c0-1.214 2.172-2 2.923-2a4.194 4.194 0 0 1 1.339.227s-2.316 2.134-2.343 2.16C8.538 8.749 8.225 9 8 9a1 1 0 0 1-1-1Zm7-5.5A2.5 2.5 0 1 1 16.5 5 2.5 2.5 0 0 1 14 2.5Z" />
      </svg>
    ),
  },
  {
    name: 'bookmark',
    network: 'bookmark',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M20.137 24a2.8 2.8 0 0 1-1.987-.835L12 17.051l-6.15 6.118a2.8 2.8 0 0 1-3.095.609A2.8 2.8 0 0 1 1 21.154V5a5 5 0 0 1 5-5h12a5 5 0 0 1 5 5v16.154a2.8 2.8 0 0 1-1.751 2.624 2.867 2.867 0 0 1-1.112.222ZM6 2a3 3 0 0 0-3 3v16.154a.843.843 0 0 0 1.437.6l6.863-6.821a1 1 0 0 1 1.41 0l6.855 6.819a.843.843 0 0 0 1.437-.6V5a3 3 0 0 0-3-3Z" />
      </svg>
    ),
  },
  {
    name: 'briefcase',
    network: 'briefcase',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M19 4h-1.1A5.009 5.009 0 0 0 13 0h-2a5.009 5.009 0 0 0-4.9 4H5a5.006 5.006 0 0 0-5 5v10a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5V9a5.006 5.006 0 0 0-5-5Zm-8-2h2a3 3 0 0 1 2.816 2H8.184A3 3 0 0 1 11 2ZM5 6h14a3 3 0 0 1 3 3v3H2V9a3 3 0 0 1 3-3Zm14 16H5a3 3 0 0 1-3-3v-5h9v1a1 1 0 0 0 2 0v-1h9v5a3 3 0 0 1-3 3Z" />
      </svg>
    ),
  },
  {
    name: 'building',
    network: 'building',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M4 13h3v2H4zm5 2h3v-2H9zm-5 4h3v-2H4zm5 0h3v-2H9zM4 7h3V5H4zm5 0h3V5H9zm-5 4h3V9H4zm5 0h3V9H9zm15-3v16H0V3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v2h5a3 3 0 0 1 3 3zM14 3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v19h12zm8 5a1 1 0 0 0-1-1h-5v15h6zm-4 7h2v-2h-2zm0 4h2v-2h-2zm0-8h2V9h-2z" />
      </svg>
    ),
  },
  {
    name: 'bulb',
    network: 'bulb',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m17.994 2.286a9 9 0 0 0 -14.919 5.536 8.938 8.938 0 0 0 2.793 7.761 6.263 6.263 0 0 1 2.132 4.566v.161a3.694 3.694 0 0 0 3.69 3.69h.62a3.694 3.694 0 0 0 3.69-3.69v-.549a5.323 5.323 0 0 1 1.932-4 8.994 8.994 0 0 0 .062-13.477zm-5.684 19.714h-.62a1.692 1.692 0 0 1 -1.69-1.69s-.007-.26-.008-.31h4.008v.31a1.692 1.692 0 0 1 -1.69 1.69zm4.3-7.741a7.667 7.667 0 0 0 -2.364 3.741h-1.246v-7.184a3 3 0 0 0 2-2.816 1 1 0 0 0 -2 0 1 1 0 0 1 -2 0 1 1 0 0 0 -2 0 3 3 0 0 0 2 2.816v7.184h-1.322a8.634 8.634 0 0 0 -2.448-3.881 7 7 0 0 1 3.951-12.073 7.452 7.452 0 0 1 .828-.046 6.921 6.921 0 0 1 4.652 1.778 6.993 6.993 0 0 1 -.048 10.481z" />
      </svg>
    ),
  },
  {
    name: 'bullseye',
    network: 'bullseye',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12-5.383,12-12,12Zm0-22C6.486,2,2,6.486,2,12s4.486,10,10,10,10-4.486,10-10S17.514,2,12,2Zm0,18c-4.411,0-8-3.589-8-8S7.589,4,12,4s8,3.589,8,8-3.589,8-8,8Zm0-14c-3.309,0-6,2.691-6,6s2.691,6,6,6,6-2.691,6-6-2.691-6-6-6Zm0,10c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Zm0-6c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Z" />
      </svg>
    ),
  },
  {
    name: 'cake-birthday',
    network: 'cake-birthday',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M23,22H22V14a5.006,5.006,0,0,0-5-5H13V6.039A2.5,2.5,0,0,0,14.5,3.75,7.293,7.293,0,0,0,12.738.327a1,1,0,0,0-1.476,0A7.293,7.293,0,0,0,9.5,3.75,2.5,2.5,0,0,0,11,6.039V9H7a5.006,5.006,0,0,0-5,5v8H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2ZM7,11H17a3,3,0,0,1,3,3v1.98c-.936-.1-1.5-.7-1.5-.98a1,1,0,0,0-2,0c0,.343-.682,1-1.75,1C13.661,16,13,15.306,13,15a1,1,0,0,0-2,0c0,.343-.682,1-1.75,1-1.089,0-1.75-.694-1.75-1a1,1,0,0,0-2,0c0,.315-.579.888-1.5.981V14A3,3,0,0,1,7,11ZM4,17.979A4.156,4.156,0,0,0,6.5,17a4.309,4.309,0,0,0,5.5.015A4.309,4.309,0,0,0,17.5,17a4.156,4.156,0,0,0,2.5.978V22H4Z" />
      </svg>
    ),
  },
  {
    name: 'calendar',
    network: 'calendar',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" />
        <circle cx={12} cy={15} r={1.5} />
        <circle cx={7} cy={15} r={1.5} />
        <circle cx={17} cy={15} r={1.5} />
      </svg>
    ),
  },
  {
    name: 'child-head',
    network: 'child-head',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M24 11.5a3.5 3.5 0 0 0-2.149-3.226 10 10 0 0 0-19.7 0 3.5 3.5 0 0 0 1.119 6.718 10.607 10.607 0 0 0 2.071 2.955 8.908 8.908 0 0 0-2.272 4.928 1 1 0 0 0 .868 1.117 1.093 1.093 0 0 0 .124.008 1 1 0 0 0 .991-.875 6.924 6.924 0 0 1 1.815-3.872A8.948 8.948 0 0 0 12 21a8.94 8.94 0 0 0 5.119-1.74 6.922 6.922 0 0 1 1.808 3.862 1 1 0 0 0 .991.876 1.063 1.063 0 0 0 .125-.008 1 1 0 0 0 .868-1.116 8.9 8.9 0 0 0-2.261-4.918 10.622 10.622 0 0 0 2.082-2.966A3.5 3.5 0 0 0 24 11.5Zm-3.752 1.473a.993.993 0 0 0-1.117.651C18.215 16.222 15.13 19 12 19s-6.215-2.78-7.131-5.378a.994.994 0 0 0-1.117-.651A1.606 1.606 0 0 1 3.5 13a1.5 1.5 0 0 1-.27-2.972 1 1 0 0 0 .816-.878A7.961 7.961 0 0 1 8.13 3a4.075 4.075 0 0 0-.022 1.942 4 4 0 0 0 7.688.318.977.977 0 0 0-.945-1.26H14.7a.867.867 0 0 0-.806.631A2 2 0 1 1 12 2a7.978 7.978 0 0 1 7.954 7.15 1 1 0 0 0 .816.878A1.5 1.5 0 0 1 20.5 13a1.606 1.606 0 0 1-.252-.027Z" />
        <circle cx={9.5} cy={11.5} r={1.5} />
        <circle cx={14.5} cy={11.5} r={1.5} />
      </svg>
    ),
  },
  {
    name: 'cloud',
    network: 'cloud',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M17.792 7.212A8 8 0 0 0 2 9a7.915 7.915 0 0 0 .9 3.671A5.49 5.49 0 0 0 5.5 23H16a8 8 0 0 0 1.792-15.788ZM16 21H5.5a3.491 3.491 0 0 1-.872-6.874 1 1 0 0 0 .554-1.564A5.936 5.936 0 0 1 4 9a6 6 0 0 1 11.94-.8 1 1 0 0 0 .858.86A6 6 0 0 1 16 21Z" />
      </svg>
    ),
  },
  {
    name: 'coins',
    network: 'coins',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M16.5 0C12.294 0 9 1.977 9 4.5v2.587A12.816 12.816 0 0 0 7.5 7C3.294 7 0 8.977 0 11.5v8C0 22.023 3.294 24 7.5 24c3.407 0 6.216-1.297 7.16-3.131.598.087 1.214.131 1.84.131 4.206 0 7.5-1.977 7.5-4.5v-12C24 1.977 20.706 0 16.5 0ZM22 12.5c0 1.18-2.352 2.5-5.5 2.5-.512 0-1.014-.035-1.5-.103v-1.984c.49.057.992.087 1.5.087 2.194 0 4.14-.538 5.5-1.411v.911ZM2 14.589C3.36 15.462 5.306 16 7.5 16s4.14-.538 5.5-1.411v.911c0 1.18-2.352 2.5-5.5 2.5S2 16.68 2 15.5v-.911ZM22 8.5c0 1.18-2.352 2.5-5.5 2.5-.535 0-1.06-.038-1.566-.112-.193-.887-.8-1.684-1.706-2.323.984.28 2.092.435 3.272.435 2.194 0 4.14-.538 5.5-1.411V8.5ZM16.5 2C19.648 2 22 3.32 22 4.5S19.648 7 16.5 7 11 5.68 11 4.5 13.352 2 16.5 2Zm-9 7c3.148 0 5.5 1.32 5.5 2.5S10.648 14 7.5 14 2 12.68 2 11.5 4.352 9 7.5 9Zm0 13C4.352 22 2 20.68 2 19.5v-.911C3.36 19.462 5.306 20 7.5 20s4.14-.538 5.5-1.411v.911c0 1.18-2.352 2.5-5.5 2.5Zm9-3c-.512 0-1.014-.035-1.5-.103v-1.984c.49.057.992.087 1.5.087 2.194 0 4.14-.538 5.5-1.411v.911c0 1.18-2.352 2.5-5.5 2.5Z" />
      </svg>
    ),
  },
  {
    name: 'comment-alt',
    network: 'comment-alt',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M20 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h2.9l4.451 3.763a1 1 0 0 0 1.292 0L17.1 20H20a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 16a2 2 0 0 1-2 2h-2.9a2 2 0 0 0-1.291.473L12 21.69l-3.807-3.217A2 2 0 0 0 6.9 18H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2Z" />
        <path d="M7 7h5a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2ZM17 9H7a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2ZM17 13H7a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2Z" />
      </svg>
    ),
  },
  {
    name: 'comment-info',
    network: 'comment-info',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M13.5 6.5a1.5 1.5 0 0 1-3 0 1.5 1.5 0 0 1 3 0ZM24 19v-6.66A12.209 12.209 0 0 0 12.836.028 12 12 0 0 0 .029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 0 0 5-5ZM12.7 2.024A10.2 10.2 0 0 1 22 12.34V19a3 3 0 0 1-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0 1 12.016 2c.227 0 .456.009.684.024ZM14 18v-6a2 2 0 0 0-2-2h-1a1 1 0 0 0 0 2h1v6a1 1 0 0 0 2 0Z" />
      </svg>
    ),
  },
  {
    name: 'diamond',
    network: 'diamond',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m23.22 5.743-3.011-4.068A4.005 4.005 0 0 0 16.955 0h-9.91A4 4 0 0 0 3.8 1.656L.735 5.753a3.993 3.993 0 0 0 .123 4.784L9.65 22.865A2.985 2.985 0 0 0 12 24a3.006 3.006 0 0 0 2.378-1.175L23.1 10.634a4 4 0 0 0 .12-4.891zm-4.628-2.892 3.018 4.078c.016.022.02.048.036.071h-4.892l-1.428-5h1.629a2.012 2.012 0 0 1 1.637.851zM12 19.118 9.3 9h5.4zM9.326 7l1.428-5h2.492l1.428 5zM5.417 2.837A2.007 2.007 0 0 1 7.045 2h1.629L7.246 7H2.318c.014-.022.018-.049.034-.07zM2.448 9.325A1.9 1.9 0 0 1 2.263 9h4.969l2.994 11.23zM13.771 20.24l3-11.24h4.986a2.1 2.1 0 0 1-.243.421z" />
      </svg>
    ),
  },
  {
    name: 'dice-d6',
    network: 'dice-d6',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m20.572 4.312-6-3.6a4.987 4.987 0 0 0-5.145 0l-6 3.6A5.027 5.027 0 0 0 1 8.6v6.8a5.027 5.027 0 0 0 2.427 4.288l6 3.6a4.985 4.985 0 0 0 5.145 0l6-3.6A5.027 5.027 0 0 0 23 15.4V8.6a5.027 5.027 0 0 0-2.428-4.288ZM10.457 2.428a2.989 2.989 0 0 1 3.086 0l6 3.6a2.978 2.978 0 0 1 .46.341l-8 4.8-8-4.8a2.931 2.931 0 0 1 .46-.341Zm-6 15.545A3.015 3.015 0 0 1 3 15.4V8.6a2.881 2.881 0 0 1 .039-.475L11 12.9v8.928a3.022 3.022 0 0 1-.543-.257ZM21 15.4a3.017 3.017 0 0 1-1.457 2.573l-6 3.6a3.022 3.022 0 0 1-.543.257V12.9l7.961-4.776A2.881 2.881 0 0 1 21 8.6Z" />
      </svg>
    ),
  },
  {
    name: 'document',
    network: 'document',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M17 14a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3H8a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515V19a5.006 5.006 0 0 1-5 5H7a5.006 5.006 0 0 1-5-5V5a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486A6.951 6.951 0 0 1 22 10.485zm-6.949-7.021A5.01 5.01 0 0 0 14 2.684V7a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 0 1-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
      </svg>
    ),
  },
  {
    name: 'edit',
    network: 'edit',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M18.656.93 6.464 13.122A4.966 4.966 0 0 0 5 16.657V18a1 1 0 0 0 1 1h1.343a4.966 4.966 0 0 0 3.535-1.464L23.07 5.344a3.125 3.125 0 0 0 0-4.414 3.194 3.194 0 0 0-4.414 0Zm3 3L9.464 16.122A3.02 3.02 0 0 1 7.343 17H7v-.343a3.02 3.02 0 0 1 .878-2.121L20.07 2.344a1.148 1.148 0 0 1 1.586 0 1.123 1.123 0 0 1 0 1.586Z" />
        <path d="M23 8.979a1 1 0 0 0-1 1V15h-4a3 3 0 0 0-3 3v4H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h9.042a1 1 0 0 0 0-2H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h11.343a4.968 4.968 0 0 0 3.536-1.464l2.656-2.658A4.968 4.968 0 0 0 24 16.343V9.979a1 1 0 0 0-1-1Zm-4.535 12.143a2.975 2.975 0 0 1-1.465.8V18a1 1 0 0 1 1-1h3.925a3.016 3.016 0 0 1-.8 1.464Z" />
      </svg>
    ),
  },
  {
    name: 'globe',
    network: 'globe',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm8.647 7h-3.221a19.676 19.676 0 0 0-2.821-4.644A10.031 10.031 0 0 1 20.647 7ZM16.5 12a10.211 10.211 0 0 1-.476 3H7.976a10.211 10.211 0 0 1-.476-3 10.211 10.211 0 0 1 .476-3h8.048a10.211 10.211 0 0 1 .476 3Zm-7.722 5h6.444A19.614 19.614 0 0 1 12 21.588 19.57 19.57 0 0 1 8.778 17Zm0-10A19.614 19.614 0 0 1 12 2.412 19.57 19.57 0 0 1 15.222 7ZM9.4 2.356A19.676 19.676 0 0 0 6.574 7H3.353A10.031 10.031 0 0 1 9.4 2.356ZM2.461 9H5.9a12.016 12.016 0 0 0-.4 3 12.016 12.016 0 0 0 .4 3H2.461a9.992 9.992 0 0 1 0-6Zm.892 8h3.221A19.676 19.676 0 0 0 9.4 21.644 10.031 10.031 0 0 1 3.353 17Zm11.252 4.644A19.676 19.676 0 0 0 17.426 17h3.221a10.031 10.031 0 0 1-6.042 4.644ZM21.539 15H18.1a12.016 12.016 0 0 0 .4-3 12.016 12.016 0 0 0-.4-3h3.437a9.992 9.992 0 0 1 0 6Z" />
      </svg>
    ),
  },
  {
    name: 'headset',
    network: 'headset',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M21 12.424V11a9 9 0 0 0-18 0v1.424A5 5 0 0 0 5 22a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2v-1a7 7 0 0 1 14 0v1a2 2 0 0 0-2 2v6h-3a1 1 0 0 0 0 2h5a5 5 0 0 0 2-9.576ZM5 20a3 3 0 0 1 0-6Zm14 0v-6a3 3 0 0 1 0 6Z" />
      </svg>
    ),
  },
  {
    name: 'heart',
    network: 'heart',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" />
      </svg>
    ),
  },
  {
    name: 'home',
    network: 'home',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m23.121 9.069-7.585-7.586a5.008 5.008 0 0 0-7.072 0L.879 9.069A2.978 2.978 0 0 0 0 11.19v9.817a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V11.19a2.978 2.978 0 0 0-.879-2.121ZM15 22.007H9v-3.934a3 3 0 0 1 6 0Zm7-1a1 1 0 0 1-1 1h-4v-3.934a5 5 0 0 0-10 0v3.934H3a1 1 0 0 1-1-1V11.19a1.008 1.008 0 0 1 .293-.707L9.878 2.9a3.008 3.008 0 0 1 4.244 0l7.585 7.586a1.008 1.008 0 0 1 .293.704Z" />
      </svg>
    ),
  },
  {
    name: 'megaphone',
    network: 'megaphone',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M17 0a1 1 0 0 0-1 1c0 2.949-2.583 4-5 4H4a4 4 0 0 0-4 4v2a3.979 3.979 0 0 0 1.514 3.109l3.572 7.972A3.233 3.233 0 0 0 8.039 24a2.982 2.982 0 0 0 2.72-4.2l-2.2-4.8H11c2.417 0 5 1.051 5 4a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1zM8.937 20.619A.983.983 0 0 1 8.039 22a1.232 1.232 0 0 1-1.126-.734L4.105 15h2.254zM16 14.6a7.723 7.723 0 0 0-5-1.6H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h7a7.723 7.723 0 0 0 5-1.595zm7.9.852a1 1 0 0 1-1.342.448l-2-1a1 1 0 0 1 .894-1.79l2 1a1 1 0 0 1 .448 1.337zm-3.79-9a1 1 0 0 1 .448-1.342l2-1a1 1 0 1 1 .894 1.79l-2 1a1 1 0 0 1-1.342-.448zM20 10a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2h-2a1 1 0 0 1-1-1z" />
      </svg>
    ),
  },
  {
    name: 'music-alt',
    network: 'music-alt',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M22.554.923A3.978 3.978 0 0 0 19.263.07l-9.184 1.722A5 5 0 0 0 6 6.706v9.85A3.959 3.959 0 0 0 4 16a4 4 0 1 0 4 4v-9.124A2 2 0 0 1 9.632 8.91l11.183-2.1A1 1 0 0 1 22 7.8v5.76a3.959 3.959 0 0 0-2-.56 4 4 0 1 0 4 4V4A3.987 3.987 0 0 0 22.554.923ZM4 22a2 2 0 1 1 2-2 2 2 0 0 1-2 2ZM20.447 4.848 9.263 6.944A4 4 0 0 0 8 7.416v-.71a3 3 0 0 1 2.447-2.949l9.185-1.722A2 2 0 0 1 22 4v.984a2.955 2.955 0 0 0-1.553-.136ZM20 19a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z" />
      </svg>
    ),
  },
  {
    name: 'paw',
    network: 'paw',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M8.164 8a2.5 2.5 0 0 1-1.579-.594 4.833 4.833 0 0 1-1.557-3.261C4.785 1.652 6.145.181 7.614.017A2.655 2.655 0 0 1 9.6.611a4.179 4.179 0 0 1 1.376 2.9C11.2 5.835 10.128 8 8.164 8Zm-.328-6c-.577.064-.921.883-.817 1.946a2.868 2.868 0 0 0 .826 1.9A.539.539 0 0 0 8.2 6c.351-.039.916-.941.783-2.3C8.641 1.834 7.861 2 7.836 2.005ZM3.164 14a2.5 2.5 0 0 1-1.579-.594 4.833 4.833 0 0 1-1.557-3.261c-.243-2.493 1.117-3.964 2.586-4.128a2.663 2.663 0 0 1 1.986.594 4.179 4.179 0 0 1 1.376 2.9C6.2 11.835 5.077 14 3.164 14Zm-.328-6c-.577.064-.921.883-.817 1.946a2.868 2.868 0 0 0 .826 1.9A.557.557 0 0 0 3.2 12c.351-.039.916-.941.783-2.3C3.718 7.872 2.861 8 2.836 8.005Zm13 0c-1.708.012-3.034-2.166-2.807-4.492a4.179 4.179 0 0 1 1.376-2.9 2.659 2.659 0 0 1 1.981-.591c1.469.164 2.829 1.635 2.586 4.128a4.833 4.833 0 0 1-1.557 3.262A2.5 2.5 0 0 1 15.836 8Zm-.816-4.3c-.133 1.355.432 2.257.783 2.3a.553.553 0 0 0 .352-.145 2.868 2.868 0 0 0 .826-1.9c.21-2.621-1.781-2.45-1.961-.255ZM20.836 14c-1.862 0-3.034-2.166-2.807-4.492a4.179 4.179 0 0 1 1.376-2.9 2.653 2.653 0 0 1 1.981-.594c1.469.164 2.829 1.635 2.586 4.128a4.833 4.833 0 0 1-1.557 3.262 2.5 2.5 0 0 1-1.579.596Zm-.816-4.3c-.133 1.355.432 2.257.783 2.3a.574.574 0 0 0 .352-.145 2.868 2.868 0 0 0 .826-1.9c.1-1.063-.24-1.882-.817-1.946C21.139 8 20.115 7.911 20.02 9.7ZM16 24a4.853 4.853 0 0 1-2.447-.606 3.332 3.332 0 0 0-3.106 0C7.434 25.083 3.922 23.227 4 19c0-4.635 4.507-9 8-9s8 4.364 8 9c0 2.944-1.645 5-4 5Zm-4-3a4.865 4.865 0 0 1 2.447.605C16.684 22.823 18.035 21.172 18 19c0-3.38-3.521-7-6-7s-6 3.62-6 7c-.032 2.169 1.308 3.823 3.553 2.605A4.865 4.865 0 0 1 12 21Z" />
      </svg>
    ),
  },
  {
    name: 'phone-call',
    network: 'phone-call',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M14.2 16.261a12.133 12.133 0 0 1-6.453-6.468l3.4-3.406L4.887.122l-3.171 3.17A5.9 5.9 0 0 0 0 7.5C0 14.748 9.252 24 16.5 24a5.889 5.889 0 0 0 4.207-1.716l3.171-3.171-6.265-6.265Zm5.092 4.609A3.9 3.9 0 0 1 16.5 22C10.267 22 2 13.733 2 7.5a3.907 3.907 0 0 1 1.13-2.793L4.887 2.95l3.437 3.437-2.935 2.935.245.614a14.372 14.372 0 0 0 8.447 8.436l.606.231 2.926-2.927 3.437 3.437ZM14 2V0a10.011 10.011 0 0 1 10 10h-2a8.009 8.009 0 0 0-8-8Zm0 4V4a6.006 6.006 0 0 1 6 6h-2a4 4 0 0 0-4-4Z" />
      </svg>
    ),
  },
  {
    name: 'pizza-slice',
    network: 'pizza-slice',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M23.2.8a2.716 2.716 0 0 0-2.9-.624L3.155 6.929a4.976 4.976 0 0 0-3 5.824 15.855 15.855 0 0 0 11.094 11.1 4.983 4.983 0 0 0 5.825-3.009l6.758-17.172A2.71 2.71 0 0 0 23.2.8ZM11.746 21.911a13.8 13.8 0 0 1-9.656-9.657 2.949 2.949 0 0 1 1.265-3.18 11.987 11.987 0 0 0 11.569 11.572 2.947 2.947 0 0 1-3.178 1.265Zm4.027-3.227c-6.191-.1-10.355-4.265-10.456-10.457l15.693-6.18a.717.717 0 0 1 .771.17.7.7 0 0 1 .181.747L21.3 4.645c-5.758-1.98-8.942 6.209-3.413 8.668Zm2.847-7.232a2.654 2.654 0 0 1 1.943-4.935Z" />
        <path d="M11 9a2 2 0 0 0 0 4 2 2 0 0 0 0-4Z" />
      </svg>
    ),
  },
  {
    name: 'sad',
    network: 'sad',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12Zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2Zm5.746 15.667a1 1 0 0 0-.08-1.413A9.454 9.454 0 0 0 12 14a9.454 9.454 0 0 0-5.666 2.254 1 1 0 0 0 1.33 1.494A7.508 7.508 0 0 1 12 16a7.51 7.51 0 0 1 4.336 1.748 1 1 0 0 0 1.41-.081ZM6 10c0 1 .895 1 2 1s2 0 2-1a2 2 0 0 0-4 0Zm8 0c0 1 .895 1 2 1s2 0 2-1a2 2 0 0 0-4 0Z" />
      </svg>
    ),
  },
  {
    name: 'shield-check',
    network: 'shield-check',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="m11.948 24.009-.354-.157C11.2 23.679 2 19.524 2 12V5.476a2.983 2.983 0 0 1 2.051-2.832L12 .009l7.949 2.635A2.983 2.983 0 0 1 22 5.476V12c0 8.577-9.288 11.755-9.684 11.887ZM12 2.106 4.684 4.532A.992.992 0 0 0 4 5.476V12c0 5.494 6.44 9.058 8.047 9.861C13.651 21.216 20 18.263 20 12V5.476a.992.992 0 0 0-.684-.944Z" />
        <path d="M11.111 14.542h-.033a1.872 1.872 0 0 1-1.345-.6l-2.306-2.4 1.441-1.382 2.244 2.34 5.181-5.181 1.414 1.414-5.261 5.261a1.873 1.873 0 0 1-1.335.548Z" />
      </svg>
    ),
  },
  {
    name: 'shopping-cart',
    network: 'shopping-cart',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M22.713 4.077A2.993 2.993 0 0 0 20.41 3H4.242L4.2 2.649A3 3 0 0 0 1.222 0H1a1 1 0 0 0 0 2h.222a1 1 0 0 1 .993.883l1.376 11.7A5 5 0 0 0 8.557 19H19a1 1 0 0 0 0-2H8.557a3 3 0 0 1-2.82-2h11.92a5 5 0 0 0 4.921-4.113l.785-4.354a2.994 2.994 0 0 0-.65-2.456ZM21.4 6.178l-.786 4.354A3 3 0 0 1 17.657 13H5.419l-.941-8H20.41a1 1 0 0 1 .99 1.178Z" />
        <circle cx={7} cy={22} r={2} />
        <circle cx={17} cy={22} r={2} />
      </svg>
    ),
  },
  {
    name: 'smile',
    network: 'smile',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12Zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2Zm5.666 13.746a1 1 0 0 0-1.33-1.494A7.508 7.508 0 0 1 12 16a7.509 7.509 0 0 1-4.334-1.746 1 1 0 0 0-1.332 1.492A9.454 9.454 0 0 0 12 18a9.454 9.454 0 0 0 5.666-2.254ZM6 10c0 1 .895 1 2 1s2 0 2-1a2 2 0 0 0-4 0Zm8 0c0 1 .895 1 2 1s2 0 2-1a2 2 0 0 0-4 0Z" />
      </svg>
    ),
  },
  {
    name: 'star',
    network: 'star',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M23.836 8.794a3.179 3.179 0 0 0-3.067-2.226H16.4l-1.327-4.136a3.227 3.227 0 0 0-6.146 0L7.6 6.568H3.231a3.227 3.227 0 0 0-1.9 5.832L4.887 15l-1.352 4.187A3.178 3.178 0 0 0 4.719 22.8a3.177 3.177 0 0 0 3.8-.019L12 20.219l3.482 2.559a3.227 3.227 0 0 0 4.983-3.591L19.113 15l3.56-2.6a3.177 3.177 0 0 0 1.163-3.606Zm-2.343 1.991-4.144 3.029a1 1 0 0 0-.362 1.116l1.575 4.87a1.227 1.227 0 0 1-1.895 1.365l-4.075-3a1 1 0 0 0-1.184 0l-4.075 3a1.227 1.227 0 0 1-1.9-1.365l1.58-4.87a1 1 0 0 0-.362-1.116l-4.144-3.029a1.227 1.227 0 0 1 .724-2.217h5.1a1 1 0 0 0 .952-.694l1.55-4.831a1.227 1.227 0 0 1 2.336 0l1.55 4.831a1 1 0 0 0 .952.694h5.1a1.227 1.227 0 0 1 .724 2.217Z" />
      </svg>
    ),
  },
  {
    name: 'time-twenty-four',
    network: 'time-twenty-four',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M17 18a3 3 0 0 0-6 0h2a1 1 0 0 1 2 0c0 .5-.85 1.138-1.6 1.7-1.125.844-2.4 1.8-2.4 3.3v1h6v-2h-3.29c.271-.236.589-.474.89-.7 1.125-.844 2.4-1.8 2.4-3.3zM22 19h-2v-4h-2v4a2 2 0 0 0 2 2h2v3h2v-9h-2zM13 6h-2v5H7v2h6z" />
        <path d="M22 2v3.374A11.995 11.995 0 1 0 9 23.605v-2.066A10 10 0 1 1 20.636 7H17v2h5a2 2 0 0 0 2-2V2z" />
      </svg>
    ),
  },
  {
    name: 'usd-circle',
    network: 'usd-circle',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0Zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10Zm4-8c0 1.654-1.346 3-3 3v1a1 1 0 1 1-2 0v-1h-.268a3.01 3.01 0 0 1-2.598-1.499 1 1 0 1 1 1.73-1.002c.179.31.511.501.867.501h2.268a1 1 0 0 0 1-1 .768.768 0 0 0-.644-.76l-3.041-.507A2.761 2.761 0 0 1 7.999 10c0-1.654 1.346-3 3-3V6a1 1 0 1 1 2 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 1 1-1.73 1.001 1.004 1.004 0 0 0-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0 1 16 14.001Z" />
      </svg>
    ),
  },
  {
    name: 'users',
    network: 'users',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M7.5 13A4.5 4.5 0 1 1 12 8.5 4.505 4.505 0 0 1 7.5 13zm0-7A2.5 2.5 0 1 0 10 8.5 2.5 2.5 0 0 0 7.5 6zM15 23v-.5a7.5 7.5 0 0 0-15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0-11.667-5.217 1 1 0 1 0 1.334 1.49A5 5 0 0 1 22 18a1 1 0 0 0 2 0zm-6.5-9A4.5 4.5 0 1 1 22 4.5 4.505 4.505 0 0 1 17.5 9zm0-7A2.5 2.5 0 1 0 20 4.5 2.5 2.5 0 0 0 17.5 2z" />
      </svg>
    ),
  },
  {
    name: 'wrench-simple',
    network: 'wrench-simple',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#000000" viewBox="0 0 24 24">
        <path d="M15 24a1 1 0 0 1-1-1v-1.398c0-1.505.805-2.853 2.1-3.516a8.971 8.971 0 0 0 4.9-8.013 8.95 8.95 0 0 0-5.03-8.078L16 8c0 2.206-1.794 4-4 4s-4-1.794-4-4V2.012c-3.072 1.511-5 4.606-5 8.061a8.971 8.971 0 0 0 4.9 8.013c1.295.663 2.1 2.011 2.1 3.516V23a1 1 0 1 1-2 0v-1.398c0-.739-.397-1.421-1.012-1.736A10.963 10.963 0 0 1 1 10.074C1 5.852 3.355 2.068 7.146.2c.61-.3 1.321-.263 1.901.101.597.371.953 1.011.953 1.711V8c0 1.103.897 2 2 2s2-.897 2-2V2.012c0-.7.356-1.34.953-1.711a1.951 1.951 0 0 1 1.9-.101 10.94 10.94 0 0 1 6.146 9.873c0 4.146-2.295 7.898-5.988 9.792-.614.315-1.012.997-1.012 1.736v1.398a1 1 0 0 1-1 1Z" />
      </svg>
    ),
  },
]
