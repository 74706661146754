import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import { formatToNumberWithCommas, linkTransformer } from 'helpers'
import { radius, space, breakpoints } from 'theme'
import {
  DEFAULT_VIDEO_THUMBNAIL,
  VIMEO,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
  DIRECTION_RIGHT_TO_LEFT,
  DIRECTION_LEFT_TO_RIGHT,
} from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import HtmlCollapse from 'components/molecules/HtmlCollapse'
import Link from 'components/atoms/Link'
import Input from 'components/atoms/Input'
import Counter from 'components/molecules/Counter'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import {
  NETWORK_LIMITS,
  POST_VIDEO,
  POST_LINK,
  POST_MIXED_MEDIA,
  VIMEO_COLORS,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import { transformTextVariantsToUnicodeVariant, variableTransformer } from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const StyledFlex = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const SourceWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-color: rgb(204, 214, 221);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledIconLink = styled(Icon.Link)`
  fill: ${({ fill }) => fill};
`

const StyledFlexStatistics = styled(Flex)`
  border-top: 0.0625rem solid #e3e8e9;
`

const StyledCommentWrapper = styled(Flex)`
  border-bottom: 1px solid #e3e8e9;
  width: 100%;
  white-space: pre-wrap;
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === VIMEO)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  videoTitleLimit: MAX_VIDEO_TITLE_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const VimeoPost = ({
  user,
  onChangeUpdatePost,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  reminderNotificationsList,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  theme,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const { isRTL } = theme || {}
  const TEXT_DIRECTION = isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile
  const {
    publish_at,
    postText,
    postComponent,
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
    insights = {},
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { url } = link || {}
  const { published_link } = result || {}

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let video_title
  let comments = []
  let device_gid = null
  let email_user_gid = null
  let publishing_option = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      video_title,
      comments = [],
      device_gid = null,
      email_user_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      commentTextMaxLimit,
      notificationReminderLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { variableTransformedText, transformed_text_to_html_for_textarea } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: variableTransformedText,
      color: VIMEO_COLORS.LAST_LIGHT_BLUE,
      transformHashstag: true,
      hashtagColor: VIMEO_COLORS.LAST_LIGHT_BLUE,
    })

    return { variableTransformedText, transformed_text_to_html_for_textarea }
  }, [updatedPostText, savedVariables])

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_views = 0
  let c_likes = 0
  let c_comments = 0
  let c_collection = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({
      views: c_views = 0,
      likes: c_likes = 0,
      comments: c_comments = 0,
      collection: c_collection = 0,
    } = profileInsights)
  }

  c_comments = c_comments || comments.filter(({ message }) => message).length

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (e) {
      urlHost = url
    }
  }

  let updatedPostMedia = null

  if (customizedPostComponent === POST_VIDEO) {
    if (customizedPostVideos.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      updatedPostMedia = customizedPostVideos[0]
    }
  } else if (customizedPostComponent === POST_MIXED_MEDIA) {
    if (customizedPostMixedMedias.length !== 0) {
      updatedPostMedia = customizedPostMixedMedias.find(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
    }
  }

  const { url: videoUrl, thumbnail_url, isNew, id: videoId, title, type } = updatedPostMedia || {}

  let videoThumbnailUrl = ''
  if (typeof isNew === 'undefined' && thumbnail_url) {
    videoThumbnailUrl = thumbnail_url
  }

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={customizedPostMixedMedias}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_post_preview' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
              >
                {(customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) &&
                updatedPostMedia &&
                isPostFormDirty ? (
                  <Box bg="white">
                    {(videoUrl || thumbnail_url || type === VIDEO_DYNAMIC) && (
                      <SourceWrapper>
                        {noVideoAttachment ? (
                          <Flex justifyContent="center" alignItems="center" position="relative">
                            {type === VIDEO && (
                              <Fragment>
                                <Box position="absolute" top={space.xs} right={space.xs}>
                                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                                </Box>
                                <StyledVideoThumbnailImage
                                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                                  height="100%"
                                  width="100%"
                                  maxHeight="17.5em"
                                  source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                                  fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                                  name={videoId}
                                />
                              </Fragment>
                            )}
                            {type === VIDEO_DYNAMIC && (
                              <Flex
                                width={isEditable ? '250px' : '560px'}
                                height={isEditable ? '125px' : '280px'}
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                              >
                                <DynamicMedia
                                  media={updatedPostMedia}
                                  post={post}
                                  profiles={[profile]}
                                  verticalPreview
                                  isSmall={smallPreview}
                                />
                              </Flex>
                            )}
                          </Flex>
                        ) : (
                          <StyledBox position="relative" width="100%" justifyContent="center" hasError={postVideoError}>
                            {isNew ? (
                              <Box className="cliploader-wrapper">
                                <ClipLoader size="50" />
                              </Box>
                            ) : (
                              <Fragment>
                                {type === VIDEO && (
                                  <VideoWithFallback
                                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                                    url={videoUrl}
                                    controls
                                    width="100%"
                                    style={{ position: 'relative' }}
                                    height="18.75em"
                                    config={{
                                      file: {
                                        attributes: {
                                          poster: videoThumbnailUrl,
                                        },
                                      },
                                    }}
                                    name={videoId}
                                  />
                                )}
                                {type === VIDEO_DYNAMIC && (
                                  <Flex
                                    width="100%"
                                    height="18.75em"
                                    justifyContent="center"
                                    alignItems="center"
                                    mx="auto"
                                  >
                                    <DynamicMedia
                                      media={updatedPostMedia}
                                      post={post}
                                      profiles={[profile]}
                                      verticalPreview
                                      isSmall={smallPreview}
                                    />
                                  </Flex>
                                )}
                              </Fragment>
                            )}
                          </StyledBox>
                        )}
                      </SourceWrapper>
                    )}
                  </Box>
                ) : (
                  <Box px="0.5em" />
                )}
                <Flex flexDirection="column" px="1em" bg="white" pb="1em">
                  {video_title && (
                    <Text color={VIMEO_COLORS.GLUON_GREY} fontSize="2.25em" dir={TEXT_DIRECTION}>
                      {video_title}
                    </Text>
                  )}
                  <StyledLink
                    pb="0.5em"
                    fontSize="0.875em"
                    color={VIMEO_COLORS.TARNISHED_SILVER}
                    as={published_link ? 'a' : 'p'}
                    href={published_link || ''}
                    target="_blank"
                    dir={TEXT_DIRECTION}
                  >
                    {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'}
                  </StyledLink>
                  <Flex>
                    {isEditable ? (
                      <StyledImg
                        source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage}
                        fallbackSource={fallbackLogoImage}
                        width="2em"
                        height="2em"
                      />
                    ) : (
                      <SocialProfileImage
                        code={network}
                        picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                        width="2em"
                        height="2em"
                      />
                    )}
                    <Flex ml="0.5em" flexDirection="column" justifyContent="center">
                      <Flex
                        alignItems="center"
                        onMouseEnter={(e) => {
                          if (!isMultipleProfilesPreview) {
                            tooltipRef.current.handleShowTooltip({
                              contentComp: (
                                <ProfileItemTooltip
                                  profile={{
                                    code: network,
                                    picture_url: profilePictureUrl,
                                    profile_url: profileUrl,
                                    name: profileName,
                                    username: profileUsername,
                                    display,
                                    profile_id,
                                    type: profile_type,
                                  }}
                                  message=""
                                />
                              ),
                              wrapperComp: e.currentTarget,
                            })
                          }
                        }}
                        onMouseLeave={() => {
                          tooltipRef.current.handleHideTooltip()
                        }}
                      >
                        <StyledLink
                          as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                          href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                          target="_blank"
                          color={VIMEO_COLORS.GLUON_GREY}
                          fontWeight="bold"
                          fontSize="0.875em"
                        >
                          {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                        </StyledLink>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex px="1em" width="100%" flexDirection="column" bg="white">
                  <StyledFlexStatistics py="1em" px="1em">
                    <Box mr="0.125em">
                      <Image
                        width="1.125em"
                        height="1.125em"
                        src="/assets/vimeo_view.svg"
                        alt="Views"
                        mr="0.5em"
                        mt="-0.1875em"
                      />
                      <Text fontSize="1em" as="span" color={VIMEO_COLORS.GLUON_GREY}>
                        {formatToNumberWithCommas(c_views)}
                      </Text>
                    </Box>
                    <Box ml="1em" mr="0.125em">
                      <Image width="1.125em" height="1.125em" src="/assets/vimeo_like.svg" alt="Likes" mr="0.5em" />
                      <Text fontSize="1em" as="span" color={VIMEO_COLORS.GLUON_GREY}>
                        {formatToNumberWithCommas(c_likes)}
                      </Text>
                    </Box>
                    <Box ml="1em" mr="0.125em">
                      <Image
                        width="1.125em"
                        height="1.125em"
                        src="/assets/vimeo_collection.svg"
                        alt="Collectionc"
                        mr="0.5em"
                      />
                      <Text fontSize="1em" as="span" color={VIMEO_COLORS.GLUON_GREY}>
                        {formatToNumberWithCommas(c_collection)}
                      </Text>
                    </Box>
                    <Box ml="1em">
                      <Image
                        width="1.125em"
                        height="1.125em"
                        src="/assets/vimeo_comment.svg"
                        alt="Comments"
                        mr="0.5em"
                      />
                      <Text fontSize="1em" as="span" color={VIMEO_COLORS.GLUON_GREY}>
                        {formatToNumberWithCommas(c_comments)}
                      </Text>
                    </Box>
                  </StyledFlexStatistics>
                  {isPostFormDirty ? (
                    <Flex
                      flexDirection="column"
                      width="100%"
                      onClick={() => {
                        if (published_link) {
                          window.open(published_link)
                        }
                      }}
                    >
                      {transformed_text_to_html_for_textarea ? (
                        <Box width="100%" pl="1em">
                          <HtmlCollapse
                            html={transformed_text_to_html_for_textarea}
                            maxLength={150}
                            color={VIMEO_COLORS.GLUON_GREY}
                            mt="0.5em"
                            pb="1em"
                            fontSize="0.875em"
                            whiteSpace="pre-line"
                            title="Post"
                            dir={TEXT_DIRECTION}
                          />
                        </Box>
                      ) : (
                        <Box mt="0.5em" pb="1em" />
                      )}
                      {customizedPostComponent === POST_LINK && urlHost && (
                        <Flex mb="0.5em" alignItems="center">
                          <StyledIconLink width="0.75em" height="0.75em" fill={VIMEO_COLORS.LAST_LIGHT_BLUE} />
                          <Link
                            ml="0.25em"
                            as="a"
                            href={url}
                            target="_blank"
                            rel="noreferrer noopener"
                            color={VIMEO_COLORS.LAST_LIGHT_BLUE}
                          >
                            {urlHost}
                          </Link>
                        </Flex>
                      )}
                    </Flex>
                  ) : (
                    <Box mt="m" px="s" mb="s" height="100%">
                      <NoPost />
                    </Box>
                  )}
                  <Text fontSize="1em" color={VIMEO_COLORS.GLUON_GREY}>
                    {`${formatToNumberWithCommas(c_comments)} ${c_comments === 1 ? 'Comment' : 'Comments'}`}
                  </Text>
                  <Flex flexDirection="column">
                    {comments.map(({ message }, index) => {
                      if (!message) {
                        return null
                      }

                      const { variableTransformedText } = variableTransformer({
                        str: message,
                        savedVariables,
                        post,
                        profileId,
                      })
                      return (
                        <Flex py="1.25em" key={index}>
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width={{ mobile: '1em', tablet: '2.5em' }}
                              height={{ mobile: '1em', tablet: '2.5em' }}
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width={{ mobile: '1em', tablet: '2.5em' }}
                              height={{ mobile: '1em', tablet: '2.5em' }}
                              imageBorderRadius={radius.pill}
                            />
                          )}
                          <StyledCommentWrapper ml="1em" flexDirection="column" justifyContent="center">
                            <Flex>
                              <StyledLink
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                color={VIMEO_COLORS.GLUON_GREY}
                                fontWeight="bold"
                                fontSize="0.875em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                              </StyledLink>
                            </Flex>
                            <Text
                              mt="0.25em"
                              color={VIMEO_COLORS.GLUON_GREY}
                              fontSize="0.875em"
                              dangerouslySetInnerHTML={{
                                __html: variableTransformedText,
                              }}
                              dir={TEXT_DIRECTION}
                            />
                            <Flex py="0.5em" alignItems="center" justifyContent="flex-end">
                              <Text
                                fontSize="0.75em"
                                fontWeight="medium"
                                as="span"
                                color={VIMEO_COLORS.TARNISHED_SILVER}
                              >
                                Reply
                              </Text>
                            </Flex>
                          </StyledCommentWrapper>
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder={isRTL ? '... Write your content' : 'Write your content ...'}
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={variableTransformedText.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <HtmlCollapse
                                html={transformed_text_to_html_for_textarea || 'Click to edit caption'}
                                maxLength={150}
                                color={
                                  transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'
                                }
                                fontSize="xs"
                                whiteSpace="pre-line"
                                title="Post"
                                dir={TEXT_DIRECTION}
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            showImageComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_VIDEO ||
                                customizedPostComponent === POST_MIXED_MEDIA) &&
                              updatedPostMedia
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <Flex mt="m" alignItems="center" width="100%">
                    <Flex flexDirection="column" width="100%">
                      <Input
                        placeholder="Please enter a video title"
                        label="Video title *"
                        defaultValue={video_title || title || ''}
                        name="video_title"
                        id="video_title"
                        onChange={(e) => {
                          const { value } = e.target
                          if (value.length <= MAX_VIDEO_TITLE_LENGTH) {
                            handleUpdateCustomizationDebounce({ type: 'video_title', value })
                          }
                        }}
                        error={!video_title || video_title.length === 0 ? 'Video title is required' : ''}
                        width="100%"
                        maxLength={MAX_VIDEO_TITLE_LENGTH}
                      />
                    </Flex>
                    <TooltipPromptComponent
                      prompt_key="video_title"
                      network={VIMEO}
                      mt={!video_title || video_title.length === 0 ? '-18px' : '0'}
                    />
                  </Flex>

                  <PublicationOptionComponent
                    handleUpdateCustomization={handleUpdateCustomization}
                    publishing_option={publishing_option}
                    device_gid={device_gid}
                    email_user_gid={email_user_gid}
                    reminderNotificationsList={reminderNotificationsList}
                    notificationReminderLengthLimit={notificationReminderLengthLimit}
                    hasMobileNotificationOption={hasMobileNotificationOption}
                    network={network}
                  />

                  <Flex mt="m" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}

                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CommentsComponent
                            user={user}
                            handleUpdateCustomization={handleUpdateCustomization}
                            handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                            network={VIMEO}
                            profileId={profileId}
                            post={post}
                            commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                            comments={comments}
                            commentTextMaxLimit={commentTextMaxLimit}
                            selectedEntities={selectedEntities}
                            selectedProfiles={selectedProfiles}
                            textVariantOffsets={textVariantOffsets}
                            hasPreview={hasPreview}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments" />
                      </Flex>
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

VimeoPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  reminderNotificationsList: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

VimeoPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  postErrors: PropTypes.object,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  reminderNotificationsList: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(VimeoPost)
