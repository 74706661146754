import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { IMAGE, VIDEO } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import PromptComponent from 'components/atoms/PromptComponent'
import DropDown from 'shared/DropDown'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483002;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const DEFAULT_IMAGE_QUALITY_OPTION = { value: 80, label: '80' }
const DEFAULT_VIDEO_QUALITY_OPTION = { value: 'horizontal_480p', label: 'Horizontal 480p' }

const IMAGE_QUALITY_OPTIONS = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 70, label: '70' },
  DEFAULT_IMAGE_QUALITY_OPTION,
  { value: 90, label: '90' },
  { value: 100, label: '100' },
]

const VIDEO_QUALITY_OPTIONS = [
  DEFAULT_VIDEO_QUALITY_OPTION,
  { value: 'horizontal_720p', label: 'Horizontal 720p' },
  { value: 'horizontal_1080p', label: 'Horizontal 1080p' },
  { value: 'horizontal_4k', label: 'Horizontal 4k' },
  { value: 'vertical_480p', label: 'Vertical 480p' },
  { value: 'vertical_720p', label: 'Vertical 720p' },
  { value: 'vertical_1080p', label: 'Vertical 1080p' },
  { value: 'vertical_4k', label: 'Vertical 4k' },
]

const IMAGE_PROMPT_TEXT =
  'The quality of the exported image determines how compressed the exported file should be. A low quality value (minimum 10) will create a file with a smaller file size, but the resulting file will have pixelated artifacts when compared to a file created with a high quality value (maximum 100)'

const VIDEO_PROMPT_TEXT =
  'The quality is the orientation and resolution of the exported video. Orientation is either horizontal or vertical, and resolution is one of 480p, 720p, 1080p or 4k.'

const ExportConfigurationModal = ({ isOpen, handleDismiss, type, handleClickNextStep }) => {
  const [exportQuality, setExportQuality] = useState(null)
  const [options, setOptions] = useState([])
  const [promptText, setPromptText] = useState('')

  useEffect(() => {
    if (isOpen) {
      if (type.includes(IMAGE)) {
        setExportQuality({ ...DEFAULT_IMAGE_QUALITY_OPTION })
        setOptions([...IMAGE_QUALITY_OPTIONS])
        setPromptText(IMAGE_PROMPT_TEXT)
      } else if (type.includes(VIDEO)) {
        setExportQuality({ ...DEFAULT_VIDEO_QUALITY_OPTION })
        setOptions([...VIDEO_QUALITY_OPTIONS])
        setPromptText(VIDEO_PROMPT_TEXT)
      }
    }
  }, [isOpen])

  const handleClickContinue = () => {
    handleClickNextStep({ quality: exportQuality.value })
    handleDismiss()
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Export configuration</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" p="m">
            <Flex flexDirection="column">
              <DropDown
                placeholder=""
                onChange={(option) => {
                  setExportQuality(option)
                }}
                options={options}
                value={exportQuality}
                width="100%"
                isSearchable={false}
              />
            </Flex>

            <Flex alignItems="center" mt="m">
              <Text color="secondaryText" fontSize="xs">
                {promptText}
              </Text>
            </Flex>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>

            <Button.Gradient onClick={handleClickContinue} isSmall>
              <Text fontWeight="medium">Continue</Text>
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ExportConfigurationModal.defaultProps = {}

ExportConfigurationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClickNextStep: PropTypes.func.isRequired,
}

export default ExportConfigurationModal
