import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius } from 'theme'
import { DEFAULT_SOCIAL_PROFILE_SOURCE, YOUTUBE } from 'consts'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const ProfileImage = styled(ImageWithFallback)`
  border-radius: ${({ imageBorderRadius }) => imageBorderRadius};
  vertical-align: inherit;
`

const NetworkImageWrapper = styled(Flex)`
  position: absolute;
  bottom: -4px;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${radius.pill};
  cursor: pointer;
`

const SocialProfileImage = ({
  picture_url,
  code,
  width,
  height,
  iconWidth,
  iconHeight,
  imageBorderRadius,
  id,
  fallbackSource,
  custom_network_picture_url,
}) => {
  const networkImage = getSocialNetworkIconByName(code, custom_network_picture_url).enabled

  let source = picture_url || fallbackSource || DEFAULT_SOCIAL_PROFILE_SOURCE

  let showIcon = true
  if (code === YOUTUBE && process.env.NEXT_PUBLIC_YOUTUBE_AUDIT === 'yes') {
    source = networkImage
    showIcon = false
  }
  return (
    <StyledFlex width={width} height={height} justifyContent="center" alignItems="center" position="relative">
      <ProfileImage
        width={width}
        height={height}
        source={source}
        fallbackSource={fallbackSource || DEFAULT_SOCIAL_PROFILE_SOURCE}
        imageBorderRadius={imageBorderRadius}
        id={id}
      />
      {showIcon && (
        <NetworkImageWrapper justifyContent="center" alignItems="center">
          <Image src={networkImage} width="12px" height="12px" />
        </NetworkImageWrapper>
      )}
    </StyledFlex>
  )
}

SocialProfileImage.defaultProps = {
  picture_url: '',
  width: '24px',
  height: '24px',
  iconHeight: '12px',
  iconWidth: '12px',
  imageBorderRadius: radius.m,
  code: '',
  fallbackSource: DEFAULT_SOCIAL_PROFILE_SOURCE,
  custom_network_picture_url: null,
}

SocialProfileImage.propTypes = {
  picture_url: PropTypes.string,
  code: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  imageBorderRadius: PropTypes.string,
  fallbackSource: PropTypes.string,
  custom_network_picture_url: PropTypes.string,
}

export default SocialProfileImage
