import { SUPPORTED_SOCIAL_NETWORKS_MAJOR, SUPPORTED_SOCIAL_NETWORKS_MINOR } from 'consts'
import { POST_TYPE_EXTERNAL_CALENDAR_EVENT } from '../routes/Calendar/consts'

const getSocialNetworkIconByName = (name, custom_network_picture_url) => {
  if (!name) {
    return {
      enabled: `/assets/cross_channel.svg`,
      disabled: `/assets/cross_channel.svg`,
    }
  }

  if (custom_network_picture_url) {
    return { enabled: custom_network_picture_url, disabled: '/assets/cross_channel.svg' }
  }

  return {
    enabled: `/assets/${name.toLowerCase()}.svg`,
    disabled: `/assets/${name.toLowerCase()}_disabled.svg`,
  }
}

const getSpecialPostsIconByType = (type) => {
  if (type) {
    if (type === 'fun') {
      return '/assets/gift.svg'
    } else if (type === POST_TYPE_EXTERNAL_CALENDAR_EVENT) {
      return `/assets/calendar_gray.svg`
    } else {
      return `/assets/special_events/${type}.svg`
    }
  }
  return `/assets/calendar_gray.svg`
}

const getSocialNetworkNames = (socialNetworks) => socialNetworks.map(({ network: { name } }) => name)

const filterMajorSocialNetworks = (networks) =>
  networks.filter(({ name }) => SUPPORTED_SOCIAL_NETWORKS_MAJOR.includes(name))

const filterMinorSocialNetworks = (networks) =>
  networks.filter(({ name }) => SUPPORTED_SOCIAL_NETWORKS_MINOR.includes(name))

export {
  getSocialNetworkIconByName,
  getSpecialPostsIconByType,
  getSocialNetworkNames,
  filterMajorSocialNetworks,
  filterMinorSocialNetworks,
}
