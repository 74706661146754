import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { transparentize } from 'polished'
import { space as styledSpace, width } from 'styled-system'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, fontSizes, fontWeights, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  FEATURE_FIND_CONTENT,
  PLAN_UPGRADE,
  IMAGE,
  VIDEO,
  AUDIO,
  PERMISSION_PUBLISH,
  DIRECTION_RIGHT_TO_LEFT,
  DIRECTION_LEFT_TO_RIGHT,
} from 'consts'
import { isValidInstagramHashtag, isValidInstagramUsername, pxToRem } from 'helpers'
import request from 'utils/request'
import { checkIfPlanHasFeatureEnabled, hasGlobalAccessManage } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import NoData from 'components/molecules/NoData'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Radio from 'components/atoms/Radio'
import ArticleItem from './ArticleItem'
import {
  POST_LINK,
  POST_IMAGE,
  POST_VIDEO,
  ROUTE_CONTENT,
  IMPORT_FROM_SEARCH_CONTENT,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
  IMPORT_FROM_INSTAGRAM_USER,
  IMPORT_FROM_FIND_IMAGES,
  IMPORT_FROM_FIND_GIFS,
  IMPORT_FROM_FIND_VIDEOS,
  INSTAGRAM_REG_EXP,
  ROUTE_MEDIA,
  TABS_TYPE_IMAGE_UNSPLASH,
  TABS_TYPE_IMAGE_PEXELS,
  TABS_TYPE_IMAGE_GIPHY,
  TABS_TYPE_VIDEO_PEXELS,
} from '../../consts'
import MediaSettings from '../../../Media/MediaSettings'
import MediaItem from './MediaItem'
import MediaDetailModal from './MediaDetailModal'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const StyledMenuItemWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledImportText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.colors.secondaryText};
`

const StyledLearnMore = styled(Text)`
  text-decoration: none;
`

const StyledTabsWrapper = styled(Flex)`
  border-radius: ${radius.l};
  width: 210px;
  justify-content: center;
`

const StyledSearchWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  width: 100%;
  min-height: 230px;
  background-image: url(${({ searchBackground }) => searchBackground});
  background-color: #dce4ec;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${radius.l} ${radius.l} 0 0;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.33);
  }
`

const StyledInput = styled.input`
  padding-top: ${space.m};
  padding-bottom: ${space.m};
  padding-right: ${space.m};
  border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.error : theme.colors.border_color)};
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
  height: ${pxToRem(30)};
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: ${fontSizes.xs};
  :focus {
    outline-style: none;
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
  ${styledSpace};
  ${width};
`

const StyledIconSearch = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledIconSearching = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ${space.xs};
  cursor: pointer;
`

const MENU_ITEMS = [
  {
    title: 'Find latest news',
    description: 'Find great news articles based on topics your audience might be interested in',
    imageComponent: <Icon.News width="20px" height="20px" fill="icon_color_gray" />,
    learnMoreLink: 'https://support.vistasocial.com/hc/en-us/articles/4411191874331-Finding-great-social-media-content',
    buttonText: 'Find news',
    type: IMPORT_FROM_SEARCH_CONTENT,
    searchInputPlaceholder: 'Enter company, product or keyword',
    searchButtonText: 'Find content',
    noDataFoundText: 'No articles found. Please try again',
    searchBackground: '/assets/find_content/background_1.webp',
  },
  {
    title: 'Instagram hashtags',
    description: 'Find trending content based on an Instagram hashtag',
    imageComponent: <Icon.HashTag width="20px" height="20px" fill="icon_color_gray" />,
    learnMoreLink: '',
    buttonText: 'Hashtag search',
    type: IMPORT_FROM_INSTAGRAM_HASHTAG,
    searchInputPlaceholder: 'Enter hashtag',
    searchButtonText: 'Find posts',
    noDataFoundText: 'No posts found. Please try again',
    searchBackground: '/assets/find_content/background_2.webp',
  },
  {
    title: 'Instagram users',
    description: 'Find content by a specific Instagram user',
    imageComponent: <Image width="20px" height="20px" src="/assets/avatar.svg" />,
    learnMoreLink: '',
    buttonText: 'User search',
    type: IMPORT_FROM_INSTAGRAM_USER,
    searchInputPlaceholder: 'Enter username',
    searchButtonText: 'Find posts',
    noDataFoundText: 'No posts found. Please try again',
    searchBackground: '/assets/find_content/background_2.webp',
  },
  {
    title: 'Find images',
    description: 'Find royalty-free images from Unsplash and Pexels',
    imageComponent: <Icon.Camera width="20px" height="20px" fill="icon_color_gray" />,
    learnMoreLink: '',
    buttonText: 'Search images',
    type: IMPORT_FROM_FIND_IMAGES,
    searchInputPlaceholder: 'Search for images',
    searchButtonText: 'Find images',
    noDataFoundText: 'No images found. Please try again',
    searchBackground: '/assets/find_content/background_4.webp',
  },
  {
    title: 'Find GIFs',
    description: 'Find animated GIFs from Giphy',
    imageComponent: <Icon.GIF width="20px" height="20px" fill="icon_color_gray" />,
    learnMoreLink: '',
    buttonText: 'Search GIFs',
    type: IMPORT_FROM_FIND_GIFS,
    searchInputPlaceholder: `Search for GIFs`,
    searchButtonText: 'Find GIFs',
    noDataFoundText: 'No GIFs found. Please try again',
    searchBackground: '/assets/find_content/background_5.webp',
  },
  {
    title: 'Find videos',
    description: 'Find royalty-free videos from Pexels',
    imageComponent: <Icon.VideoCamera width="20px" height="20px" fill="icon_color_gray" />,
    learnMoreLink: '',
    buttonText: 'Search videos',
    type: IMPORT_FROM_FIND_VIDEOS,
    searchInputPlaceholder: `Search for videos`,
    searchButtonText: 'Find videos',
    noDataFoundText: 'No videos found. Please try again',
    searchBackground: '/assets/find_content/background_6.webp',
  },
]

const IMPORT_FROM_FIND_IMAGES_TABS = [
  {
    name: 'Unsplash',
    system: TABS_TYPE_IMAGE_UNSPLASH,
    placeholder: 'Search for images on Unsplash',
  },
  {
    name: 'Pexels',
    system: TABS_TYPE_IMAGE_PEXELS,
    placeholder: 'Search for images on Pexels',
  },
]

const MEDIA_SETTINGS_MODAL_OPTIONS = {
  isOpen: false,
  uploadedMedias: [],
}

const MEDIA_DETAIL_MODAL_OPTIONS = {
  isOpen: false,
  data: { index: -1 },
}

const FindContentModal = ({
  user,
  isOpen,
  handleDismiss,
  handleClickSendToCompose,
  handleModalSuccessCallback,
  menuItemType,
  mediaType,
  folderPath,
  theme,
}) => {
  const searchInputRef = useRef(null)

  const [step, setStep] = useState(1)
  const [importFrom, setImportFrom] = useState(null)
  const [isContentFinding, setIsContentFinding] = useState(false)
  const [noArticlesFound, setNoArticlesFound] = useState(false)
  const [articles, setArticles] = useState([])
  const [featuresEnabled, setFeaturesEnabled] = useState({ [FEATURE_FIND_CONTENT]: { enabled: true } })
  const [error, setError] = useState('')
  const [activeTab, setActiveTab] = useState(null)
  const [mediaSettingsOptions, setMediaSettingsOptions] = useState({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  const [mediaDetailModalOptions, setMediaDetailOptions] = useState({ ...MEDIA_DETAIL_MODAL_OPTIONS })
  const [isBulkMediaUploading, setIsBulkMediaUploading] = useState(false)

  const canPublish = hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH })

  const { isRTL } = theme || {}

  let allMediasSelected = false
  let numberOfSelectedMedias = 0

  articles.forEach(({ selected }) => {
    if (selected) {
      numberOfSelectedMedias++
    }
  })

  if (numberOfSelectedMedias === articles.length) {
    allMediasSelected = true
  }

  useEffect(() => {
    if (isOpen) {
      const updatedFeatures = checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_FIND_CONTENT] })
      setFeaturesEnabled(updatedFeatures)

      if (updatedFeatures[FEATURE_FIND_CONTENT].enabled && menuItemType) {
        const foundMenuItem = MENU_ITEMS.find(({ type }) => type === menuItemType)
        if (foundMenuItem) {
          // eslint-disable-next-line no-use-before-define
          handleClickSelectMenu({ item: foundMenuItem })
        } else {
          setStep(1)
        }
      } else {
        setStep(1)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (
      activeTab &&
      (activeTab === TABS_TYPE_IMAGE_UNSPLASH ||
        activeTab === TABS_TYPE_IMAGE_GIPHY ||
        activeTab === TABS_TYPE_VIDEO_PEXELS)
    ) {
      // eslint-disable-next-line no-use-before-define
      handleClickDiscoverMedia()
    }
  }, [activeTab])

  const handleClickSelectMenu = ({ item }) => {
    setImportFrom(item)
    setError('')
    setArticles([])
    setIsContentFinding(false)
    setNoArticlesFound(false)
    if (item.type === IMPORT_FROM_FIND_IMAGES) {
      setActiveTab(TABS_TYPE_IMAGE_UNSPLASH)
    } else if (item.type === IMPORT_FROM_FIND_GIFS) {
      setActiveTab(TABS_TYPE_IMAGE_GIPHY)
    } else if (item.type === IMPORT_FROM_FIND_VIDEOS) {
      setActiveTab(TABS_TYPE_VIDEO_PEXELS)
    } else {
      setActiveTab(null)
    }
    setStep(2)
  }

  const handleClickFindLatestNews = async () => {
    const { value = '' } = searchInputRef.current

    if (value.length !== 0) {
      setNoArticlesFound(false)
      setIsContentFinding(true)
      try {
        const response = await request({
          path: `${ROUTE_CONTENT}?q=${value}&from=find_content`,
        })
        handleModalSuccessCallback()

        const { error } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setNoArticlesFound(true)
        } else if (response.length === 0) {
          setNoArticlesFound(true)
        } else {
          setNoArticlesFound(false)
          setArticles(
            response.map((item) => ({ tempId: new Date().getTime() + Math.floor(Math.random() * 1000), ...item }))
          )
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: FindContentModal.displayName,
          functionName: 'handleClickFindLatestNews',
          errorMessage: `Can't find any articles. Please contact support.`,
        })
        setNoArticlesFound(true)
      } finally {
        setIsContentFinding(false)
      }
    }
  }

  const handleClickFindInstagramPosts = async () => {
    const { value = '' } = searchInputRef.current

    let error = ''

    const updatedValue = value.replace(INSTAGRAM_REG_EXP, '').trim()

    if (updatedValue) {
      setIsContentFinding(true)
      try {
        let path = ''

        let type = 'any'

        if (mediaType) {
          type = mediaType === AUDIO ? VIDEO : mediaType
        }

        if (importFrom.type === IMPORT_FROM_INSTAGRAM_HASHTAG) {
          if (!isValidInstagramHashtag(`#${updatedValue}`)) {
            error = `Please enter a valid hashtag`
          }
          path = `instagram/hashtag/recent?q=${updatedValue}&type=${type}&from=find_content`
        } else if (importFrom.type === IMPORT_FROM_INSTAGRAM_USER) {
          if (!isValidInstagramUsername(`@${updatedValue}`)) {
            error = `Please enter a valid Instagram username`
          }
          path = `instagram/user/recent?q=${updatedValue}&type=${type}&from=find_content`
        }

        if (!error && path) {
          const response = await request({
            path,
          })

          const { error: responseError, data = [] } = response

          handleModalSuccessCallback()
          if (!response || responseError) {
            error = responseError || ERROR_MESSAGE
            setNoArticlesFound(true)
          } else if (data.length === 0) {
            setNoArticlesFound(true)
          } else {
            setNoArticlesFound(false)
            setArticles(
              data.map(
                ({ description, url, link, published, username, media_type, username_link, username_picture_url }) => ({
                  tempId: new Date().getTime() + Math.floor(Math.random() * 1000),
                  image: url,
                  title: '',
                  description,
                  source: { source: username || 'Instagram post', username_link, username_picture_url },
                  url: link,
                  published,
                  username,
                  media_type,
                })
              )
            )
          }
        }
      } catch (e) {
        errorHelper({
          error: e,
          componentName: FindContentModal.displayName,
          functionName: 'handleClickFindInstagramPosts',
          showAlert: false,
        })
        error = `Can't find any posts. Please try again.`
        setArticles([])
        setNoArticlesFound(true)
      } finally {
        setIsContentFinding(false)
      }
    } else {
      error = 'Please enter search criteria'
    }
    setError(error)
  }

  const handleClickDiscoverMedia = async () => {
    const { value = '' } = searchInputRef.current

    setNoArticlesFound(false)
    setIsContentFinding(true)
    try {
      let searchType = IMAGE

      if (importFrom.type === IMPORT_FROM_FIND_VIDEOS) {
        searchType = VIDEO
      }

      const response = await request({
        path: `${ROUTE_MEDIA}/search?q=${value}&system=${activeTab}&type=${searchType}&from=find_content`,
      })

      handleModalSuccessCallback()

      const { error, data = [] } = response || {}
      console.log(response)
      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setNoArticlesFound(true)
      } else if (data.length === 0) {
        setNoArticlesFound(true)
      } else {
        setNoArticlesFound(false)
        setArticles(
          data.map(({ url, description, thumbnail_url, tempId }) => ({
            tempId: new Date().getTime() + Math.floor(Math.random() * 1000),
            image: url,
            description,
            thumbnail_url,
            media_type: importFrom.type === IMPORT_FROM_FIND_VIDEOS ? VIDEO.toUpperCase() : IMAGE.toUpperCase(),
            mediaId: tempId,
          }))
        )
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: FindContentModal.displayName,
        functionName: 'handleClickDiscoverMedia',
        errorMessage: `Can't find any medias. Please contact support.`,
      })
      setNoArticlesFound(true)
    } finally {
      setIsContentFinding(false)
    }
  }

  const handleClickFind = () => {
    switch (importFrom.type) {
      case IMPORT_FROM_SEARCH_CONTENT:
        handleClickFindLatestNews()
        break
      case IMPORT_FROM_INSTAGRAM_HASHTAG:
      case IMPORT_FROM_INSTAGRAM_USER:
        handleClickFindInstagramPosts()
        break
      case IMPORT_FROM_FIND_IMAGES:
      case IMPORT_FROM_FIND_GIFS:
      case IMPORT_FROM_FIND_VIDEOS:
        handleClickDiscoverMedia()
        break
      default:
        break
    }
  }

  const handleClickTransformDataAndSendToCompose = (data) => {
    if (data.length === 0) {
      Alert.error(`Please select at least one media to create a post with.`, { timeout: 5000 })
    } else {
      const foundLoadingArticle = data.find(({ isLoading }) => isLoading)
      if (foundLoadingArticle) {
        Alert.warning(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
      } else {
        let post

        if (importFrom.type === IMPORT_FROM_SEARCH_CONTENT) {
          const { 0: { title, url } = {} } = data
          post = {
            postText: `${title}\n${url}`,
            link: { url, needsUpload: true },
            postComponent: POST_LINK,
            postVideos: [],
            postImages: [],
            postDocuments: [],
          }
        } else {
          const { 0: { description, username, media_type = IMAGE } = {} } = data
          post = {
            postText: '',
            postComponent: media_type.toLowerCase() === VIDEO ? POST_VIDEO : POST_IMAGE,
            postVideos: [],
            postImages: [],
          }

          if (data.length === 1) {
            if (importFrom.type === IMPORT_FROM_INSTAGRAM_USER || importFrom.type === IMPORT_FROM_INSTAGRAM_HASHTAG) {
              post.postText = `${description || ''} via ${username ? `@${username}` : 'Instagram'}`
            } else {
              post.postText = description || ''
            }
          }

          data.forEach(({ media_type, mediaData, image, tempId }) => {
            if (media_type.toLowerCase() === VIDEO) {
              post.postVideos.push(
                mediaData || {
                  needsUpload: true,
                  videoURL: image,
                  tempId,
                  isNew: true,
                }
              )
            } else {
              post.postImages.push(
                mediaData || {
                  needsUpload: true,
                  imageURL: image,
                  tempId,
                  isNew: true,
                }
              )
            }
          })
        }

        if (post) {
          handleClickSendToCompose({ post })
        }
      }
    }
  }

  const handleClickAddToMediaLibrary = async ({ url: mediaURL, type, uploadingId, index }) => {
    const foundLoadingArticle = articles.find(({ isLoading }) => isLoading)

    if (foundLoadingArticle) {
      Alert.warning(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
    } else {
      let hasError = false
      let tempId
      let response
      try {
        articles[index].isLoading = true
        setArticles([...articles])

        let path = `${ROUTE_MEDIA}?tempId=${uploadingId}`

        if (mediaType === AUDIO) {
          path = `${ROUTE_MEDIA}/audio?tempId=${uploadingId}`
        }

        response = await request({
          method: 'POST',
          body: { mediaURL, mediaType: mediaType === AUDIO ? AUDIO : type },
          path,
        })
        const { error } = response || {}
        ;({ tempId } = response || {})
        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          hasError = true
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: FindContentModal.displayName,
          functionName: 'handleClickAddToMediaLibrary',
        })
        hasError = true
      } finally {
        const media = response || {}

        delete media.tempId

        const { url, media_gid } = media

        const foundArticleIndex = articles.findIndex((article) => article.tempId === Number(tempId))

        if (foundArticleIndex > -1) {
          if (!hasError) {
            const mediaData = {
              ...media,
              id: media_gid,
              type,
              index,
            }
            articles[foundArticleIndex] = {
              ...articles[foundArticleIndex],
              image: url,
              mediaData,
            }
            setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: [mediaData] } })
          } else {
            articles[foundArticleIndex].isLoading = false
            setArticles([...articles])
          }
        }
      }
    }
  }

  const handleClickAddToMediaLibraryBulk = async () => {
    const selectedMedias = []
    let hasLoadingArticles = false
    let hasMediaData = false

    for (let i = 0; i < articles.length; i++) {
      const { isLoading, image: mediaURL, selected, tempId, mediaData } = articles[i]
      if (isLoading) {
        hasLoadingArticles = true
        break
      } else if (selected) {
        if (mediaData) {
          hasMediaData = true
        } else {
          articles[i].isLoading = true
          const data = { mediaURL, tempId }
          if (mediaType === AUDIO) {
            data.type = AUDIO
          }
          selectedMedias.push(data)
        }
      }
    }

    if (hasLoadingArticles) {
      Alert.warning(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
    } else if (selectedMedias.length === 0) {
      if (hasMediaData) {
        Alert.error(`Selected medias have already been uploaded to media library.`, { timeout: 5000 })
      } else {
        Alert.error(`Please select at least one media for adding to media library.`, { timeout: 5000 })
      }
    } else {
      setArticles([...articles])

      let hasError = false
      let response

      setIsBulkMediaUploading(true)

      try {
        response = await request({
          method: 'POST',
          body: { medias: selectedMedias },
          path: `${ROUTE_MEDIA}/batch-upload`,
        })
        const { error, errors = [] } = response || {}
        if (!response || error || errors.length !== 0) {
          let errorMessage = error || ERROR_MESSAGE
          if (errors.length !== 0) {
            errorMessage = `Can't upload some of your selected medias. Please contact support.`
          } else {
            hasError = true
          }
          Alert.error(errorMessage, { timeout: 5000 })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: FindContentModal.displayName,
          functionName: 'handleClickAddToMediaLibraryBulk',
        })
        hasError = true
      } finally {
        const { uploadedMedias = [] } = response || {}

        const mediasForProcessing = []

        articles.forEach((article, index) => {
          if (!hasError) {
            const foundMediaIndex = uploadedMedias.findIndex(({ tempId }) => article.tempId === Number(tempId))

            if (foundMediaIndex > -1) {
              const media = uploadedMedias[foundMediaIndex]

              const { url, media_gid } = media

              delete media.tempId

              const mediaData = {
                ...media,
                id: media_gid,
                index,
              }

              article.image = url
              article.mediaData = mediaData

              mediasForProcessing.push(mediaData)

              uploadedMedias.splice(foundMediaIndex, 1)
            }
          } else {
            article.isLoading = false
          }
        })

        if (mediasForProcessing.length !== 0) {
          setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: mediasForProcessing } })
        } else {
          setIsBulkMediaUploading(false)
        }

        setArticles([...articles])
      }
    }
  }

  const handleClickCloseMediaSettingsModal = () => {
    setMediaSettingsOptions({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  }

  const handleUpdateUploadedMedias = ({ medias }) => {
    medias.forEach((media) => {
      const { index } = media
      articles[index].mediaData = media
      articles[index].isLoading = false
    })
    setArticles([...articles])
    setIsBulkMediaUploading(false)

    let type = 'media'

    const { 0: { type: uploadedMediaType } = {} } = medias

    if (mediaType) {
      type = mediaType
    } else if (uploadedMediaType) {
      type = uploadedMediaType
    }

    Alert.success(`Selected ${type}${medias.length === 1 ? ' has' : 's have'} been saved to your media library.`, {
      timeout: 5000,
    })
  }

  const handleClickSelectMedia = ({ index }) => {
    if (!articles[index].isLoading) {
      articles[index].selected = !articles[index].selected
      setArticles([...articles])
    }
  }

  const handleClickSelectAllMedias = () => {
    if (allMediasSelected) {
      articles.forEach((article) => {
        article.selected = false
      })
    } else {
      articles.forEach((article) => {
        const { isLoading } = article
        if (!isLoading) {
          article.selected = true
        }
      })
    }
    setArticles([...articles])
  }

  const handleClickOpenMediaDetail = ({ index }) => {
    setMediaDetailOptions({ isOpen: true, data: { index } })
  }

  const handleClickCloseMediaDetail = () => {
    setMediaDetailOptions(MEDIA_DETAIL_MODAL_OPTIONS)
  }

  const handleClickPreviousStep = () => {
    const foundLoadingArticle = articles.find(({ isLoading }) => isLoading)
    if (!isContentFinding && !foundLoadingArticle) {
      setStep(1)
    } else {
      Alert.warning(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
    }
  }

  const handleClickCloseModal = () => {
    const foundLoadingArticle = articles.find(({ isLoading }) => isLoading)
    if (!isContentFinding && !foundLoadingArticle) {
      if (step === 2 && !menuItemType) {
        handleClickPreviousStep()
      } else {
        handleDismiss()
      }
    } else {
      Alert.warning(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="auto" width="100%" height="100%" p="l">
        <StyledDialogContent className="modal-content-shadow">
          {step === 1 && (
            <StyledDialogEnvironmentWrapper px="m" $isTop>
              <H4 my="m">Find content</H4>
            </StyledDialogEnvironmentWrapper>
          )}

          <Fragment>
            {step === 1 && (
              <Fragment>
                <StyledDialogBodyWrapper flex="1" heigth="100%" width="100%">
                  <Scrollbars universal>
                    <Grid
                      gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', desktop: 'repeat(3, 1fr)' }}
                      height="100%"
                      width="100%"
                    >
                      {MENU_ITEMS.map((item) => {
                        const { title, description, imageComponent, learnMoreLink, buttonText } = item
                        return (
                          <StyledMenuItemWrapper
                            key={title}
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            heigth="100%"
                            p="m"
                          >
                            {imageComponent}
                            <Flex mt="m" justifyContent="center" height="55px">
                              <Text fontSize="l" textAlign="center">
                                {title}
                              </Text>
                            </Flex>

                            <Flex width="265px" height="90px" justifyContent="center">
                              <StyledImportText>
                                {description}{' '}
                                {learnMoreLink && (
                                  <StyledLearnMore color="primary" as="a" href={learnMoreLink} target="_blank">
                                    . Learn more
                                  </StyledLearnMore>
                                )}
                              </StyledImportText>
                            </Flex>

                            <Button.Bordered
                              mt="m"
                              onClick={() => {
                                if (featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
                                  handleClickSelectMenu({ item })
                                } else {
                                  window.location.replace('/pricing')
                                }
                              }}
                            >
                              <Text>{featuresEnabled[FEATURE_FIND_CONTENT].enabled ? buttonText : PLAN_UPGRADE}</Text>
                            </Button.Bordered>
                          </StyledMenuItemWrapper>
                        )
                      })}
                    </Grid>
                  </Scrollbars>
                </StyledDialogBodyWrapper>
                <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-end" $isBottom>
                  <Button.Gray isSmall onClick={handleDismiss}>
                    Close & Cancel
                  </Button.Gray>
                </StyledDialogEnvironmentWrapper>
              </Fragment>
            )}
            {step === 2 && (
              <Fragment>
                <StyledDialogBodyWrapper flex="1">
                  <Scrollbars universal>
                    <Flex flexDirection="column" width="100%" height="100%">
                      <StyledSearchWrapper searchBackground={importFrom.searchBackground} flexDirection="column">
                        <Flex width="50%" px="m" mx="auto" flexDirection="column">
                          <H4 color="white" mb="m">
                            {importFrom.title}
                          </H4>
                          <Text color="white" mb="s">
                            {importFrom.description}
                          </Text>
                          <Flex justifyContent="space-between" width="100%">
                            <Flex
                              flexDirection="column"
                              position="relative"
                              width={importFrom.type === IMPORT_FROM_FIND_IMAGES ? 'calc(100% - 226px)' : '100%'}
                            >
                              <StyledIconSearch p="s" justifyContent="center" alignItems="center" left="0">
                                <Image src="/assets/magnifier.svg" width="16px" height="16px" />
                              </StyledIconSearch>
                              <StyledInput
                                ref={searchInputRef}
                                pl="l"
                                placeholder={importFrom.searchInputPlaceholder}
                                onKeyUp={(e) => {
                                  if (featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
                                    if (e.keyCode === 13 && !isContentFinding) {
                                      handleClickFind()
                                    }
                                  }
                                }}
                                isSmall
                                width="100%"
                                dir={isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT}
                              />
                              <StyledIconSearching
                                justifyContent="center"
                                alignItems="center"
                                onClick={() => {
                                  if (!isContentFinding) {
                                    if (featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
                                      handleClickFind()
                                    } else {
                                      Alert.error(featuresEnabled[FEATURE_FIND_CONTENT].description, {
                                        timeout: 10000,
                                      })
                                    }
                                  }
                                }}
                              >
                                {isContentFinding ? (
                                  <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                    className="cliploader-wrapper"
                                  >
                                    <ClipLoader size="24" />
                                  </Flex>
                                ) : (
                                  <Image
                                    src="/assets/vistasocial/double_arrow_right_blue.svg"
                                    width="24px"
                                    height="24px"
                                  />
                                )}
                              </StyledIconSearching>
                            </Flex>
                            {importFrom.type === IMPORT_FROM_FIND_IMAGES && (
                              <StyledTabsWrapper>
                                {IMPORT_FROM_FIND_IMAGES_TABS.map(({ name, system }, index) => (
                                  <Flex flexDirection="column" key={system} justifyContent="center">
                                    <Flex alignItems="center" mr={index === 0 ? 'm' : 0}>
                                      <Radio
                                        id={system}
                                        name="importFromFindImagesTabs"
                                        onChange={({ target: { id } }) => {
                                          setActiveTab(id)
                                        }}
                                        value={system}
                                        checked={activeTab === system}
                                      />
                                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                      <label htmlFor={system}>
                                        <Text ml="m" pl="xs" fontSize="s" color="white">
                                          {name}
                                        </Text>
                                      </label>
                                    </Flex>
                                  </Flex>
                                ))}
                              </StyledTabsWrapper>
                            )}
                          </Flex>
                          {error && <ErrorLabel mt="xxs" textAlign="left" error={error} color="white" />}
                        </Flex>
                      </StyledSearchWrapper>
                      {noArticlesFound ? (
                        <Flex height="100%" alignItems="center" justifyContent="center" py="l">
                          <NoData message={importFrom.noDataFoundText} image="/assets/noResultsIllustration.svg" />
                        </Flex>
                      ) : (
                        <Fragment>
                          {articles.length === 0 ? (
                            <Flex height="100%" alignItems="center" justifyContent="center" py="l">
                              <NoData
                                message={importFrom.description}
                                showHeaderText={false}
                                image="/assets/noSearchIllustration.svg"
                              />
                            </Flex>
                          ) : (
                            <Fragment>
                              {importFrom.type === IMPORT_FROM_SEARCH_CONTENT ? (
                                <Flex flexDirection="column" mx="m" mb="m">
                                  {articles.map((item, index) => (
                                    <ArticleItem
                                      data={item}
                                      handleClickSendToCompose={
                                        canPublish ? handleClickTransformDataAndSendToCompose : null
                                      }
                                      key={index}
                                      index={index}
                                      type={importFrom.type}
                                      handleClickAddToMediaLibrary={handleClickAddToMediaLibrary}
                                    />
                                  ))}
                                </Flex>
                              ) : (
                                <Flex flexDirection="column" m="m">
                                  <Flex alignItems="center" justifyContent="space-between" mb="m">
                                    <Flex alignItems="center">
                                      <ButtonWithLoading
                                        width="200px"
                                        isLoading={isBulkMediaUploading}
                                        isSmall
                                        onClick={handleClickAddToMediaLibraryBulk}
                                      >
                                        Add to media library
                                      </ButtonWithLoading>
                                      {canPublish && mediaType !== AUDIO && (
                                        <Button.Gray
                                          ml="m"
                                          isSmall
                                          onClick={() => {
                                            handleClickTransformDataAndSendToCompose(
                                              articles.filter(({ selected }) => selected)
                                            )
                                          }}
                                        >
                                          <Flex alignItems="center">
                                            <Text>Create a post</Text>
                                          </Flex>
                                        </Button.Gray>
                                      )}
                                    </Flex>

                                    <Button.Gray isSmall onClick={handleClickSelectAllMedias}>
                                      {allMediasSelected ? 'Unselect all' : 'Select all'}
                                    </Button.Gray>
                                  </Flex>
                                  <Grid
                                    gridGap="m"
                                    gridTemplateColumns={{
                                      mobile: 'repeat(2,1fr)',
                                      tablet: 'repeat(4,1fr)',
                                      desktop: 'repeat(6,1fr)',
                                    }}
                                  >
                                    {articles.map((item, index) => (
                                      <MediaItem
                                        data={item}
                                        key={index}
                                        handleClickSelectMedia={() => {
                                          handleClickSelectMedia({ index })
                                        }}
                                        handleClickOpenMediaDetail={() => {
                                          handleClickOpenMediaDetail({ index })
                                        }}
                                      />
                                    ))}
                                  </Grid>
                                </Flex>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Flex>
                  </Scrollbars>
                </StyledDialogBodyWrapper>
              </Fragment>
            )}
          </Fragment>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {mediaSettingsOptions.isOpen && (
          <MediaSettings
            user={user}
            uploadedMedias={mediaSettingsOptions.uploadedMedias}
            isOpen={mediaSettingsOptions.isOpen}
            handleDismiss={handleClickCloseMediaSettingsModal}
            handleUpdateUploadedMedias={handleUpdateUploadedMedias}
            folderPath={folderPath}
          />
        )}

        {mediaDetailModalOptions.isOpen && (
          <MediaDetailModal
            handleDismiss={handleClickCloseMediaDetail}
            isOpen={mediaDetailModalOptions.isOpen}
            data={mediaDetailModalOptions.data}
            handleClickAddToMediaLibrary={handleClickAddToMediaLibrary}
            activeTab={activeTab}
            importFrom={importFrom.type}
            articles={articles}
            handleClickSendToCompose={
              canPublish && mediaType !== AUDIO ? handleClickTransformDataAndSendToCompose : null
            }
            mediaType={mediaType}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

FindContentModal.defaultProps = {
  handleModalSuccessCallback: () => {},
  menuItemType: null,
  mediaType: null,
  folderPath: null,
}

FindContentModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickSendToCompose: PropTypes.func.isRequired,
  handleModalSuccessCallback: PropTypes.func,
  menuItemType: PropTypes.string,
  mediaType: PropTypes.string,
  folderPath: PropTypes.array,
  theme: PropTypes.object.isRequired,
}

FindContentModal.displayName = 'FindContentModal'

export default withTheme(FindContentModal)
