import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Flex } from 'components/atoms/Layout'
import ErrorLabel from 'components/atoms/ErrorLabel'
import { Text } from 'components/atoms/Typography'
import { space, fontSizes, radius, fontWeights } from 'theme'
import { DIRECTION_LEFT_TO_RIGHT, DIRECTION_RIGHT_TO_LEFT } from 'consts'

const StyledTextArea = styled.textarea`
  margin-top: ${space.s};
  padding: ${space.m};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  background: transparent;
  border-radius: ${radius.l};
  border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.error : theme.colors.border_color)};
  font-size: ${({ fontSize }) => fontSizes[fontSize] || fontSizes.xs};
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  outline: 0;
  resize: none;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
`

const TextArea = forwardRef((props, ref) => {
  const { error, fontSize, label, labelFontSize = 's', theme } = props

  const { isRTL } = theme || {}

  return (
    <Flex flexDirection="column">
      {label && (
        <Text
          mb="xs"
          fontSize={labelFontSize}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
      <StyledTextArea
        fontSize={fontSize}
        isError={error}
        dir={isRTL ? DIRECTION_RIGHT_TO_LEFT : DIRECTION_LEFT_TO_RIGHT}
        ref={ref}
        {...props}
      />
      {error && <ErrorLabel mt="xxs" textAlign="left" error={error} />}
    </Flex>
  )
})

TextArea.propTypes = {
  error: PropTypes.string,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  labelFontSize: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

export default withTheme(TextArea)
