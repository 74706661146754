import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, radius } from 'theme'
import { IMAGE, VIDEO, AUDIO, DOCUMENT } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import DropDown from 'shared/DropDown'
import { formatLabelOptionLabel } from 'shared/LabelEditModal/helpers'

const Button = styled(Flex)`
  align-items: center;
  height: ${({ height }) => height || '34px'};
  padding: ${space.xxs} ${space.s};
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const SubMenu = styled(Box)`
  position: absolute;
  z-index: 2147483001;
  top: 40px;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  width: 500px;
  padding: ${space.s};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
  max-height: 500px;
  overflow: auto;
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const StyledIcon = styled(Icon.ArrowDropdown)`
  fill: ${({ theme }) => theme.colors.secondaryText};
`

const TotalBadge = styled(Flex)`
  position: absolute;
  bottom: -7px;
  right: -9px;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  margin-left: ${space.xs};
`

const MediaFilters = forwardRef(
  (
    {
      showMobileIcon,
      removeMobileIcon,
      mobileIconHeight,
      mobileIconWidth,
      handleFetchMedia,
      labels,
      extensions,
      users,
      types,
      usage,
      left,
      right,
      defaultFilters: DEFAULT_FILTERS,
    },
    mediaFiltersRef
  ) => {
    const subMenuRef = useRef(null)
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState({ ...DEFAULT_FILTERS })
    const [filteredExtensions, setFilteredExtensions] = useState([])
    const [isTouched, setIsTouched] = useState(false)

    const {
      selectedTypes = [],
      selectedLabels = [],
      selectedExtensions = [],
      selectedUsers = [],
      selectedUsage = [],
    } = selectedFilters

    let selectedFiltersNumber = 0

    if (types.length !== 0) {
      selectedFiltersNumber += selectedTypes.length
    }
    if (labels.length !== 0) {
      selectedFiltersNumber += selectedLabels.length
    }
    if (filteredExtensions.length !== 0) {
      selectedFiltersNumber += selectedExtensions.length
    }
    if (users.length !== 0) {
      selectedFiltersNumber += selectedUsers.length
    }
    if (usage.length !== 0) {
      selectedFiltersNumber += selectedUsage.length
    }

    useImperativeHandle(mediaFiltersRef, () => ({
      getFiltersData() {
        return { filters: selectedFilters, isTouched }
      },
      setFiltersData({ data }) {
        setSelectedFilters({ ...data })
      },
      handleChangeFilters(props) {
        // eslint-disable-next-line no-use-before-define
        handleChangeFilters(props)
      },
    }))

    useEffect(() => {
      const filtered_extensions = [
        ...extensions[IMAGE],
        ...extensions[VIDEO],
        ...extensions[AUDIO],
        ...extensions[DOCUMENT],
      ]

      filtered_extensions.sort((a, b) => (a.label < b.label ? -1 : 1))

      setFilteredExtensions(Array.from(new Set(filtered_extensions.map(JSON.stringify))).map(JSON.parse))
    }, [extensions])

    const handleMouseClick = (event) => {
      if (subMenuRef.current) {
        if (subMenuRef.current.contains(event.target)) {
          return
        }
      }

      if (isSubMenuOpen) {
        setIsSubMenuOpen(false)
      }
    }

    useEffect(() => {
      window.addEventListener('mousedown', handleMouseClick, false)
      return () => window.removeEventListener('mousedown', handleMouseClick, false)
    })

    const handleChangeFilters = ({ filters, key, value, defaultFilters = false }) => {
      if (!isTouched) {
        setIsTouched(true)
      } else if (defaultFilters) {
        setIsTouched(false)
      }

      let updatedFilters = selectedFilters
      if (filters) {
        updatedFilters = filters
      } else {
        selectedFilters[key] = value
      }

      let availableExtensions = filteredExtensions || []
      if (filters || key === 'selectedTypes') {
        if (filters || value.length === 0 || value.length === types.length) {
          if (value.length === 0) {
            availableExtensions = []
          } else {
            availableExtensions = [
              ...extensions[IMAGE],
              ...extensions[VIDEO],
              ...extensions[AUDIO],
              ...extensions[DOCUMENT],
            ]
          }
        } else if (value.length === 1) {
          const {
            0: { value: typeValue },
          } = value
          availableExtensions = [...extensions[typeValue]]
        } else {
          availableExtensions = []
          for (const item of value) {
            availableExtensions.push(...extensions[item.value])
          }
        }

        availableExtensions.sort((a, b) => (a.label < b.label ? -1 : 1))

        setFilteredExtensions(Array.from(new Set(availableExtensions.map(JSON.stringify))).map(JSON.parse))
      }

      if (selectedExtensions.length > 0 && key === 'selectedTypes') {
        const filteredSelectedExtensions = []
        for (const selectedItem of selectedExtensions) {
          for (const filteredItem of availableExtensions) {
            if (selectedItem.value === filteredItem.value) {
              filteredSelectedExtensions.push(selectedItem)
            }
          }
        }

        updatedFilters.selectedExtensions.length = 0
        for (const item of filteredSelectedExtensions) {
          updatedFilters.selectedExtensions.push(item)
        }
      }

      setSelectedFilters({ ...updatedFilters })
      handleFetchMedia({ filters: updatedFilters })
    }

    return (
      <Box position="relative">
        <Button
          color="primary"
          onClick={() => {
            setIsSubMenuOpen(true)
          }}
        >
          {(!showMobileIcon || !removeMobileIcon) && (
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Text color="primaryText" fontSize="s">
                Select filters · {selectedFiltersNumber}
              </Text>
              <StyledIcon />
            </Flex>
          )}
          {showMobileIcon && removeMobileIcon && (
            <Flex width="100%">
              <Flex width="100%" alignItems="center" justifyContent="space-between" display={removeMobileIcon}>
                <Text color="primaryText" fontSize="s">
                  Select filters · {selectedFiltersNumber}
                </Text>
                <StyledIcon />
              </Flex>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                display={showMobileIcon}
                position="relative"
              >
                <Icon.VistaSocialFilter width={mobileIconWidth} height={mobileIconHeight} fill="primaryText" />
                {selectedFiltersNumber > 0 && (
                  <TotalBadge>
                    <Text as="span" color="white" fontSize="8px">
                      {selectedFiltersNumber}
                    </Text>
                  </TotalBadge>
                )}
              </Flex>
            </Flex>
          )}
        </Button>
        <Box ref={subMenuRef}>
          {isSubMenuOpen && (
            <SubMenu left={left} right={right}>
              <Flex mb="s" mr="xs" justifyContent="flex-end">
                <StyledText
                  color="secondaryText"
                  fontSize="xs"
                  onClick={() => {
                    handleChangeFilters({
                      filters: { ...DEFAULT_FILTERS },
                      defaultFilters: true,
                    })
                  }}
                >
                  Reset
                </StyledText>
                <Text color="secondaryText" fontSize="xs">
                  &nbsp; · &nbsp;
                </Text>
                <StyledText
                  color="secondaryText"
                  fontSize="xs"
                  onClick={() => {
                    handleChangeFilters({
                      filters: { ...DEFAULT_FILTERS },
                    })
                  }}
                >
                  Clear selected
                </StyledText>
              </Flex>

              {types.length !== 0 && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Types</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select types"
                      label=""
                      value={selectedTypes}
                      onChange={(options) => {
                        handleChangeFilters({ key: 'selectedTypes', value: options || [] })
                      }}
                      options={types}
                      openMenuOnFocus={false}
                      isMulti
                    />
                  </Flex>
                </Flex>
              )}

              {filteredExtensions.length !== 0 && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Formats</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select formats"
                      label=""
                      value={selectedExtensions}
                      onChange={(options) => {
                        handleChangeFilters({ key: 'selectedExtensions', value: options || [] })
                      }}
                      options={filteredExtensions}
                      openMenuOnFocus={false}
                      isMulti
                    />
                  </Flex>
                </Flex>
              )}

              <Flex mb="s" alignItems="center">
                <Flex width="80px">
                  <Text>Labels</Text>
                </Flex>
                <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                  <DropDown
                    placeholder="Select labels"
                    label=""
                    value={selectedLabels}
                    onChange={(options) => {
                      handleChangeFilters({ key: 'selectedLabels', value: options || [] })
                    }}
                    options={labels}
                    openMenuOnFocus={false}
                    isMulti
                    formatOptionLabel={formatLabelOptionLabel}
                  />
                </Flex>
              </Flex>

              {users.length !== 0 && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Users</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select users"
                      label=""
                      value={selectedUsers}
                      onChange={(options) => {
                        handleChangeFilters({ key: 'selectedUsers', value: options || [] })
                      }}
                      options={users}
                      openMenuOnFocus={false}
                      isMulti
                    />
                  </Flex>
                </Flex>
              )}

              {usage.length !== 0 && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Usage</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select usage"
                      label=""
                      value={selectedUsage}
                      onChange={(options) => {
                        handleChangeFilters({ key: 'selectedUsage', value: options || [] })
                      }}
                      options={usage}
                      openMenuOnFocus={false}
                      isMulti
                    />
                  </Flex>
                </Flex>
              )}
            </SubMenu>
          )}
        </Box>
      </Box>
    )
  }
)

MediaFilters.defaultProps = {
  showMobileIcon: null,
  removeMobileIcon: null,
  mobileIconWidth: '16px',
  mobileIconHeight: '16px',
  types: [],
  labels: [],
  extensions: {},
  users: [],
  usage: [],
  left: 'unset',
  right: '0px',
  defaultFilters: {
    selectedTypes: [
      { value: IMAGE, label: 'Images' },
      { value: VIDEO, label: 'Videos' },
    ],
    selectedLabels: [],
    selectedExtensions: [],
    selectedUsers: [],
    selectedUsage: [],
  },
}

MediaFilters.propTypes = {
  showMobileIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  removeMobileIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mobileIcon: PropTypes.string,
  mobileIconWidth: PropTypes.string,
  mobileIconHeight: PropTypes.string,
  handleFetchMedia: PropTypes.func.isRequired,
  types: PropTypes.array,
  labels: PropTypes.array,
  extensions: PropTypes.object,
  users: PropTypes.array,
  usage: PropTypes.array,
  left: PropTypes.string,
  right: PropTypes.string,
  defaultFilters: PropTypes.object,
}

export default MediaFilters
