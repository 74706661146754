import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { transparentize } from 'polished'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { space, radius } from 'theme'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'

const COMPONENT_WIDTH = 210

const DropdownMenuWrapper = styled(Flex)`
  z-index: 16;
  position: fixed;
  flex-direction: column;
  width: ${COMPONENT_WIDTH}px;
  padding: ${space.xs} 0;
  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  background-color: ${({ theme }) => theme.colors.dropdown_component_background};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  ${({ isTriangleVisible }) =>
    isTriangleVisible &&
    `
  &::after,
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border: solid transparent;
  }
  &::after {
    border-bottom-color: ${({ theme }) => theme.colors.background};
    border-width: 6px;
    right: 11px;
  }
  &::before {
    border-bottom-color: ${({ theme }) => theme.colors.border_color}; 
    border-width: 7px;
    right: 10px;
  }
  `}
`

const IconWrapper = styled(Box)`
  padding: 0 ${space.s};
  width: 36px;
  height: 36px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.composer_icons_color};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
   svg {
      fill: ${theme.colors.primary};
   }
  `}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

let specialCloseInterval = null

class ShowComponentDropdownMenu extends React.Component {
  state = { isOpen: false, IconComp: <Fragment /> }

  componentDidMount() {
    const { iconName, iconHeight, iconWidth, boxId } = this.props
    const IconComp = iconName ? Icon[iconName] : null
    const StyledIconComp =
      iconName &&
      styled(IconComp)`
        max-width: 20px;
        width: ${iconWidth || '20px'};
        height: ${iconHeight || '20px'};
      `
    this.setState({
      IconComp: <StyledIconComp />,
      boxId: `${boxId}_${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(
        Math.random() * 100
      )}`,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isActive } = this.props
    if (prevProps.isActive && !isActive) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: false })
    }
  }

  componentWillUnmount() {
    clearInterval(specialCloseInterval)
  }

  handleOpenMenu = () => {
    const { boxId } = this.state

    const el = document.getElementById(boxId)

    const {
      top: parentTop,
      bottom: parentBottom,
      left: parentLeft,
      right: parentRight,
      height,
    } = el.getBoundingClientRect()

    const { innerHeight, innerWidth } = window

    let left = parentLeft
    let right = 'unset'

    if (left + COMPONENT_WIDTH > innerWidth - parentRight) {
      right = innerWidth - parentRight
      left = 'unset'
    }

    let top = parentTop + height + 6
    let bottom = 'unset'

    if (top + 300 > innerHeight) {
      top = 'unset'
      bottom = innerHeight - parentBottom + height
    }

    this.setState({ isOpen: true, left, right, top, bottom })
  }

  handleClickOutside = () => {
    const { isOpen } = this.state
    if (isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickInside = () => {
    const { isOpen } = this.state

    if (isOpen) {
      this.setState({ isOpen: false })
    } else {
      this.handleOpenMenu()
    }
  }

  render() {
    const { isOpen, IconComp, top, left, right, bottom, boxId } = this.state
    const {
      children,
      isActive,
      handleClickToggleMenu,
      showIsActiveIndicator,
      isTriangleVisible,
      tooltipRef,
      tooltipMessage,
    } = this.props

    return (
      <Box>
        <Box>
          <Box
            id={boxId}
            position="relative"
            onClick={() => {
              this.handleClickInside()
              handleClickToggleMenu()

              if (tooltipRef && tooltipMessage) {
                tooltipRef.current.handleHideTooltip()
              }
            }}
            onMouseEnter={(e) => {
              if (tooltipRef && tooltipMessage) {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Box p="s" maxWidth="350px">
                      <Text textAlign="center">{tooltipMessage}</Text>
                    </Box>
                  ),
                  wrapperComp: e.currentTarget,
                  defaultYPosition: 'top',
                })
              }
            }}
            onMouseLeave={() => {
              if (isOpen) {
                clearInterval(specialCloseInterval)
                specialCloseInterval = setInterval(() => {
                  this.handleClickOutside()
                  clearInterval(specialCloseInterval)
                }, 300)
              }

              if (tooltipRef && tooltipMessage) {
                tooltipRef.current.handleHideTooltip()
              }
            }}
            onMouseOver={() => {
              if (isActive) {
                clearInterval(specialCloseInterval)
                if (!isOpen) {
                  this.handleOpenMenu()
                  // this.setState({ isOpen: true })
                }
              }
            }}
          >
            <IconWrapper isActive={isActive && showIsActiveIndicator}>{IconComp}</IconWrapper>
            {isOpen && (
              <DropdownMenuWrapper
                minWidth="min-content"
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleClickInside()
                }}
                // bottom={bottom}
                top={top}
                left={left}
                right={right}
                bottom={bottom}
                isTriangleVisible={isTriangleVisible}
              >
                {children}
              </DropdownMenuWrapper>
            )}
          </Box>
        </Box>
      </Box>
    )
  }
}

ShowComponentDropdownMenu.defaultProps = {
  isActive: false,
  showIsActiveIndicator: true,
  isTriangleVisible: true,
  iconHeight: '',
  iconWidth: '',
  tooltipRef: null,
  tooltipMessage: '',
}

ShowComponentDropdownMenu.propTypes = {
  boxId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClickToggleMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  showIsActiveIndicator: PropTypes.bool,
  isTriangleVisible: PropTypes.bool,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  tooltipMessage: PropTypes.string,
}

export default onClickOutside(ShowComponentDropdownMenu)
