import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import DropDown from 'shared/DropDown'

const Button = styled(Flex)`
  align-items: center;
  height: ${({ height }) => height || '34px'};
  padding: ${space.xxs} ${space.s};
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const SubMenu = styled(Box)`
  position: absolute;
  z-index: 2147483001;
  top: 40px;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  width: 500px;
  padding: ${space.s};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
`

const StyledIcon = styled(Icon.ArrowDropdown)`
  fill: ${({ theme }) => theme.colors.secondaryText};
`

const OWNERSHIP_OPTIONS = [
  { label: 'Owned and shared', value: 'any' },
  { label: 'Owned', value: 'owned' },
  { label: 'Shared', value: 'shared' },
]

const SORT_OPTIONS = [
  { label: 'Relevance algorithm', value: 'relevance' },
  { label: 'Last modified descending', value: 'modified_descending' },
  { label: 'Last modified ascending', value: 'modified_ascending' },
  { label: 'Name descending', value: 'title_descending' },
  { label: 'Name ascending', value: 'title_ascending' },
]

const MEDIA_TYPE_OPTIONS = [
  { label: 'Design', value: 'design' },
  { label: 'Folder', value: 'folder' },
]

const MediaFilters = forwardRef(
  ({ handleFetchMedia, left, right, defaultFilters: DEFAULT_FILTERS, isSearchFieldEmpty }, mediaFiltersRef) => {
    const subMenuRef = useRef(null)
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState({ ...DEFAULT_FILTERS })
    const [isTouched, setIsTouched] = useState(false)

    const { selectedOwnership, selectedSort, selectedMediaTypes } = selectedFilters

    let selectedFiltersNumber = 0

    if (isSearchFieldEmpty) {
      selectedFiltersNumber += selectedMediaTypes ? selectedMediaTypes.length : 0
    } else {
      selectedFiltersNumber += selectedOwnership && selectedOwnership.value ? 1 : 0
    }

    selectedFiltersNumber += selectedSort && selectedSort.value ? 1 : 0

    useImperativeHandle(mediaFiltersRef, () => ({
      getFiltersData() {
        return { filters: selectedFilters, isTouched }
      },
      setFiltersData({ data }) {
        setSelectedFilters({ ...data })
      },
      handleChangeFilters(props) {
        // eslint-disable-next-line no-use-before-define
        handleChangeFilters(props)
      },
    }))

    const handleMouseClick = (event) => {
      if (subMenuRef.current) {
        if (subMenuRef.current.contains(event.target)) {
          return
        }
      }

      if (isSubMenuOpen) {
        setIsSubMenuOpen(false)
      }
    }

    useEffect(() => {
      window.addEventListener('mousedown', handleMouseClick, false)
      return () => window.removeEventListener('mousedown', handleMouseClick, false)
    })

    const handleChangeFilters = ({ filters, key, value, defaultFilters = false }) => {
      if (!isTouched) {
        setIsTouched(true)
      } else if (defaultFilters) {
        setIsTouched(false)
      }

      let updatedFilters = selectedFilters
      if (filters) {
        updatedFilters = filters
      } else {
        selectedFilters[key] = value
      }

      setSelectedFilters({ ...updatedFilters })
      handleFetchMedia({ filters: updatedFilters })
    }

    return (
      <Box position="relative">
        <Button
          color="primary"
          onClick={() => {
            setIsSubMenuOpen(true)
          }}
        >
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Text color="primaryText" fontSize="s">
              Select filters · {selectedFiltersNumber}
            </Text>
            <StyledIcon />
          </Flex>
        </Button>
        <Box ref={subMenuRef}>
          {isSubMenuOpen && (
            <SubMenu left={left} right={right}>
              {isSearchFieldEmpty && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Type</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select types"
                      label=""
                      value={selectedMediaTypes}
                      onChange={(options) => {
                        handleChangeFilters({ key: 'selectedMediaTypes', value: options })
                      }}
                      options={MEDIA_TYPE_OPTIONS}
                      openMenuOnFocus={false}
                      isMulti
                    />
                  </Flex>
                </Flex>
              )}

              {!isSearchFieldEmpty && (
                <Flex mb="s" alignItems="center">
                  <Flex width="80px">
                    <Text>Ownership</Text>
                  </Flex>
                  <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                    <DropDown
                      placeholder="Select ownership"
                      label=""
                      value={selectedOwnership}
                      onChange={(option) => {
                        handleChangeFilters({ key: 'selectedOwnership', value: option })
                      }}
                      options={OWNERSHIP_OPTIONS}
                      openMenuOnFocus={false}
                    />
                  </Flex>
                </Flex>
              )}

              <Flex mb="s" alignItems="center">
                <Flex width="80px">
                  <Text>Sorting</Text>
                </Flex>
                <Flex ml="s" flexDirection="column" width="calc(100% - 90px)">
                  <DropDown
                    placeholder="Sort by"
                    label=""
                    value={selectedSort}
                    onChange={(option) => {
                      handleChangeFilters({ key: 'selectedSort', value: option })
                    }}
                    options={SORT_OPTIONS}
                    openMenuOnFocus={false}
                  />
                </Flex>
              </Flex>
            </SubMenu>
          )}
        </Box>
      </Box>
    )
  }
)

MediaFilters.defaultProps = {
  left: 'unset',
  right: '0px',
  defaultFilters: {
    selectedOwnership: OWNERSHIP_OPTIONS[0],
    selectedSort: SORT_OPTIONS[1],
    selectedMediaTypes: MEDIA_TYPE_OPTIONS,
  },
}

MediaFilters.propTypes = {
  handleFetchMedia: PropTypes.func.isRequired,
  left: PropTypes.string,
  right: PropTypes.string,
  defaultFilters: PropTypes.object,
  isSearchFieldEmpty: PropTypes.bool.isRequired,
}

export default MediaFilters
