import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  MAX_IMAGE_SIZE_SMALL,
  MAX_IMAGE_SIZE_SMALL_TEXT,
} from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'
import AvatarUpload from 'components/molecules/AvatarUpload'
import ProfileImageUploadComponent from './ProfileImageUploadComponent'
import ChooseNetworkIconModal from './CustomProfilePictureModal/ChooseNetworkIconModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 500px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 330px;
    top: 40%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const RemoveIconWrapper = styled(Box)`
  position: absolute;
  top: 18px;
  right: -8px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 16px;
  width: 16px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ $hasBoxShadow, theme }) =>
    $hasBoxShadow && `  box-shadow: 0 4px 15px ${transparentize(0.7, theme.colors.box_shadow)};`}
`

const StyledGrayButton = styled(Flex)`
  padding: ${space.s};
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const { NAME, PICTURE_FILE, NETWORK_PICTURE_FILE } = {
  NAME: 'name',
  PICTURE_FILE: 'pictureFile',
  NETWORK_PICTURE_FILE: 'networkPictureFile',
}

const ConnectingCustomProfileModal = ({ isOpen, handleDismiss, handleAddProfile, entityId }) => {
  const formRef = useRef(null)
  const [isConnectingProfile, setIsConnectingProfile] = useState(false)
  const [isChooseIconModalOpen, setIsChooseIconModalOpen] = useState(false)

  const handleSubmitForm = async (values) => {
    setIsConnectingProfile(true)
    try {
      const body = {
        [NAME]: values[NAME],
        entity_gid: entityId,
      }

      if (values[PICTURE_FILE]) {
        body[PICTURE_FILE] = values[PICTURE_FILE]
      }

      if (values[NETWORK_PICTURE_FILE]) {
        body[NETWORK_PICTURE_FILE] = values[NETWORK_PICTURE_FILE]
      }

      const response = await request({
        method: 'POST',
        body,
        path: 'connect/custom-profile',
      })
      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setIsConnectingProfile(false)
      } else {
        Alert.success('Custom profile added successfully.', { timeout: 5000 })
        handleAddProfile()
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: ConnectingCustomProfileModal.displayName,
        functionName: 'handleSubmitForm',
      })
      setIsConnectingProfile(false)
    }
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const createValidationSchema = () => {
    return Yup.object().shape({
      [NAME]: Yup.string()
        .max(50, 'Profile name is too long - should be 50 chars maximum.')
        .required('Profile name is required.'),
    })
  }

  const handleClickOpenChooseNetworkIconModal = () => {
    setIsChooseIconModalOpen(true)
  }

  const handleClickCloseChooseNetworkIconModal = () => {
    setIsChooseIconModalOpen(false)
  }

  const handleSaveNetworkIcon = ({ base64 }) => {
    formRef.current.setFieldValue(NETWORK_PICTURE_FILE, base64)
    handleClickCloseChooseNetworkIconModal()
  }

  const handleClickCloseModal = () => {
    if (!isConnectingProfile) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Create custom profile</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" mt="m" px="m" flex="1">
            <Text fontSize="s">
              Custom profile represents a marketing channel that we do not currently integrate with, such as email
              marketing, sms or blogs.
            </Text>
            <Flex flexDirection="column" width="100%">
              <Formik
                ref={formRef}
                initialValues={{
                  [NAME]: '',
                  [PICTURE_FILE]: null,
                  [NETWORK_PICTURE_FILE]: null,
                }}
                validationSchema={createValidationSchema}
                onSubmit={handleSubmitForm}
                autocomplete="off"
              >
                {({ values, touched, errors, setFieldValue }) => (
                  <Flex my="m" width="100%" height="100%" flexDirection="column">
                    <Box width="100%">
                      <Input
                        label="Profile name"
                        placeholder="Profile name"
                        value={values[NAME]}
                        name={[NAME]}
                        onChange={(e) => {
                          const { value } = e.target
                          setFieldValue(NAME, value)
                        }}
                        error={errors[NAME] && touched[NAME] && errors[NAME]}
                        width="100%"
                      />
                    </Box>

                    <Flex mt="m" flexDirection="column" position="relative">
                      <Text mb="xs" fontSize="s">
                        Avatar (optional)
                      </Text>

                      <Flex justifyContent="center" alignItems="center" flexDirection="column" width="100%">
                        <AvatarUpload
                          onChange={(event) => {
                            if (event.currentTarget.files[0]) {
                              if (event.currentTarget.files[0].size <= MAX_IMAGE_SIZE) {
                                setFieldValue(PICTURE_FILE, URL.createObjectURL(event.currentTarget.files[0]))
                              } else {
                                Alert.error(`The size of the image must be less than ${MAX_IMAGE_SIZE_TEXT}!`)
                              }
                            }
                          }}
                          onSave={(picture_url) => {
                            setFieldValue(PICTURE_FILE, picture_url)
                          }}
                          onCancel={() => {
                            setFieldValue(PICTURE_FILE, null)
                          }}
                          source={values[PICTURE_FILE]}
                          ContentComponent={<ProfileImageUploadComponent pictureFile={values[PICTURE_FILE]} />}
                        />

                        {values[PICTURE_FILE] && (
                          <RemoveIconWrapper
                            zIndex="1"
                            onClick={(e) => {
                              e.stopPropagation()
                              setFieldValue(PICTURE_FILE, null)
                            }}
                            $hasBoxShadow
                          >
                            <Image width="8px" height="8px" src="/assets/clear.svg" />
                          </RemoveIconWrapper>
                        )}
                      </Flex>
                    </Flex>

                    <Flex mt="m" flexDirection="column" position="relative">
                      <Text mb="xs" fontSize="s">
                        Network icon
                      </Text>

                      <Flex justifyContent="center" alignItems="center" width="100%">
                        <Flex justifyContent="center" alignItems="center" flexDirection="column" width="100%">
                          <AvatarUpload
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                if (event.currentTarget.files[0].size <= MAX_IMAGE_SIZE_SMALL) {
                                  setFieldValue(NETWORK_PICTURE_FILE, URL.createObjectURL(event.currentTarget.files[0]))
                                } else {
                                  Alert.error(
                                    `The size of the network image must be less than ${MAX_IMAGE_SIZE_SMALL_TEXT}!`
                                  )
                                }
                              }
                            }}
                            onSave={(custom_network_picture_url) => {
                              setFieldValue(NETWORK_PICTURE_FILE, custom_network_picture_url)
                            }}
                            onCancel={() => {
                              setFieldValue(NETWORK_PICTURE_FILE, null)
                            }}
                            source={values[NETWORK_PICTURE_FILE]}
                            ContentComponent={
                              <ProfileImageUploadComponent pictureFile={values[NETWORK_PICTURE_FILE]} />
                            }
                            id={NETWORK_PICTURE_FILE}
                          />
                        </Flex>

                        <Flex position="absolute">
                          <StyledGrayButton
                            mx="s"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleClickOpenChooseNetworkIconModal()
                            }}
                          >
                            <Icon.Image fill="icon_color_gray" width="14px" height="14px" />
                            <Text ml="s" color="secondaryText">
                              Choose image
                            </Text>
                          </StyledGrayButton>
                        </Flex>

                        {values[NETWORK_PICTURE_FILE] && (
                          <RemoveIconWrapper
                            zIndex="1"
                            onClick={(e) => {
                              e.stopPropagation()
                              setFieldValue(NETWORK_PICTURE_FILE, null)
                            }}
                            $hasBoxShadow
                          >
                            <Image width="8px" height="8px" src="/assets/clear.svg" />
                          </RemoveIconWrapper>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Formik>
            </Flex>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isConnectingProfile}>
              Cancel
            </Button.Gray>
            <ButtonWithLoading
              isSmall
              onClick={handleSubmitFormFromButton}
              isLoading={isConnectingProfile}
              type="submit"
            >
              {isConnectingProfile ? 'Creating' : 'Create'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>

      {isChooseIconModalOpen && (
        <ChooseNetworkIconModal
          isOpen={isChooseIconModalOpen}
          handleClickCloseModal={handleClickCloseChooseNetworkIconModal}
          handleSaveNetworkIcon={handleSaveNetworkIcon}
        />
      )}
    </StyledDialogOverlay>
  )
}

ConnectingCustomProfileModal.defaultProps = {}

ConnectingCustomProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleAddProfile: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
}

ConnectingCustomProfileModal.displayName = 'ConnectingCustomProfileModal'

export default ConnectingCustomProfileModal
